import { useState } from "react";
import { Box, Card, CardContent, IconButton, InputLabel, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

export default function CustomFilters(props) {
  const {
    cardSx = {
      height: "75%",
      marginTop: ".5rem",
      maxHeight: "75%",
      position: "absolute",
      overflow: "scroll",
      width: "90%",
    },
    customs = [],
    state = {},
    setState,
    xsSize = 3,
    parentSx = { padding: "0 1rem", marginTop: "1rem", position: "relative" },
  } = props;
  const { filters = {} } = state;
  const { customs: customsFromFilters } = filters;
  const theme = useTheme()
  const { palette = {} } = theme
  const { typography = {} } = palette
  const [showCustomSelector, setShowCustomSelector] = useState(false);
  const [selectedCustoms, setSelectedCustoms] = useState([]);
  const customSelectOptions =
    customs
      .map((item) => ({
        label: item.name,
        value: item.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  function handleSubmit() {
    let newCustomsArray = [...customsFromFilters];
    // Here we need to set the show state back to default
    setShowCustomSelector(false);

    // Push new values into the customs array
    // We do type of check in case we want to add multi select
    if (typeof selectedCustoms === "string") {
      newCustomsArray.push({ label: selectedCustoms, value: "" });
    } else {
      selectedCustoms.forEach((item) => {
        newCustomsArray.push({ label: item, value: "" });
      });
    }

    setState((prevState) => ({
      ...prevState,
      filters: { ...prevState.filters, customs: newCustomsArray },
    }));

    // Reset the selected items
    setSelectedCustoms([]);
  }

  return (
    (<Grid sx={parentSx} size={xsSize}>
      <Grid container>
        <Grid size={12}>
          {/* Header and Add filter button */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <InputLabel
              sx={{
                color: typography?.secondary,
                fontFamily: "Lato",
                fontWeight: "bold",
              }}
              id="map-custom-filters"
            >
              {showCustomSelector ? "Select Custom Filter" : "Custom Filters"}
            </InputLabel>
            {!showCustomSelector ? (
              <IconButton
                aria-label="Open Custom Filter Selector"
                onClick={() => setShowCustomSelector(true)}
              >
                <AddCircleIcon color="primary" cursor="pointer" size="small" />
              </IconButton>
            ) : (
              <Box>
                {/* Cancel */}
                <IconButton
                  aria-label="Close custom filter selector"
                  onClick={() => {
                    // Switch back to the default view
                    setShowCustomSelector(false);

                    // Clear any selection that might of been made
                    setSelectedCustoms([]);
                  }}
                >
                  <ClearIcon
                    className="pointer"
                    color="error"
                    cursor="pointer"
                    size="small"
                  />
                </IconButton>

                {/* Submit */}
                <IconButton
                  aria-label="Add Custom Filter"
                  onClick={() => handleSubmit()}
                >
                  <CheckIcon
                    className="pointer"
                    color="success"
                    cursor="pointer"
                    size="small"
                  />
                </IconButton>
              </Box>
            )}
          </Box>

          {/* Custom Selector */}
          {showCustomSelector ? (
            <Box sx={{ margin: ".5rem 0" }}>
              <SimpleSelect
                onChange={(event) => setSelectedCustoms(event.target.value)}
                options={customSelectOptions}
                size="large"
                variant="outlined"
                value={selectedCustoms}
              />
            </Box>
          ) : null}
        </Grid>
        {/* Rendered customs */}
        <Grid size={12}>
          <Card sx={cardSx}>
            <CardContent aria-labelledby="map-custom-filters" role="group">
              {customsFromFilters?.map((item, index) => (
                <Box key={`${item.label}-${index}`} sx={{ padding: ".5rem 0" }}>
                  <SimpleTextField
                    onChange={(event) =>
                      setState((prevState) => {
                        const newState = { ...prevState };
                        newState.filters.customs[index].value =
                          event.target.value;
                        return newState;
                      })
                    }
                    label={item.label}
                    onDeleteIconClick={() =>
                      setState((prevState) => {
                        const newState = { ...prevState };
                        newState?.filters?.customs?.splice(index, 1);
                        return newState;
                      })
                    }
                    showDeleteIcon
                    size="small"
                    value={customsFromFilters[index].value}
                  />
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>)
  );
}
