import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function CQRImagePreview(props) {

  const classes = {
    root: {
      margin: "1rem 1rem",
    },
    header: {
      marginBottom: "1rem",
    },
    textCenter: {
      textAlign: "center",
    },
  }

  const {
    apiUrl,
    batchAsset,
    qrBackgroundSession,
    eccLevel,
    magnification,
    selectedAssets,
    selectCQRLayout,
    version,
  } = props;
  const assetId = batchAsset
    ? props.assetId
    : selectedAssets[Object.keys(selectedAssets)[0]]
    ? selectedAssets[Object.keys(selectedAssets)[0]].assetId
    : "";
  return (
    <Card sx={classes.root}>
      <CardContent sx={classes.textCenter}>
        <Typography sx={classes.header} variant="h4">
          Image Preview
        </Typography>

        {selectCQRLayout.value === "addNew" && qrBackgroundSession.image ? (
          <img
            alt="cqr"
            style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
            src={qrBackgroundSession.image}
          />
        ) : selectCQRLayout.value ? (
          <img
            alt="cqr"
            style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
            src={`${apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}&qrBackground=${selectCQRLayout.label}`}
          />
        ) : (
          <img
            alt="cqr"
            style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
            src={`${apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}`}
          />
        )}
      </CardContent>
    </Card>
  );
}
