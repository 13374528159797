import { checkSession, onLogin, updateWidgetSettingsThunk } from "./thunks";

export const extraReducers = {
  [checkSession.pending]: (state) => {
    state.checkSession.status = "loading";
  },
  [checkSession.fulfilled]: (state, action) => {
    if (action.payload.error || !action.payload.success) {
      return {
        ...state,
      };
    } else {
      return {
        ...state,
        checkSession: {
          status: "success",
          sessionValid: action.payload?.success || false,
        },
        ...action.payload,
      };
    }
  },
  [checkSession.rejected]: (state) => {
    return {
      ...state,
      checkSession: {
        status: "error",
        sessionValid: false,
      },
    };
  },
  [onLogin.fulfilled]: (state, action) => {
    return {
      ...state,
      checkSession: {
        status: "success",
        sessionValid: action.payload?.success || false,
      },
      ...action.payload,
    };
  },
  [updateWidgetSettingsThunk.fulfilled]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};
