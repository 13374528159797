import { createSlice } from "@reduxjs/toolkit";

import { initSettings } from "./thunks/init";
import * as thunks from "./thunks/thunks";
import { extraReducers } from "./thunks/extraReducers";

const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        init: {
            status: "idle",
        },
        displayConfig: [],
        selectedTab: "Display Configuration",
    },
    reducers: {
        setSelectedTab(state, action) {
            state.selectedTab = action.payload;
        },
    },
    extraReducers: {
        ...extraReducers,
    },
 });


 export const { setSelectedTab } = settingsSlice.actions;
 export { initSettings, thunks };

 export default settingsSlice.reducer;