import { Box } from "@mui/material";
import SimpleCheckbox from "../../../../components/Forms/FieldTypes/Checkbox";

export default function FacilityImageViewOptions(props) {
  const {
    isFacilityPage = false,
    state = {},
    setState = () => {},
    mainSx = { marginTop: "1rem" },
  } = props;
  const { viewOptions = {} } = state;
  const {
    showZones = true,
    showRadios = true,
    showAssets = true,
  } = viewOptions;

  return (
    <Box sx={mainSx}>
      <SimpleCheckbox
        label="Show Zones"
        checked={showZones}
        onChange={() => {
          setState((prevState) => ({
            ...prevState,
            viewOptions: {
              ...prevState.viewOptions,
              showZones: !showZones,
            },
          }));
        }}
      />
      <SimpleCheckbox
        checked={showRadios}
        label="Show Radios"
        onChange={() =>
          setState((prevState) => ({
            ...prevState,
            viewOptions: {
              ...prevState.viewOptions,
              showRadios: !showRadios,
            },
          }))
        }
      />
      {isFacilityPage === true ? null : (
        <SimpleCheckbox
          checked={showAssets}
          label="Show Assets"
          onChange={() =>
            setState((prevState) => ({
              ...prevState,
              viewOptions: {
                ...prevState.viewOptions,
                showAssets: !showAssets,
              },
            }))
          }
        />
      )}
    </Box>
  );
}
