import { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import GoogleMapComponent from "../../../components/Maps/GoogleMapComponent";
import { Box, Typography } from "@mui/material";

export default function FacilitiesOverviewMap() {
  const facilitiesSelector = useSelector(
    (state) => state.organization.facilities,
    isEqual
  );
  const [mapSettings, setMapSettings] = useState({
    center: { lat: 60.363007, lng: -43.6339 },
    zoom: 5,
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const facilities = useMemo(
    () =>
      Object.keys(facilitiesSelector)
        .map((k) => facilitiesSelector[k])
        .map((facility) => {
          const validateLat =
            /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          const validateLong =
            /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          if (
            facility &&
            (facility.location?.latitude !== 0 ||
              facility.location?.longitude !== 0) &&
            validateLat.test(facility.location?.latitude) &&
            validateLong.test(facility.location?.longitude)
          ) {
            return facility;
          }
          return null;
        })
        .filter((facility) => facility !== null) || [],
    [facilitiesSelector]
  );

  const markersToRender = facilities.map((facility) => {
    const { location = {} } = facility;
    const { latitude, longitude } = location;

    return { ...facility, latitude, longitude };
  });

  const geofencesToRender = facilities.map((facility) => {
    const { propertiesMap = {} } = facility;
    const { geofence } = propertiesMap;

    if (geofence) {
      return { ...geofence, facility };
    }
  })
    .filter(facility => facility !== undefined);

  const popUpContent = (facility) => {
    const {
      propertiesMap = null,
      location = null,
      facilityType = null,
      name = null,
      radius = null,
    } = facility;
    const { address1 = null, city = null, state = null, } = location
    const { locationDetails = null } = propertiesMap || {};

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {name ? (
          <Typography component="h2" sx={{ fontSize: "22px" }}>
            {name}
          </Typography>
        ) : (
          ""
        )}
        {facilityType ? (
          <Typography component={"span"} sx={{ fontSize: "22px" }}>
            {facilityType}
          </Typography>
        ) : (
          ""
        )}{" "}
        {location ? (
          <Typography component={"span"} sx={{ fontSize: "18px" }}>
            {address1 && city && state
              ? address1 + ", " + city + ", " + state
              : city && state
                ? city + ", " + state
                : ""}
          </Typography>
        ) : null}
        {radius ? (
          <Typography component={"span"} sx={{ fontSize: "14px" }}>
            Radius: {radius} KM{" "}
          </Typography>
        ) : (
          ""
        )}
        {locationDetails ? (
          <Typography component={"span"} sx={{ fontSize: "14px" }}>
            Note: {locationDetails}
          </Typography>
        ) : (
          ""
        )}
      </Box>
    );
  };

  // This useEffect sets the center of hte map to the first facility in the list
  useEffect(() => {
    if (facilities.length > 0) {
      const { location = {} } = facilities[0];
      const { latitude, longitude } = location;
      setMapSettings((prev) => ({
        ...prev,
        center: { lat: latitude, lng: longitude },
      }));
    }
  }, [facilities]);

  return (
    <GoogleMapComponent
      activeMarker={activeMarker}
      mapSettings={mapSettings}
      markersToRender={markersToRender}
      markerKey="facilityId"
      polygons={geofencesToRender}
      popUpContent={popUpContent}
      setActiveMarker={setActiveMarker}
    />
  );
}