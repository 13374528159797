import { Fragment } from "react"

export default function HoverIconButton(props) {
  const {
    cypressId,
    disabled,
    downloadDocType,
    exportItemType,
    handleClick,
    icon,
    iconDirection,
    text,
  } = props


  function handleKeyPress(e) {
    if (e.key === "Enter" || e.code === "Space") { e.target.click() }
  }

  return (
    <div  className={disabled ? "hoverIconDisabled" : "hoverIcon"} data-cypress-id={cypressId} onKeyPress={handleKeyPress} onClick={() => {handleClick()}} role="link" tabIndex="0">
      {iconDirection === "left" ?
        <>
          <Fragment>{icon}</Fragment>
          {text || ""}
          {downloadDocType && <span className="screen-reader-only">{exportItemType ? `selected ${exportItemType}` : null} {downloadDocType} file download</span>}
        </>
        :
        <>
          {text || ""}
          {downloadDocType && <span className="screen-reader-only">{exportItemType ? `selected ${exportItemType}` : null} {downloadDocType} file download</span>}
          <Fragment>{icon}</Fragment>
        </>
      }
    </div>

  )
}