import Grid from "@mui/material/Grid2";
import Dashboard from "../dashboard/dashboard";

export default function SplashPage(props) {
  return (
    (<Grid container>
      <Grid size={12}>
        <Dashboard {...props} />
      </Grid>
    </Grid>)
  );
}