import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Information from "./Tabs/Information";
import { useTheme } from "@mui/material";
import { Box } from "@mui/material";


function FacilityDetail(props) {

  const theme = useTheme()

  const classes = {
    content: {
      minHeight: "75vh",
      maxHeight: "75vh",
      overflow: "auto",
    },
    root: {
      margin: 0,
    },
    closeButton: {
      color: theme.palette.grey[500],
      padding: 0
    },
  }

  const { handleClose, open, title } = props

  return (
    <Dialog
      aria-labelledby={title}
      sx={classes.root}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={open || false}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          {handleClose ? (
            <IconButton
              aria-label="close"
              sx={classes.closeButton}
              onClick={handleClose}
              size="large">
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </DialogTitle>
      <DialogContent sx={classes.content}>
        <Information label="Information" {...props} />
      </DialogContent>
    </Dialog>
  );
}

export default FacilityDetail;
