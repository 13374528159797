import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { fixedDrawerStyles } from "./styles";
import lxlogowhitegreen from "../../../../assets/lxlogowhitegreen.png";
import MuiSVGIcon from "../../../IconPicker/MuiSVGIcon";

export default function FixedDrawer(props) {
  const { appBarHeight, navLinks, sideNavWidth, stickyNavLinks } = props;
  const classes = fixedDrawerStyles({ sideNavWidth, appBarHeight });
  const history = useHistory();
  const isActive = (path) => {
    return history.location.pathname.includes(path);
  };

  return (
    <Drawer variant="permanent" sx={classes.drawer}>
      <List>
        <ListItem
          className="pointer"
          key="logo-nav-item"
          onClick={() => {
            history.push("/");
          }}
          sx={classes.menuIcon}
        >
            <img
              alt="locatorx company logo"
              src={lxlogowhitegreen}
              height="100%"
            />
        </ListItem>
        {/* <Divider /> */}
        {navLinks.map((link, idx) => (
          <ListItem
            key={`${link.name} - ${idx}`}
            onClick={() => {
              if (link.hyperlink) {
                window.open(link.hyperlink, "_blank", "noopener,noreferrer");
              } else {
                history.push(`${"/"}${link.name}`);
              }
            }}
            sx={{
              ...classes.listItem,
              // I spent too much time trying to figure this out in the styles.js
              backgroundColor: (theme) => {
                if (isActive(link.name)) {
                  return theme?.palette?.success?.main;
                }
              },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
            >
              <MuiSVGIcon
                icon={link.icon}
                key={`${link.name} - ${idx} icon`}
                sx={classes.listItemIcon}
              />
              <ListItemText
                primary={link.label}
                key={`${link.name} - ${idx} text`}
                sx={classes.listItemText}
              />
            </Box>
          </ListItem>
        ))}

        <Box sx={classes.bottomPush} />

        {/* For information and support menu items on the bottom of the nav */}
        {stickyNavLinks.map((link, idx) => {
          return (
            <ListItem
              key={`${link.name} - ${idx}`}
              onClick={() => {
                if (link.hyperlink) {
                  window.open(link.hyperlink, "_blank", "noopener,noreferrer");
                } else {
                  history.push(`${"/"}${link.name}`);
                }
              }}
              sx={{
                ...classes.listItem,
                // I spent too much time trying to figure this out in the styles.js
                backgroundColor: (theme) => {
                  if (isActive(link.name)) {
                    return theme?.palette?.success?.main;
                  }
                },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
              >
                <MuiSVGIcon
                  icon={link.icon}
                  key={`${link.name} - ${idx} icon`}
                  sx={classes.listItemIcon}
                />
                <ListItemText
                  primary={link.label}
                  key={`${link.name} - ${idx} text`}
                  sx={classes.listItemText}
                />
              </Box>
            </ListItem>
          );
        })}

      </List>
    </Drawer>
  );
}
