import { useEffect, useState } from "react";
import { retrieveAssetChildren } from "../api";
import VisibilityIcon from "@mui/icons-material/Visibility";

export function useTreeView(props) {
  const {
    apiUrl,
    assetData,
    assetId,
    buttonClass,
    facilities,
    history,
    mounted,
    organizationId,
    pipeClass,
    token,
    zones,
  } = props;

  const [treeView, setTreeView] = useState();

  // Handles the Hierarchy Tab and Hierarchy Status Table
  useEffect(() => {
    const viewSnapshot = (assetId) => {
      history.push(`/assetSnapshot/${assetId}`);
      // history.go(0);
    };

    const recursiveFetch = (assetId) => {
      const fetchChildren = async (assetId) => {
        const children = await retrieveAssetChildren(
          { organizationId, apiUrl, token, zones, facilities },
          assetId
        );

        const results = children.map(async (child) => {
          child.children = await fetchChildren(child.id);
          return child;
        });

        return await Promise.all(results).then((completed) => {
          return completed;
        });
      };

      const results = fetchChildren(assetId);

      return results;
    };

    const pipe = <span className={pipeClass}></span>;

    const generateTreeView = async () => {
      // if assetData has ancestors, i.e., it is itself a child, then start the root of the tree at the last item in its ancestor list,
      // i.e., it's progenitor
      const results = await recursiveFetch(
        assetData.ancestors?.length
          ? assetData.ancestors[assetData.ancestors.length - 1]
          : assetData.assetId
      ).then(async (children) => {
        const recurseAndCount = async (node) => {
          const results = node.map(async (child) => {
            const label =
              assetData.assetId === child.id
                ? "Current Asset Tag: "
                : assetData.ancestors?.includes(child.id)
                ? "Parent Asset Tag: "
                : "Child Asset Tag: ";

            const showViewSnapshotIcon = assetData.assetId !== child.id;

            if (child?.children?.length > 0) {
              child.label = (
                <>
                  <span>
                    <strong>{label}</strong> {child.assetTag}
                    {pipe}
                    <strong>Last Event:</strong> {child.lastEvent}
                    {pipe}
                    <strong>Children:</strong> {child.children.length}
                    {showViewSnapshotIcon ? (
                      <>
                        {pipe}
                        <span
                          className={buttonClass}
                          onClick={() => viewSnapshot(child.id)}
                        >
                          View Snapshot{" "}
                          <VisibilityIcon
                            className={buttonClass}
                            color="primary"
                            fontSize="small"
                          />
                        </span>{" "}
                      </>
                    ) : null}
                  </span>
                </>
              );

              recurseAndCount(child.children);
              return child;
            } else {
              child.label = (
                <>
                  <strong>{label}</strong> {child.assetTag}
                  {pipe}
                  <strong>Last Event:</strong> {child.lastEvent}
                  {showViewSnapshotIcon ? (
                    <>
                      {pipe}
                      <span
                        className={buttonClass}
                        onClick={() => viewSnapshot(child.id)}
                      >
                        View Snapshot{" "}
                        <VisibilityIcon
                          className={buttonClass}
                          color="primary"
                          fontSize="small"
                        />
                      </span>{" "}
                    </>
                  ) : null}
                </>
              );
              return child;
            }
          });

          return await Promise.all(results).then((completed) => {
            return completed;
          });
        };

        return await recurseAndCount(children).then((children) => {
          const rootAncestorSource =
            assetData?.ancestors?.length > 0 &&
            assetData?.ancestorList?.length > 0
              ? assetData?.ancestorList?.filter(
                  (ancestor) =>
                    ancestor.assetId ===
                    assetData.ancestors[assetData.ancestors.length - 1]
                )
              : [assetData];

          const rootAncestor = rootAncestorSource?.map((asset) => {
            const zone =
              zones &&
              asset?.zone &&
              asset.zone?.zoneId &&
              zones[asset.zone.zoneId]
                ? zones[asset.zone.zoneId].name
                : null;
            const binLocation =
              asset.zone && asset.zone.binLocation
                ? asset.zone.binLocation
                : null;
            return {
              assetTag: asset.tag,
              ancestorList: asset.ancestorList || [],
              ancestors: asset.ancestors || [],
              binLocation,
              facility: facilities[asset.facilityId]
                ? facilities[asset.facilityId].name
                : "",
              id: asset.assetId,
              lastEvent: asset.lastEvent,
              parentId: asset.parentId,
              quantityNeeded: asset.quantity
                ? asset.quantity.quantityNeeded
                : null,
              quantityPicked: asset.quantity
                ? asset.quantity.quantityPicked
                : null,
              zone,
            };
          })[0];

          const rootAncestorChildrenCount = Array.isArray(children)
            ? children.length
            : 0;

          const rootAncestorTag =
            assetData.assetId === rootAncestor.id
              ? "Current Asset Tag: "
              : "Parent Asset Tag: ";

          const showViewSnapshotIcon = rootAncestor.id !== assetData.assetId;

          const rootAncestorLabel = rootAncestor ? (
            <>
              <span>
                <strong>{rootAncestorTag}</strong> {rootAncestor.assetTag}
                {pipe}
                <strong>Last Event:</strong> {rootAncestor.lastEvent}
                {rootAncestorChildrenCount ? (
                  <>
                    {pipe}
                    <strong>Children:</strong> {rootAncestorChildrenCount}
                  </>
                ) : null}
                {showViewSnapshotIcon ? (
                  <>
                    {pipe}
                    <span
                      className={buttonClass}
                      onClick={() => viewSnapshot(rootAncestor.id)}
                    >
                      View Snapshot{" "}
                      <VisibilityIcon
                        className={buttonClass}
                        color="primary"
                        fontSize="small"
                      />
                    </span>
                  </>
                ) : null}
              </span>
            </>
          ) : null;

          return {
            id: rootAncestor.id,
            label: rootAncestorLabel,
            children,
            defaultSelected: assetData.assetId,
            defaultExpanded: [
              ...(assetData.ancestors || []),
              assetData.assetId,
            ],
            complete: true,
          };
        });
      });

      return results;
    };

    if (mounted && assetData?.assetId) {
      generateTreeView().then((res) => {
        setTreeView((t) => {
          return { ...t, ...res, complete: true };
        });
      });
    }

    return () => {
      setTreeView((prevState) => {
        return {
          ...prevState,
          complete: false,
        };
      });
    };
  }, [
    buttonClass,
    pipeClass,
    facilities,
    history,
    assetId,
    organizationId,
    apiUrl,
    token,
    zones,
    assetData,
    mounted,
  ]);

  return treeView;
}
