import { lazy, Suspense, useEffect, useState } from "react";
import {
  addZone,
  editZone as updateZone,
  updateFacilityZones,
  removeZones,
} from "../../../globalStore/slices/organization/organizationSlice";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { deleteZone, createZone, editZone } from "./API/api";
import { isEqual } from "lodash";
import { naturalSort } from "../../../utils/naturalSort";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConfirmationModalContent from "../../../components/Modals/ConfirmationModalContent";
import CreEditModal from "../../../components/Modals/CreEditModal/CreEditModal";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import Loading from "../../../components/Loading/Loading";
import MaterialConfirmationModal from "../../../components/Modals/MaterialConfirmationModal";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import NotificationModal from "../../../components/Modals/NotificationModal";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";
import ZoneDetail from "./ZoneDetail/ZoneDetail";

// Component uses xlxs which is very heavy, which is why we are using lazy/Suspense
const ZonesTable = lazy(() => import("./ZonesTable/ZonesTable"));

export default function Zones(props) {
  const classes = {
    titleContainer: {
      display: "flex",
      marginBottom: "1rem",
      justifyContent: "space-between",
    },
  };

  const { apiUrl, facilities, token, permissions, view, setView } = props;
  const [confirm, setConfirm] = useState({
    confirmShow: false,
    text: "",
    color: "",
    icon: "",
  });
  const [dialog, setDialog] = useState({
    dialogTitle: "",
    dialogShow: false,
    selectedZone: {},
  });
  const zones = useSelector((state) => state.organization.zones, isEqual);

  const organization = useSelector(
    (state) => state.organization.organization,
    isEqual
  );

  const { facility = {} } = view;
  const [state, setState] = useState({
    facility: facility,
    zones: [],
    zoneHashMap: {},
    selectedZones: {},
    selectedZone: {},
  });

  const { zoneCategories = [] } = organization?.propertiesMap || {};

  const categorySelectOptions = [...zoneCategories]
    .sort((a, b) => naturalSort(a, b))
    .map((element) => ({
      label: element,
      value: element,
    }));

  const [alertModalState, setAlertModalState] = useState({
    alertModalColor: "success",
    modalShow: false,
    modalText: "",
  });

  const initialZonesModalFields = {
    abbreviation: {
      error: false,
      id: "abbreviation",
      label: "Abbreviation",
      required: false,
      type: "text",
      value: "",
    },
    category: {
      error: false,
      id: "category",
      label: "Category",
      required: false,
      options: [{ label: "-Select-", value: "" }, ...categorySelectOptions],
      type: "select",
      value: "",
    },
    description: {
      error: false,
      id: "description",
      label: "Description",
      multiline: true,
      required: false,
      type: "text",
      value: "",
      xs: 12,
    },
    floor: {
      error: false,
      id: "floor",
      label: "Floor",
      required: false,
      type: "text",
      value: "",
    },
    name: {
      error: false,
      id: "name",
      label: "Name",
      required: true,
      type: "text",
      value: "",
    },
    parentId: {
      error: false,
      id: "parentId",
      label: "Parent Zone",
      required: false,
      options: [{ label: "-Select-", value: "" }],
      type: "select",
      value: "",
    },
    radius: {
      error: false,
      id: "radius",
      label: "Radius",
      required: false,
      type: "number",
      value: "",
    },
    xCoordinate: {
      error: false,
      id: "xCoordinate",
      label: "X Coordinate",
      required: false,
      type: "number",
      value: "",
    },
    yCoordinate: {
      error: false,
      id: "yCoordinate",
      label: "Y Coordinate",
      required: false,
      type: "number",
      value: "",
    },
    // zCoordinate: {
    //   error: false,
    //   id: "zCoordinate",
    //   label: "Z Coordinate",
    //   required: false,
    //   type: "number",
    //   value: "",
    // },
    zoneType: {
      changeHandler: updateParentZoneOptions,
      error: false,
      id: "zoneType",
      label: "Zone Type",
      options: [
        {
          label: "-Select-",
          value: "",
        },
        {
          label: "Top Level",
          value: "top level",
        },
        {
          label: "Processing",
          value: "processing",
        },
        {
          label: "Target",
          value: "target",
        },
      ],
      required: true,
      type: "select",
      value: "",
    },
  };

  const [zonesModalFields, setZonesModalFields] = useState(
    initialZonesModalFields
  );

  const [creEditModalOpen, setCreEditModalOpen] = useState(false);
  const [creEditModalType, setCreEditModalType] = useState("Create");

  const dispatchGlobal = useDispatch();

  function validateFields(data) {
    const errors = {};

    // Validate each field
    for (const field in data) {
      const { id, label, required, value } = data[field];

      if (required && (value === undefined || value === "")) {
        errors[id] = `${label} is required.`;
      }
    }

    // Check if x, y, and z coordinates are all present or all absent
    const coordinates = [
      "xCoordinate",
      "yCoordinate",
      // "zCoordinate"
    ];
    const coordinateValues = coordinates.map((coord) => data[coord].value);
    const atLeastOneCoordinatePresent = coordinateValues.some(
      (value) => value !== undefined && value !== ""
    );

    if (
      atLeastOneCoordinatePresent &&
      !coordinateValues.every((value) => value !== undefined && value !== "")
    ) {
      coordinates.forEach((coord) => {
        errors[coord] =
          "All coordinates (X, Y, Z) must be present if at least one is present.";
      });
    }

    return errors;
  }
  const handleDelete = (id) => {
    deleteZone({ apiUrl, token }, id)
      .then((res) => {
        if (res.success) {
          // replace this with an update to zones so that
          //if deleted zone was a processing zone, also delete all target zones that had its id as parent id
          //if deleted zone was a top level zone, also delete all processing zones that had its id as parent id and all target zones related to those processing zones

          const deletedZone = state.zones.find((zone) => zone.zoneId === id);
          const deletedZoneType = deletedZone?.internalZoneType;

          let updatedZones = [];
          //if deleted zone is a target zone, filter out that zone from the zones
          if (deletedZoneType === "target") {
            updatedZones = state.zones.filter((zone) => zone.zoneId !== id);
          }
          //if deleted zone is a processing zone, filter out that zone and all target zones that had its id as parent id
          else if (deletedZoneType === "processing") {
            updatedZones = state.zones.filter(
              (zone) => zone.zoneId !== id && zone.parentId !== id
            );
          }

          //if deleted zone is a top level zone, filter out that zone, all processing zones that had its id as parent id, as well as the target zones related to each of the processing zones
          else if (deletedZoneType === "top level") {
            // Filter out the deleted zone
            updatedZones = state.zones.filter((zone) => zone.zoneId !== id);

            // Get IDs of processing zones that had the deleted zone as parent
            const processingZoneIds = state.zones
              .filter(
                (zone) =>
                  zone.parentId === id && zone.internalZoneType === "processing"
              )
              .map((zone) => zone.zoneId);

            // Filter out processing zones and their related target zones
            updatedZones = updatedZones.filter((zone) => {
              // If current zone is processing, and it's related to the deleted zone
              if (
                zone.internalZoneType === "processing" &&
                processingZoneIds.includes(zone.zoneId)
              ) {
                // Filter out this processing zone
                return false;
              }
              // If current zone is target and its parent is a processing zone related to the deleted zone
              if (
                zone.internalZoneType === "target" &&
                processingZoneIds.includes(zone.parentId)
              ) {
                // Filter out this target zone
                return false;
              }
              // Keep the zone if it doesn't match any condition above
              return true;
            });
          }

          //invoke updateFacilityZones action creator
          dispatchGlobal(
            updateFacilityZones({
              facilityId: state.facility.facilityId,
              updatedZones,
            })
          );

          //using updatedZones, get all the zones that werent deleted and create an array of ids to pass to removeZones action creator
          const updatedZoneIds = updatedZones.map((zone) => zone.zoneId);

          //get id of all zones that are in zones but not in updatedZones. Put them in an array
          const deletedZones = state.zones
            .filter((zone) => !updatedZoneIds.includes(zone.zoneId))
            .map((zone) => zone.zoneId);

          //invoke removeZones action creator to update zones store
          dispatchGlobal(removeZones(deletedZones));

          //TODO: clear cache for facilities so facilities page will update once user returns to it

          dialogClose();
          setAlertModalState((prevState) => ({
            ...prevState,
            alertModalColor: "success",
            modalShow: true,
            modalText: "Zone successfully deleted",
          }));
        }
      })
      .catch((err) => {
        setAlertModalState((prevState) => ({
          ...prevState,
          alertModalColor: "error",
          modalShow: true,
          modalText: `There was a problem deleting the selected zone: ${err}`,
        }));
      });
  };

  const handleZoneCreate = () => {
    const errors = validateFields(zonesModalFields);
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      setZonesModalFields((prevState) => {
        const fields = { ...prevState };

        for (const field in fields) {
          if (errors[field]) {
            fields[field].error = true;
            fields[field].errorText = errors[field];
          }
        }

        return fields;
      });

      return;
    }

    const payload = {
      abbreviation: zonesModalFields.abbreviation.value,
      category: zonesModalFields.category.value,
      facilityId: state.facility?.facilityId,
      floor: zonesModalFields.floor.value,
      internalZoneType: zonesModalFields.zoneType.value,
      name: zonesModalFields.name.value,
      parentId: zonesModalFields.parentId.value,
      propertiesMap: {
        description: zonesModalFields.description.value,
      },
      radius: zonesModalFields.radius.value,
      xyz: {
        x: zonesModalFields.xCoordinate.value,
        y: zonesModalFields.yCoordinate.value,
        // z: zonesModalFields.zCoordinate.value,
      },
      zoneType: zonesModalFields.zoneType.value,
    };

    createZone(props, payload)
      .then((res) => {
        if (res.success) {
          const newZone = res?.zone;

          // invoke addZone action creator
          dispatchGlobal(addZone(newZone));

          //update in facility store
          dispatchGlobal(
            updateFacilityZones({
              facilityId: state.facility.facilityId,
              updatedZones: [...state.zones, newZone],
            })
          );

          //TODO: clear cache for facilities so facilities page will update once user returns to it

          setCreEditModalOpen(false);

          setAlertModalState((prevState) => ({
            ...prevState,
            alertModalColor: "success",
            modalShow: true,
            modalText: "Zone successfully created",
          }));
        } else {
          setAlertModalState((prevState) => ({
            ...prevState,
            alertModalColor: "error",
            modalShow: true,
            modalText: `${res.errors}`,
          }));
        }
      })
      .catch((err) => {
        setAlertModalState((prevState) => ({
          ...prevState,
          alertModalColor: "error",
          modalShow: true,
          modalText: `${err}`,
        }));
      });
  };

  const handleZoneEdit = () => {
    const errors = validateFields(zonesModalFields);
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      setZonesModalFields((prevState) => {
        const fields = { ...prevState };

        for (const field in fields) {
          if (errors[field]) {
            fields[field].error = true;
            fields[field].errorText = errors[field];
          }
        }

        return fields;
      });

      return;
    }

    //create payload from zonesModalFields
    const payload = {
      abbreviation: zonesModalFields.abbreviation.value,
      category: zonesModalFields.category.value,
      facilityId: state.facility?.facilityId,
      floor: zonesModalFields.floor.value,
      internalZoneType: zonesModalFields.zoneType.value,
      name: zonesModalFields.name.value,
      parentId: zonesModalFields.parentId.value,
      propertiesMap: {
        description: zonesModalFields.description.value,
      },
      radius: zonesModalFields.radius.value,
      xyz: {
        x: zonesModalFields.xCoordinate.value,
        y: zonesModalFields.yCoordinate.value,
        // z: zonesModalFields.zCoordinate.value,
      },
      zoneType: zonesModalFields.zoneType.value,
    };

    editZone(props, state?.selectedZone?.zoneId, payload)
      .then((res) => {
        if (res.success) {
          const editedZone = res?.zone;
          // invoke editZone action creator imported as updateZone since there was already an editZone from a different location
          dispatchGlobal(updateZone(editedZone));

          // update edited zone in zones array
          const updatedZones = state.zones.map((zone) => {
            if (zone.zoneId === editedZone.zoneId) {
              return editedZone;
            }
            return zone;
          });

          //run dispatch of updateFacilityZones
          dispatchGlobal(
            updateFacilityZones({
              facilityId: state.facility.facilityId,
              updatedZones,
            })
          );

          //TODO: clear cache for facilities so facilities page will update once user returns to it

          setCreEditModalOpen(false);

          setAlertModalState((prevState) => ({
            ...prevState,
            alertModalColor: "success",
            modalShow: true,
            modalText: "Zone successfully edited",
          }));
        } else {
          setAlertModalState((prevState) => ({
            ...prevState,
            alertModalColor: "error",
            modalShow: true,
            modalText: `${res.errors}`,
          }));
        }
      })
      .catch((err) => {
        setAlertModalState((prevState) => ({
          ...prevState,
          alertModalColor: "error",
          modalShow: true,
          modalText: `${err}`,
        }));
      });
  };

  function updateParentZoneOptions(e) {
    const zoneType = e.target.value;
    //sort, filter, and map the zones based on zoneType
    const parentZoneSelectOptions = [...state.zones]
      .filter((element) => {
        if (zoneType === "processing") {
          return element.zoneType === "top level";
        } else if (zoneType === "target") {
          return element.zoneType === "processing";
        }
      })
      .sort((a, b) => naturalSort(a.name, b.name))
      .map((element) => ({
        label: element.name,
        value: element.zoneId,
      }));

    let required = false;
    //if zoneType is "processing" or "target", set required to true for parentZone
    if (zoneType === "processing" || zoneType === "target") {
      required = true;
    }

    let errorReset = {};
    //if zonetype is top level, reset the errors for the parentZone field
    if (zoneType === "top level") {
      errorReset = { error: false, errorText: "" };
    }

    //set zoneModalFields parentZone options to the new parentZoneSelectOptions
    setZonesModalFields((prevState) => ({
      ...prevState,
      parentId: {
        ...prevState.parentId,
        options: [{ label: "-Select-", value: "" }, ...parentZoneSelectOptions],
        required: required,
        ...errorReset,
      },
      //set zoneType value while you're at it
      zoneType: { ...prevState.zoneType, value: zoneType },
    }));
  }

  // TODO: phase out and replace with NotificationModal
  const handleConfirmationModal = (confirmationText, color, icon) => {
    setConfirm({
      ...confirm,
      confirmShow: true,
      text: confirmationText,
      color: color,
      icon: icon,
    });
  };

  const dialogClose = () =>
    setDialog({
      ...dialog,
      dialogShow: false,
      dialogTitle: "",
      selectedZone: {},
    });

  const notificationModalClose = () => {
    setConfirm({ ...confirm, confirmShow: false });
  };

  const { dialogTitle, dialogShow, selectedZone } = dialog;

  const switchDialog = (dialog) => {
    switch (dialog) {
      case "Zone Detail":
        return (
          <ZoneDetail
            handleClose={() => {
              dialogClose();
            }}
            open={true}
            token={token}
            apiUrl={apiUrl}
            selectedZone={selectedZone}
            notificationModal={handleConfirmationModal}
            zonesMap={zones || {}}
            facility={facility}
          />
        );
      case "Delete Zone":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedZone.name}? This will result in ALL sub zones being deleted as well. This action cannot be undone.`}
            handleSubmit={() => {
              handleDelete(selectedZone.zoneId);
            }}
            handleCancel={() => {
              dialogClose();
            }}
          />
        );
      default:
        return;
    }
  };

  //iterate through the fields and create a textfield for each
  const fields = Object.values(zonesModalFields).map((field) => {
    const {
      changeHandler,
      disabled,
      error,
      errorText,
      id,
      label,
      multiline,
      options,
      required,
      type,
      value,
      xs,
    } = field;

    if (type === "text" || type === "number") {
      return (
        (<Grid key={id} size={xs || 6}>
          <SimpleTextField
            error={error}
            errorText={errorText}
            id={id}
            key={id}
            label={label}
            multiline={multiline}
            onChange={(e) => {
              setZonesModalFields((prevState) => ({
                ...prevState,
                [id]: {
                  ...prevState[id],
                  value: e.target.value,
                },
              }));
            }}
            required={required}
            type={type}
            value={value}
          />
        </Grid>)
      );
    } else if (type === "select") {
      return (
        (<Grid key={id} size={xs || 6}>
          <SimpleSelect
            disabled={disabled}
            error={error}
            errorText={errorText}
            id={id}
            key={id}
            label={label}
            multiline={multiline}
            options={options}
            //if theres a changeHandler, use that instead of the default onChange
            onChange={
              changeHandler
                ? (e) => changeHandler(e)
                : (e) => {
                    setZonesModalFields((prevState) => ({
                      ...prevState,
                      [id]: {
                        ...prevState[id],
                        value: e.target.value,
                      },
                    }));
                  }
            }
            value={value}
            required={required}
            variant="outlined"
          />
        </Grid>)
      );
    }
  });

  // This useEffect sets up the zonesModalFields. This defaults the modal values.
  useEffect(() => {
    if (creEditModalType === "Create") {
      setZonesModalFields(initialZonesModalFields);
      return;
    }

    //set zone fields based on the selected zones properties
    else if (creEditModalType === "Edit") {
      const selectedZone = state.selectedZone;
      const {
        abbreviation,
        category,
        floor,
        name,
        parentId,
        propertiesMap = {},
        radius,
        xyz,
        zoneType,
      } = selectedZone;
      const { description = "" } = propertiesMap;

      //create parentId options based on zoneType
      const parentZoneSelectOptions = [...state.zones]
        .filter((element) => {
          if (zoneType === "processing") {
            return element.zoneType === "top level";
          } else if (zoneType === "target") {
            return element.zoneType === "processing";
          }
        })
        .sort((a, b) => naturalSort(a.name, b.name))
        .map((element) => ({
          label: element.name,
          value: element.zoneId,
        }));

      setZonesModalFields((prevState) => ({
        ...prevState,
        //Set fields and clear errors. Otherwise, error from previous edit submit will persist
        abbreviation: {
          ...prevState.abbreviation,
          error: false,
          errorText: "",
          value: abbreviation,
        },
        category: {
          ...prevState.category,
          error: false,
          errorText: "",
          value: category,
        },
        description: {
          ...prevState.description,
          error: false,
          errorText: "",
          value: description,
        },
        floor: {
          ...prevState.floor,
          error: false,
          errorText: "",
          value: floor,
        },
        name: { ...prevState.name, error: false, errorText: "", value: name },
        parentId: {
          ...prevState.parentId,
          error: false,
          errorText: "",
          options: [
            { label: "-Select-", value: "" },
            ...parentZoneSelectOptions,
          ],
          value: parentId,
          disabled: true,
        },
        radius: {
          ...prevState.radius,
          error: false,
          errorText: "",
          value: radius,
        },
        xCoordinate: {
          ...prevState.xCoordinate,
          error: false,
          errorText: "",
          value: xyz?.x,
        },
        yCoordinate: {
          ...prevState.yCoordinate,
          error: false,
          errorText: "",
          value: xyz?.y,
        },
        // zCoordinate: {
        //   ...prevState.zCoordinate,
        //   error: false,
        //   errorText: "",
        //   value: xyz?.z,
        // },
        zoneType: {
          ...prevState.zoneType,
          error: false,
          errorText: "",
          value: zoneType,
          disabled: true,
          required: false,
        },
      }));
    }
  }, [state.selectedZone, creEditModalOpen]);

  useEffect(() => {
    const zoneHashMap = {};
    const selectedFacility = facilities[view.facility.facilityId];
    const selectedFacilityId = selectedFacility?.facilityId;

    //filter through zones and only keep the zones that belong to the selected facility. zones is an object, not an array
    const selectedFacilityZones = Object.values(zones).filter(
      (zone) => zone.facilityId === selectedFacilityId
    );

    //create a hashmap of zones that belong to the selected facility
    selectedFacilityZones.forEach((zone) => {
      zoneHashMap[zone.name] = zone;
    });

    setState((prevState) => ({
      ...prevState,
      facility: selectedFacility,
      zones: selectedFacilityZones,
      zoneHashMap,
    }));
  }, [view.facility.facilityId, zones]);

  return (
    (<Box ml={1} mt={2} mb={2}>
      <Grid
        container
        sx={{
          margin: "0 auto",
          width: "84vw",
        }}
      >
        <ModalDialog
          content={switchDialog(dialogTitle)}
          handleClose={dialogClose}
          open={dialogShow}
          title={dialogTitle}
        />

        <NotificationModal
          confirmationModalShow={alertModalState.modalShow}
          modalClose={() => {
            setAlertModalState(() => ({
              alertModalColor: "success",
              modalShow: false,
              modalText: "",
            }));
          }}
          confirmationText={alertModalState.modalText}
          color={alertModalState.alertModalColor}
        />
        {/* TODO: being replaced with NotificationModal */}
        <MaterialConfirmationModal
          content={confirm.text}
          closeModal={notificationModalClose}
          modalOpen={confirm.confirmShow}
          severity={confirm.color ? "error" : "success"}
          variant="filled"
        />

        <Grid sx={classes.titleContainer} size={12}>
          <Typography component="h1" variant="h5">
            {state.facility.name} Zones
          </Typography>
          <HoverIconButton
            cypressId="facilities-zones-btn-back"
            handleClick={() =>
              setView({
                facility: {},
                viewZones: false,
              })
            }
            icon={<ArrowBackIcon/>}
            iconDirection="left"
            text="Back to Facilities"
          />
        </Grid>

        <CreEditModal
          fields={fields}
          handleEditSubmit={handleZoneEdit}
          handleCreateSubmit={handleZoneCreate}
          modalOpen={creEditModalOpen}
          modalType={creEditModalType}
          setModalOpen={setCreEditModalOpen}
          state={state}
          setState={setState}
          title="Zone"
        />

        <Suspense fallback={<Loading />}>
          <ZonesTable
            diaog={dialog}
            handleConfirmationModal={handleConfirmationModal}
            permissions={permissions}
            setCreEditModalOpen={setCreEditModalOpen}
            setCreEditModalType={setCreEditModalType}
            setDialog={setDialog}
            setState={setState}
            setView={setView}
            state={state}
          />
        </Suspense>
      </Grid>
    </Box>)
  );
}
