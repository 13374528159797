import { createSlice } from "@reduxjs/toolkit";
import { initInventory } from "./thunks/init";
import * as thunks from "./thunks/thunks";
import { extraReducers } from "./thunks/extraReducers";

export const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    init: {
      status: "idle",
    },
    filters: {},
    assets: [],
    count: 0,
  },
  reducers: {},
  extraReducers: {
    ...extraReducers,
  },
});

// Action creators are generated for each case reducer function
// const {} = devicesSlice.actions;

export { initInventory, thunks };

export default inventorySlice.reducer;
