import { buildDeviceAssetsMap } from "../devices/utils/utils";
import { getAppUser, getZonesData } from "./api";
import { getBatchData, getBatchAssets, getProductData } from "./api";
import { getDevices } from "../../utils/API/Devices/Devices";

export const naturalSort = (a, b, language) => {
  // Intl Collator can help us sort diacritics and other idiosyncracies across languages, supported on 95% of browsers (including IE). Setting "sensitivity" to "base" ignores case sensitivity, and "numeric" to "true" sorts alphanumerically
  return new Intl.Collator(language || undefined, {
    numeric: true,
    sensitivity: "base",
  }).compare(a, b);
};

export const init = async (props) => {
  const { organization = {}, facilities, usersMap, batchIdFromParams } = props;

  const appUser = await getAppUser(props);
  const { propertiesMap = {}, userTimeZone } = appUser;
  const eventTypes =
    organization && organization.eventTypesMap
      ? [
        ...Object.keys(organization.eventTypesMap),
        "origin",
        "scan",
        "leaveGeoFence",
        "enterGeoFence",
      ].sort((a, b) => a?.localeCompare(b))
      : ["origin", "scan", "leaveGeoFence", "enterGeoFence"];

  const facilityArray = facilities
    ? Object.values(facilities).sort((a, b) => {
      return a?.name?.localeCompare(b.name);
    })
    : [];

  // The "limit", "start", and "sort" filters are controlled from the "rows", "page" index, and column sorting headers on the table itself, not the filter drop down
  let initFilters = {
    assetIds: null,
    assetId: null,
    binLocations: null,
    defaultColumnOrder: [],
    endDate: null,
    events: null,
    limit: 25,
    locals: [],
    locations: [],
    pca: false,
    sorted: [
      {
        id: "timeOfLog",
        desc: true,
      },
    ],
    startDate: null,
    type: null,
    zones: null,
    ...(propertiesMap?.batchHistoryTableSettings || {}),
  };

  let stateObj = {};

  await getBatchData({ ...props }, batchIdFromParams).then(async (batchRes) => {
    if (batchRes.error || !batchRes.batch) {
      return (stateObj = {
        error: "No batch data found. This batch may have been deleted.",
      });
    } else {
      const batchData = batchRes.batch;
      await getBatchAssets({ ...props }, batchIdFromParams).then(
        async (assetRes) => {
          if (assetRes.error || !assetRes.assets) {
            return (stateObj = {
              error: "No items found for this batch!",
            });
          } else {
            const assetsArray = assetRes.assets.sort((a, b) => {
              if (a.tag && b.tag) {
                return naturalSort(a.tag, b.tag);
              } else {
                return -1;
              }
            });

            await getProductData({ ...props }, batchData).then(
              async (productRes) => {
                if (productRes.error) {
                  return (stateObj = {
                    error: "No product data found for this batch!",
                  });
                } else {
                  const productArr = productRes;
                  let availableDevices = [];
                  let availableZones = [];
                  let allDevices = [];
                  let deviceAssetsMap = [];

                  await getZonesData({ ...props }).then(async (response) => {
                    const zoneData = response.zones;
                    zoneData.forEach((item) => {
                      availableZones.push({
                        internalZoneType: item.internalZoneType,
                        label: item.name,
                        value: item.zoneId,
                      });
                    });
                  });

                  await getDevices({ ...props }).then(async (response) => {
                    allDevices = response.assets;
                    response.assets.forEach((item) => {
                      if (item.device.status !== "assigned") {
                        availableDevices.push(item);
                      }
                    });
                    deviceAssetsMap = await buildDeviceAssetsMap(
                      props,
                      response.assets
                    );
                  });

                  const assetTypes = [
                    ...new Set(assetsArray.map((a) => a.assetType).sort()),
                  ];

                  return (stateObj = {
                    filters: {
                      ...initFilters,
                      start: 0,
                      tz: userTimeZone,
                    },
                    page: 0,
                    lists: {
                      allDevices,
                      assetTypes: assetTypes,
                      availableDevices,
                      availableZones,
                      deviceAssetsMap,
                      eventTypes: eventTypes,
                      facilityArray: facilityArray,
                      assetsArray: assetsArray,
                      facilityMap: facilities || {},
                      eventTypesMap: organization.eventTypesMap || {},
                      usersMap,
                    },
                    batchData: {
                      ...batchData,
                    },
                    productData: productArr,
                    userPropertiesMap:
                      appUser.propertiesMap?.batchHistoryTableSettings,
                  });
                }
              }
            );
          }
        }
      );
    }
  });
  return stateObj;
};

export const refreshBatchAndDeviceData = (props) => {
  const { setModal, batchIdFromParams } = props;

  getBatchAssets({ ...props }, batchIdFromParams)
    .then((assetRes) => {
      if (assetRes.error || !assetRes.assets) {
        setModal({
          modalShow: true,
          text: "There was a problem refreshing assets",
          isError: true,
        });
      } else {
        const assetsArray = assetRes.assets.sort((a, b) => {
          if (a.tag && b.tag) {
            return naturalSort(a.tag, b.tag);
          } else {
            return -1;
          }
        });
        getDevices({ ...props })
          .then(async (response) => {
            let availableDevices = [];
            let allDevices = response.assets;
            let deviceAssetsMap = await buildDeviceAssetsMap(
              props,
              response.assets
            );
            response.assets.forEach((item) => {
              if (item.device.status !== "assigned") {
                availableDevices.push(item);
              }
            });

            props.setState((prevState) => ({
              ...prevState,
              lists: {
                ...prevState.lists,
                allDevices,
                availableDevices,
                assetsArray,
                deviceAssetsMap,
              },
            }));
          })
          .catch(() => {
            setModal({
              modalShow: true,
              text: "There was a problem refreshing devices",
              isError: true,
            });
          });
      }
    })
    .catch(() => {
      setModal({
        modalShow: true,
        text: "There was a problem refreshing batches",
        isError: true,
      });
    });
};

export const parseUserInfo = (row, firstLast = false) => {
  if (firstLast && row.appUserId && (row.firstName || row.lastName)) {
    return `${row.firstName || ""}${row.firstName && row.lastName ? " " : ""}${row.lastName || ""
      }`;
  }
  if (row.appUserId && (row.firstName || row.lastName)) {
    return `${row.lastName || ""}${row.firstName && row.lastName ? ", " : ""}${row.firstName || ""
      }`;
  } else {
    return ``;
  }
};
