import MaterialUiDataGridPremium from '../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium';
import buildColumns from '../inventoryHistory/Table/columns';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import Grid from "@mui/material/Grid2";

function DailyEventsGrid(props) {
  const {
    apiUrl,
    eventsGridRef,
    handleSave,
    selectedBar,
    selectedDayEvents,
    setState,
    state,
    timeZone,
    token,
    userId,
  } = props;

  const {
    classifications,
    customs,
    facilities,
    zones,
  } = useSelector((state) => state.organization, isEqual);

  const history = useHistory();
  const displayConfigs =
    useSelector((state) => state.organization.displayConfig) || [];
  const specificConfig = displayConfigs.find(
    (element) => element.type === "DailyHistory"
  );
  const columnOrder = specificConfig?.columns || null;

  const { historyTableColumns } = buildColumns({
    apiUrl,
    classifications,
    columnOrder,
    customs,
    displayColumnOptions: state?.filters?.displayColumnOptions || {},
    facilities,
    handleSave,
    history,
    setState,
    timeZone,
    token,
    userId,
    zones,
  });

  return (
    (<Grid
      aria-labelledby={selectedBar.date}
      id='daily-events-table'
      ref={eventsGridRef}
      role='tabpanel'
      tabIndex={0}
      size={12}>
      <MaterialUiDataGridPremium
        autoHeight={false}
        checkboxSelection={false}
        columns={historyTableColumns}
        disableAggregation={false}
        exportFileName={"Inventory-Daily"}
        getRowId={(row) => row.assetHistoryId}
        rows={selectedDayEvents}
      />
    </Grid>)
  );
}

export default DailyEventsGrid