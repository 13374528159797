import { IconButton, Snackbar } from "@mui/material/";
import CloseIcon from '@mui/icons-material/Close';
import { FocusTrap } from '@mui/base/FocusTrap'
import MuiAlert from '@mui/material/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NotificationModal(props) {
  const {
    anchorOrigin = { vertical: "top", horizontal: "center" },
    color = "success",
    confirmationModalShow,
    confirmationText,
    modalClose,
    landingSpot,
  } = props;
  return (
    <FocusTrap
      open={confirmationModalShow}
    >
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={confirmationModalShow}
        onClose={() => {
          modalClose();
          landingSpot?.current?.focus();
        }}
      >
        <div>
          <Alert icon={false} severity={color}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  modalClose();
                  landingSpot?.current?.focus();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {confirmationText}
          </Alert>
        </div>
      </Snackbar>
    </FocusTrap>
  );
}
