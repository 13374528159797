import { Typography } from "@mui/material";

import Grid from "@mui/material/Grid2";

export default function NoFacilityPresent() {
  return (
    (<Grid container>
      <Grid style={{ textAlign: "center" }} size={12}>
        <Typography variant="h6">
          No facility has been selected. Please click "Back to Facilities" and
          select a facility.
        </Typography>
      </Grid>
    </Grid>)
  );
}
