import { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ConfigureZone from "./ConfigureZone/ConfigureZone";
import Facilities from "./Facilities/Facilities";
import FacilitiesMap from "./FacilitiesOverviewMap/FacilitiesMap";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Zones from "./Zones/Zones";

export default function Index(props) {
  const { apiUrl, history, location, token, userAvailableActions = {} } = props;
  const dispatchGlobal = useDispatch();
  const { facilities, organization } = useSelector(
    (state) => state.organization,
    isEqual
  );
  const [view, setView] = useState({
    facility: {},
    viewConfigureZone: false,
    viewFacilitiesMap: false,
    viewZone: false,
    zoneInfo: {},
  });
  const [alertModal, setAlertModal] = useState({
    alertModalContent: "",
    alertModalOpen: false,
    isError: false,
  });
  const { alertModalContent, alertModalOpen, isError } = alertModal;
  const permissions = {
    createLocation: userAvailableActions.includes("Create Locations"),
    deleteLocation: userAvailableActions.includes("Delete Locations"),
    editLocation: userAvailableActions.includes("Edit Locations"),
  };

  const closeModal = () => {
    setAlertModal({
      ...alertModal,
      alertModalOpen: false,
    });
  };

  function handleAlertModal(content, isOpen, isError) {
    setAlertModal({
      alertModalContent: content,
      alertModalOpen: isOpen,
      isError: isError,
    });
    setTimeout(() => {
      setAlertModal((prevState) => ({ ...prevState, alertModalOpen: false }));
    }, 3000);
  }

  useEffect(() => {
    // This is looking for a redirect from the FacilityOverview component.
    // We could just say "If location.state is present, then setView:{...location.state} or something."
    if (location.state && location.state?.viewZones) {
      setView((prevState) => ({
        ...prevState,
        viewZones: location.state.viewZones,
        facility: location.state.facility,
      }));
    }
  }, [history, location.state]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertModalOpen}
        onClose={closeModal}
      >
        <MuiAlert onClose={closeModal} severity={isError ? "error" : "success"}>
          {alertModalContent}
        </MuiAlert>
      </Snackbar>
      {!view.viewZones && !view.viewFacilitiesMap && !view.viewConfigureZone ? (
        <Facilities
          apiUrl={apiUrl}
          dispatchGlobal={dispatchGlobal}
          facilities={facilities}
          history={history}
          location={location}
          organization={organization}
          permissions={permissions}
          setView={setView}
          token={token}
          view={view}
        />
      ) : view.viewConfigureZone ? (
        <ConfigureZone
          apiUrl={apiUrl}
          handleAlertModal={handleAlertModal}
          setView={setView}
          token={token}
          view={view}
        />
      ) : view.viewZones ? (
        <Zones
          apiUrl={apiUrl}
          facilities={facilities}
          permissions={permissions}
          setView={setView}
          token={token}
          view={view}
        />
      ) : (
        <FacilitiesMap apiUrl={apiUrl} token={token} setView={setView} />
      )}
    </>
  );
}
