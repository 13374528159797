import { Box, Button, SvgIcon } from "@mui/material";

import Grid from "@mui/material/Grid2";

export default function ActionButtons(props) {

  const classes = {
    button: {
      background: "none",
      border: "none",
      marginRight: "10px",
    },
  }

  const { content } = props;
  const iconContent = content.map((element, index) => {
    const { action, disabled = false, icon, title } = element;

    return (
      <Button
        aria-label={title}
        disabled={disabled}
        key={index}
        onClick={action}
        sx={classes.button}
        title={title}
      >
          <SvgIcon sx={{ height: "16px", width: "16px" }} >{icon}</SvgIcon>
      </Button>
    );
  });
  return (
    <Grid alignItems="center"  container display="flex">
      <Box>
            {iconContent}
      </Box>
    </Grid>
    
  );
}
