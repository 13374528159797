// import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

// export const theme = createTheme(adaptV4Theme({
export const theme = createTheme(({
  // pallete conists of up to date LX brand colors (09/29/2020)
  palette: {
    primary: {
      main: "#32355C",
    },
    // secondary could be green #45793A or light or light green (currently success) #A0C46D
    // or, it could be the light blue info color
    // keeping secondary as the dark red / orange "error" color, as used currently in console
    secondary: {
      main: "#B71D1A",
      sub: '#45793A'
    },
    third: {
      main: "#414348"
    },

    error: {
      main: "#B71D1A",
    },
    warning: {
      main: "#FFC854",
    },
    action: {
      main: "#5884F5",
    },
    info: {
      main: "#5884F5",
    },
    success: {
      main: "#A0C46D",
    },
    typography: {
      primary: "#41434B",
      secondary: "#6D6E70",
      light: "#A6A8AB",
    },
    // common: {
    //   white: "#FAFAFA",
    // },
  },
  // override MUI style sheets
  components: {
    // Style sheet name ⚛️
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#fafafa",
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1em",
          fontWeight: 700,
          // minWidth:'15rem'
        },
      },
      // Name of the rule
      containedPrimary: {
        "&:hover": {
          backgroundColor: "#5884F5",
        },
      },
      containedSecondary: {
        backgroundColor: "#A0C46D",
        "&:hover": {
          backgroundColor: "#45793A",
        },
      },

      outlinedPrimary: {
        borderColor: "#32355C",
        color: "#414348",
        // color: "red",
        "&:hover": {
          backgroundColor: "#32355c07",
          borderColor: "#32355c",
          color: "#32355C",
        },
        "&.Mui-disabled": {
          // borderColor: "rgba(0, 0, 0, 0.26)",
          // color: "rgba(0, 0, 0, 0.26)",
        },
      },

    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&:focused": {
            color: "#5884F5",
          },
        },
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "2px solid #32355c",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          //for the body
          height: "100%",
        },
      },
      head: {
        //for the head
        height: "auto",
        maxHeight: "10px!important",
        minHeight: "1px!important",
      },

    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Lato",
          fontWeight: "500",
          fontSize: "1rem",
        },
      },
      head: {
        fontWeight: "700",
      },

    },
  },
}));
