import { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { CircularProgress } from "@mui/material";
import { getFixedNodeById, upsertMountPoints } from "../api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MaterialUiButton from "../../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import Select from "../../../../components/Forms/FieldTypes/Select";
import TextField from "../../../../components/Forms/FieldTypes/TextField";

export default function EditNode(props) {
  const classes = {
    accordionSummary: {
      flexDirection: "row-reverse",
      padding: 0,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "3rem",
    },
    heading: {
      marginLeft: ".5rem",
    },
    foo: {
      display: "flex",
    },
    title: {
      fontWeight: "bold",
      paddingBottom: "1rem",
      paddingRight: ".5rem",
    },
    spinningContainer: {
      paddingTop: "2rem",
      textAlign: "center",
    },
    root: {
      width: "100%",
    },
  };

  const {
    apiUrl,
    token,
    dialogClose,
    handleAlertModal,
    onSuccess,
    showTitle,
    state,
    zoneInfo = {},
  } = props;
  const { selectedMountPointId = null, selectedNode = {} } = state;
  const { name: zoneName = "" } = zoneInfo;
  const radios = selectedNode.children;
  const [mountPoints, setMountPoints] = useState([]);
  const [modalState, setModalState] = useState({
    expanded: "",
    hasLoaded: false,
  });

  useEffect(() => {
    getFixedNodeById({ apiUrl, token }, selectedNode.fixedNodeId).then(
      (res) => {
        setMountPoints(res.fixedNode.mountPoints || []);
        setModalState((prevState) => ({
          ...prevState,
          hasLoaded: true,
          // The accordion is now controlled, which means we need to check if
          // we need to have a specific accordion slot open
          expanded: selectedMountPointId ? selectedMountPointId : null,
        }));
      }
    );
  }, [selectedNode.fixedNodeId, apiUrl, token, selectedMountPointId]);

  const handleXYZChange = ({ mountPointId, angle, angleValue }) => {
    setMountPoints((s) => {
      const updatedState = s.map((mountPoint) => {
        if (mountPoint.mountPointId === mountPointId) {
          mountPoint.xyz[angle] = angleValue;
        }
        return mountPoint;
      });

      return updatedState;
    });
  };

  const handleAngleRadiosChange = ({ mountPointId, angle, radioId }) => {
    setMountPoints((s) => {
      const updatedState = s.map((mountPoint) => {
        if (mountPoint.mountPointId === mountPointId) {
          mountPoint.slots.map((slot) => {
            if (parseInt(slot.angle) === parseInt(angle)) {
              if (!radioId) {
                slot.name = null;
                slot.radioId = null;
              } else {
                slot.name = radios[radioId].radioMac;
                slot.radioId = radioId;
              }
            }

            return slot;
          });
        }
        return mountPoint;
      });

      return updatedState;
    });
  };

  return (
    (<Grid container>
      {/* Title */}
      {showTitle ? (
        <>
          <Grid sx={classes.foo} size={6}>
            <Typography sx={classes.title} variant="h6">
              EchoLX Identifier:
            </Typography>
            <Typography variant="h6">{selectedNode.name}</Typography>
          </Grid>
          <Grid sx={classes.foo} size={6}>
            <Typography sx={classes.title} variant="h6">
              Zone:
            </Typography>
            <Typography variant="h6">{zoneName}</Typography>
          </Grid>
        </>
      ) : null}
      {!modalState.hasLoaded ? (
        <Grid sx={classes.spinningContainer} size={12}>
          <CircularProgress />
        </Grid>
      ) : mountPoints?.length > 0 ? (
        mountPoints

          // Sorts the mountpoints alphabetically by their names
          .sort((a = {}, b = {}) => {
            const nameA = a.name ? a.name.toUpperCase() : a.name;
            const nameB = b.name ? b.name.toUpperCase() : b.name;
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            }
            return null;
          })
          .map((mountPoint, idx) => (
            <Accordion
              expanded={modalState.expanded === mountPoint.mountPointId}
              key={`${mountPoint.name}-${idx}`}
              onChange={() => {
                setModalState((prevState) => ({
                  ...prevState,
                  expanded: mountPoint.mountPointId,
                }));
              }}
            >
              <AccordionSummary
                sx={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography sx={classes.heading}>{mountPoint.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {Object.keys(mountPoint.xyz).map((angle, idx) => (
                    <Grid key={`${angle}-${idx}`} size={4}>
                      <TextField
                        label={`${angle} (meters)`}
                        type={"number"}
                        value={mountPoint.xyz[angle]}
                        onChange={(event) => {
                          handleXYZChange({
                            mountPointId: mountPoint.mountPointId,
                            angle: angle,
                            angleValue: parseFloat(event.target.value),
                          });
                        }}
                      />
                    </Grid>
                  ))}

                  {mountPoint.slots?.length > 0
                    ? mountPoint.slots.map((slot, index) => {
                        return (
                          (<Grid key={`${slot.angle}-${index}`} size={4}>
                            <Select
                              label={`${slot.angle}° Angle Radio`}
                              value={slot.radioId}
                              options={[
                                { value: null, label: "None" },
                                ...Object.keys(radios).map((radio) => {
                                  return {
                                    value: radio,
                                    label: radios[radio].radioMac,
                                    disabled: mountPoints.some((mountPoint) =>
                                      mountPoint.slots.some(
                                        (slot) => slot.radioId === radio
                                      )
                                    ),
                                  };
                                }),
                              ]}
                              onChange={(event) => {
                                handleAngleRadiosChange({
                                  mountPointId: mountPoint.mountPointId,
                                  angle: slot.angle,
                                  radioId: event.target.value,
                                });
                              }}
                            />
                          </Grid>)
                        );
                      })
                    : null}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
      ) : (
        <div>Add mount point to configure {selectedNode.node}</div>
      )}
      <Grid container sx={classes.buttonContainer} spacing={2}>
        <Grid size={4}>
          <MaterialUiButton
            color="cancel"
            fullWidth={true}
            label="CANCEL"
            onClick={() => {
              dialogClose();
            }}
          />
        </Grid>
        <Grid size={4}>
          <MaterialUiButton
            color="submit"
            fullWidth={true}
            label="ADD MOUNT POINT"
            disabled={mountPoints.length === 3}
            onClick={() => {
              const newMountPoint = {
                fixedNodeId: `${selectedNode.fixedNodeId}`,
                name: `Mount point ${mountPoints.length + 1}`,
                xyz: {
                  x: 0,
                  y: 0,
                  z: 0,
                },
                slots: [
                  {
                    angle: 30,
                  },
                  {
                    angle: 90,
                  },
                  {
                    angle: 150,
                  },
                ],
              };

              setMountPoints((s) => {
                return [...s, newMountPoint];
              });
            }}
          />
        </Grid>
        <Grid size={4}>
          <MaterialUiButton
            color="submit"
            fullWidth={true}
            // disabled={childrensRef.current === modalState}
            label="Submit"
            onClick={() => {
              upsertMountPoints(props, mountPoints).then((res) => {
                if (!res.error) {
                  if (onSuccess) {
                    onSuccess();
                  }
                  handleAlertModal("Node Successfully Updated", true, false);
                  dialogClose();
                } else {
                  handleAlertModal(
                    `There Was an Issue Updating Your Node: ${JSON.stringify(
                      res.error
                    )}`,
                    true,
                    false
                  );
                }
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>)
  );
}
