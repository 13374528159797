import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Box, Paper, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { init } from "./utils";
import { isEqual } from "lodash";
import { saveFilterSettings } from "./api";
import { searchHistories } from "../../utils/API/AssetHistories/AssetHistories";
import { useSelector } from "react-redux";
import * as Tab from "./Tabs/index";
import HorizontalTabs from "../../components/Tabs/HorizontalTabs";
import Loading from "../../components/Loading/Loading";
import Map from "../../components/Maps/AssetHistoryMap/AssetHistoryMap";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import ModalDialog from "../../components/Modals/ModalDialog/ModalDialog";

const Table = lazy(() => import("./Tables"));

export default function BatchHistory(props) {

  const theme = useTheme();
  const { palette = {} } = theme;
  const { info = {}, text = {} } = palette;

  const classes = {
    root: {
      flexGrow: 1,
    },
    paper: {
      color: text?.secondary,
      height: "100%",
      maxHeight: "550px",
      minHeight: "550px",
      overflow: (props) => (props === 0 ? "inherit" : "auto"),
      padding: theme.spacing(2),
      textAlign: "center",
      width: "100%",
    },
  }

  const { apiUrl, token, userId, organizationId, timeZone } =
    props;
  const { batchId: batchIdFromParams } = props.match.params;

  const [state, setState] = useState();
  const [loading, setLoading] = useState({ loading: false });
  const [infoModal, setInfoModal] = useState({
    modal: { modalShow: false, title: "", content: "" },
  });
  const [modal, setModal] = useState({
    modalShow: false,
    text: "",
    isError: false,
  });
  const [tabNavigation, setTabNavigation] = useState(0);
  const modalClose = () => {
    setModal({
      ...modal,
      modalShow: false,
    });
    setInfoModal({
      ...infoModal,
      modalShow: false,
    });
  };
  const isMounted = useRef(false);

  const userPropertiesMapRef = useRef();

  const { organization, facilities, usersMap, zones } = useSelector(
    (state) => state.organization,
    isEqual
  );
  // first effect, fire init function
  useEffect(() => {
    init({
      apiUrl,
      batchIdFromParams,
      facilities,
      organization,
      organizationId,
      token,
      userId,
      usersMap,
    }).then((res) => {
      if (res.error) {
        setModal({
          modalShow: true,
          text: `Uh-oh! Something went wrong while fetching initial data... ${res.error}`,
          isError: true,
        });
      } else {
        userPropertiesMapRef.current = res.userPropertiesMap;
        setState((prevState) => {
          return {
            ...prevState,
            ...res,
          };
        });
        isMounted.current = true;
      }
    });

    return () => {
      // Clears filters
      setState((prevState) => ({
        ...prevState,
        filters: {
          assets: null,
          binLocations: null,
          endDate: null,
          events: null,
          locals: null,
          locations: null,
          startDate: null,
          type: null,
          users: null,
          zones: null,
        },
      }));
      // save filters to user profile upon dismount
      if (!isEqual(userPropertiesMapRef.current, prevFiltersRef.current)) {
        saveFilterSettings(
          { apiUrl, token, userId },
          prevFiltersRef.current
        ).then((res) => {
          if (res.error) {
            console.log(res.error);
          }
        });
      }
    };
  }, [
    apiUrl,
    batchIdFromParams,
    facilities,
    organization,
    organizationId,
    token,
    userId,
    usersMap,
  ]);

  const prevFiltersRef = useRef(state?.filters);
  const locationRef = useRef(props.history.location.pathname);

  // On filter change...
  useEffect(() => {
    const { batchId } = props.match.params;
    const pathName = props.history.location.pathname;

    if (
      !isEqual(state?.filters, prevFiltersRef.current) ||
      !isEqual(pathName, locationRef.current)
    ) {
      prevFiltersRef.current = state?.filters;
      setLoading({ loading: true });
      searchHistories({ ...props, batchId }, state).then((res) => {
        if (res.error) {
          setModal({
            modalShow: true,
            text: `Uh-oh! Something went wrong while fetching item data... ${res.error}`,
            isError: true,
          });
          setLoading({ loading: false });
        } else {
          setState((prevState) => {
            locationRef.current = pathName;

            return {
              ...prevState,
              histories: res,
              page: Math.floor(
                prevState.filters.start / prevState.filters.limit
              ),
            };
          });

          setLoading({ loading: false });
        }
      });
    }
  }, [props, state]);

  const itemLevelDataElements = state?.productData[0]?.propertiesMap
    ?.itemLevelDataElements
    ? state.productData[0].propertiesMap.itemLevelDataElements
      .map((f) => {
        return {
          id: f.id,
          dataType: f.dataType,
        };
      })
      .reduce((x, y) => {
        return {
          ...x,
          [y.id]: {
            ...y,
          },
        };
      }, {})
    : false;

  const handleSave = async (filters) => {
    saveFilterSettings({ userId, apiUrl, token }, filters).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        const { appUser = {} } = res;
        const { propertiesMap = {} } = appUser;
        const { batchHistoryTableSettings = {} } = propertiesMap;
        const { displayColumnOptions = {}, defaultColumnOrder = [] } =
          batchHistoryTableSettings;

        setState((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            displayColumnOptions,
            defaultColumnOrder,
          },
        }));
      }
    });
  };

  return (
    (<Box sx={classes.root} mt={4}>
      {state ? (
        <Grid container spacing={3}>
          <ModalDialog
            content={
              <Grid container>
                <Grid size={12}>
                  {infoModal.content}
                </Grid>
              </Grid>
            }
            handleClose={modalClose}
            open={infoModal.modalShow}
            title={infoModal.title}
          />

          {/* Map */}
          <Grid
            size={{
              xs: 12,
              sm: 4
            }}>
            <Paper sx={classes.paper}>
              <Map
                eventTypesMap={
                  state.lists.eventTypesMap ? state.lists.eventTypesMap : {}
                }
                facilities={facilities}
                state={state}
                style={{
                  border: "rgba(50, 53, 93, 0.514) solid 2px",
                  borderRadius: "4px",
                }}
                timeZone={timeZone}
              />
            </Paper>
          </Grid>

          {/* Details */}
          <Grid
            size={{
              xs: 12,
              sm: 8
            }}>
            <Paper sx={classes.paper}>
              <HorizontalTabs
                customControl={(
                  event,
                  newValue
                ) => {
                  setTabNavigation(newValue);
                }}
                customValue={tabNavigation}
                //moved the tab content to the children prop instead of a child of the component
                // with multiple tabs, there is a children prop generated which has all the tabs, However, the children prop is not generated when there is only one tab
                children={[
                  // We actually dont need a key for this ince we are not iterating on this component
                  // eslint-disable-next-line react/jsx-key 
                  <Tab.Overview
                    label="Overview"
                    setState={setState}
                    state={state}
                    timeZone={state.filters.tz ? state.filters.tz : ""}
                  />,
                  // {usersConsoleRole === "Lite" ? null : (
                  //   <Tab.Update
                  //     apiUrl={apiUrl}
                  //     assetsArray={state.lists.assetsArray}
                  //     eventTypes={state.lists.eventTypes}
                  //     eventTypesMap={
                  //       organization ? organization.eventTypesMap : []
                  //     }
                  //     facilities={facilities}
                  //     label="Update Event Log"
                  //     setLoading={setLoading}
                  //     setModal={setModal}
                  //     setState={setState}
                  //     state={state}
                  //     token={token}
                  //   />
                  // )}
                ]}
              >
              </HorizontalTabs>
            </Paper>
          </Grid>

          {/* Table */}
          <Grid size={12}>
            {loading.loading ? <Loading color={info?.main} opaque={true} /> : null}
            <Suspense fallback={<Loading color={info?.main} />}>
              <Table
                apiUrl={apiUrl}
                assetsArray={state.lists.assetsArray}
                defaultColumnOrder={state.filters.defaultColumnOrder}
                facilities={facilities}
                handleSave={handleSave}
                itemLevelDataElements={itemLevelDataElements}
                match={props.match}
                organizationId={organizationId}
                page={state.page}
                searchHistories={searchHistories}
                setInfoModal={setInfoModal}
                setLoading={setLoading}
                setModal={setModal}
                setState={setState}
                state={state}
                timeZone={state.filters.tz ? state.filters.tz : ""}
                token={token}
                zones={zones}
              />
            </Suspense>
          </Grid>
        </Grid>
      ) : (
        <Loading color={info?.main} opaque={true} />
      )}
      <MaterialConfirmationModal
        content={modal.text}
        closeModal={() => {
          setModal({ ...modal, modalShow: false });
        }}
        modalOpen={modal.modalShow}
        severity={modal.isError ? "error" : "success"}
        variant="filled"
      />
    </Box>)
  );
}
