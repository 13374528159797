// The term originated as a humorous, incorrect, past participle of "think". That is, a "thunk value" becomes available after its calculation routine is thought through, or executed
// Thunks are async dispatches to the redux store, and typically involve network API calls. Dispatch a thunk to make a network request, and diff/patch redux store with response.
import { createAsyncThunk } from "@reduxjs/toolkit";

export const products = {
  retrieveProducts: createAsyncThunk(
    "organization/products/retrieveProducts",
    async (_, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      const { token = "" } = getState().user;

      const data = await apiService.organization
        .retrieveProducts({ token })
        .then((data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                type: "error",
                text: data.error.toString(),
              },
            });
          } else if (data) {
            return {
              success: true,
              products: data || {},
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching your organization's products.",
              },
            });
          }
        });
      // data.reduxRequestId = thunkAPI.requestId;
      return data;
    }
  ),
};

export const facilities = {
  retrieveFacilities: createAsyncThunk(
    "organization/facilities/retrieveFacilities",
    async (_, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      const { token = "" } = getState().user;

      const data = await apiService.organization
        .retrieveFacilities({ token })
        .then((data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                type: "error",
                text: data.error.toString(),
              },
            });
          } else if (data) {
            return {
              success: true,
              facilities: data || {},
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching your organization's facilities.",
              },
            });
          }
        });
      return data;
    }
  ),
};

export const organization = {
  retrieveOrganization: createAsyncThunk(
    "organization/organization/retrieveOrganization",
    async (_, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      const { token = "", organizationId = "" } = getState().user;

      const data = await apiService.organization
        .retrieveOrganization({ token, organizationId })
        .then((data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                type: "error",
                text: data.error.toString(),
              },
            });
          } else if (data) {
            return {
              success: true,
              organization: data || {},
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching your organization's data.",
              },
            });
          }
        });
      return data;
    }
  ),
};

export const batches = {
  retrieveBatches: createAsyncThunk(
    "organization/batches/retrieveBatches",
    async (_, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      const { token = "" } = getState().user;

      const data = await apiService.organization
        .retrieveBatches({ token })
        .then((data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                type: "error",
                text: data.error.toString(),
              },
            });
          } else if (data) {
            return {
              success: true,
              batches: data || {},
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching your organization's batches.",
              },
            });
          }
        });
      return data;
    }
  ),
};

export const childOrganizations = {
  retrieveChildOrganizations: createAsyncThunk(
    "organization/childOrganizations/retrieveChildOrganizations",
    async (_, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      const { token = "" } = getState().user;

      const data = await apiService.organization
        .retrieveChildOrganizations({ token })
        .then((data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                type: "error",
                text: data.error.toString(),
              },
            });
          } else if (data) {
            return {
              success: true,
              childOrganizations: data || {},
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching your organizations.",
              },
            });
          }
        });
      return data;
    }
  ),
};

export const appUsers = {
  retrieveAppUsers: createAsyncThunk(
    "organization/appUsers/retrieveAppUsers",
    async (_, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      const { token = "" } = getState().user;

      const data = await apiService.organization
        .retrieveAppUsers({ token })
        .then((data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                type: "error",
                text: data.error.toString(),
              },
            });
          } else if (data) {
            return {
              success: true,
              usersMap: data || {},
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching your organization's users.",
              },
            });
          }
        });
      return data;
    }
  ),
};

