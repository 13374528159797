import { Box, Button, Typography } from "@mui/material";
import { showLastEvents } from "../../../../globalStore/apiService/assets";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useEffect, useMemo, useState } from "react";
import GoogleMapComponent from "../../../../components/Maps/GoogleMapComponent";
import NotificationModal from "../../../../components/Modals/NotificationModal";

export default function AssetStatusMap(props) {
  const {
    eventTypesMap = {},
    facilities,
    organizationId,
    setLoading,
    setState,
    state,
    timeZone,
    token,
  } = props;
  const [mapSettings, setMapSettings] = useState({
    center: { lat: 34.561187, lng: -40.814914 },
    zoom: 5
  })

  const [alertModalState, setAlertModalState] = useState({
    alertModalColor: "success",
    modalShow: false,
    modalText: "",
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const classes = {
    dataButton: {
      marginTop: "5px",
    },
  }
  const assets = useMemo(() => state.assets || [], [state.assets]);

  useEffect(() => {
    const defaultLat = 60.363007;
    const defaultLon = -43.63390;

    if (
      assets &&
      !assets[0]?.latitude &&
      !assets[0]?.longitude &&
      assets[0]?.failityId
    ) {
      const currentFacility = facilities[assets[0]?.facilityId] || {};
      const { location = {} } = currentFacility;
      const { latitude: currentLat = defaultLat, longitude: currentLon = defaultLon } = location;
      setMapSettings({
        center: { lat: currentLat, lng: currentLon },
        zoom: 4,
      })
    } else if (
      assets &&
      assets[0]?.latitude &&
      assets[0]?.longitude
    ) {
      setMapSettings({
        center: { lat: assets[0].latitude, lng: assets[0].longitude },
        zoom: 4,
      })
    } else {
      // Default view if no pins are available
      setMapSettings({
        center: { lat: defaultLat, lng: defaultLon },
        zoom: 2,
      })
    }
  }, [assets, facilities, setMapSettings]);

  const markersToRender = assets
    .map((asset) => {
      if (
        asset &&
        (!asset.latitude || !asset.longitude) &&
        asset.facility &&
        asset.facilityId
      ) {
        const currentFacility = facilities[asset.facilityId] || {};
        const { location = {} } = currentFacility;
        const { latitude: currentLat, longitude: currentLon } = location;
        return { ...asset, latitude: currentLat, longitude: currentLon };
      } else if (
        asset &&
        (asset.latitude !== 0 || asset.longitude !== 0) &&
        asset.latitude &&
        asset.longitude
      ) {
        return asset;
      }
      return null;
    })
    .filter((asset) => asset !== null)
    .sort((a, b) => a.timeOfLog - b.timeOfLog)

  const popUpContent = (marker) => {
    const { tag, latitude, longitude, facilityId, lastEvent, timeOfLogLong, propertiesMap } = marker
    return (
      (<Box sx={{
        display: "flex",
        flexDirection: "column",
      }} >
        {tag ? <Typography component="h2" sx={{ fontSize: "22px" }}>{tag}</Typography> : ""}
        {lastEvent ? <Typography component="span" sx={{ fontSize: "22px" }}>{lastEvent}</Typography> : ""}
        {propertiesMap && propertiesMap?.note ? (
          <Typography component={"span"} sx={{ fontSize: "18px" }} >Note: {propertiesMap.note}</Typography>
        ) : (
          ("")
          // null
        )}
        {timeOfLogLong ? (
          <>
            <Typography component={"span"} sx={{ fontSize: "14px" }}>
              Date:{" "}
              {unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)}
            </Typography>
            {/* <br /> */}
          </>
        ) : null}
        {facilityId &&
          facilities[facilityId] &&
          latitude !== 0 &&
          longitude !== 0 ? (
          <Typography component={"span"} sx={{ fontSize: "14px" }}>Facility: {facilities[facilityId].name}</Typography>
        ) : (
          ""
        )}{" "}
        <br />
        <Button
          sx={classes.dataButton}
          color="primary"
          variant="contained"
          onClick={() => {
            showLastEvents({
              facilityId: facilityId,
              organizationId: organizationId,
              token: token,
            }, {
              isExport: false
            }).then((res) => {
              if (res.error) {
                // put up notification modal to show error
                setAlertModalState(() => ({
                  alertModalColor: "error",
                  modalShow: true,
                  modalText: res.error,
                }));
                return
              }
              const realCount = res.count 
              //if realCount is greater than 10000, show a success modal with message
              if (realCount > 10000) {
                setAlertModalState(() => ({
                  alertModalColor: "success",
                  modalShow: true,
                  modalText: `This facility has more than 10,000 assets (${realCount}). The map table will display only the first 10,000 assets at this facility. You can narrow your results by modifying the filters in the Event Analysis tab.`,
                }));

              }
              setState((prevState) => {
                return {
                  ...prevState,
                  mapTableData: res.assets,
                };
              });
              setLoading(false);
            });
          }}
        >
          See Data
        </Button>
      </Box>)
    );
  }

  return (
    <>
    <NotificationModal
        confirmationModalShow={alertModalState.modalShow}
        modalClose={() => {
          setAlertModalState(() => ({
            alertModalColor: "success",
            modalShow: false,
            modalText: "",
          }));
        }}
        confirmationText={alertModalState.modalText}
        color={alertModalState.alertModalColor}
      />
      <GoogleMapComponent
        activeMarker={activeMarker}
        eventTypesMap={eventTypesMap}
        markersToRender={markersToRender}
        markerKey="assetId"
        mapSettings={mapSettings}
        popUpContent={popUpContent}
        setActiveMarker={setActiveMarker}
      />
    </>
  );
}
