import { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid2";
import SimpleTextField from "../Forms/FieldTypes/TextField";
import { Typography } from "@mui/material";

export default function CustomDataField(props) {

  const classes = {
    removeField: {
      textAlign: "right",
    },
    root: {
      marginTop: ".5rem",
    },
  }

  const { title, value, removeField, count, handleChange, readOnly } = props;
  const [deleteCustomField, setDeleteCustomField] = useState(false);
  // const classes = useStyles();
  const deleteTextFlow = deleteCustomField ? (
    <div>
      <span className="mx-2">Are you sure?</span>
      <span
        className="pointer mx-2"
        onClick={() => {
          removeField(count);
        }}
      >
        <CheckIcon />
      </span>
      <span
        className="pointer mx-2"
        onClick={() => {
          setDeleteCustomField(false);
        }}
      >
        <CloseIcon />
      </span>
    </div>
  ) : (
    <span
      className="pointer h6"
      onClick={() => {
        setDeleteCustomField(true);
      }}
    >
      Remove Field
    </span>
  );
  return (
    (<Grid sx={classes.root} size={12}>
      <Typography component="div" sx={classes.removeField}>
        {readOnly ? "" : deleteTextFlow}
      </Typography>
      <SimpleTextField
        id="description"
        label={title}
        onChange={(event) => {
          handleChange(event.target.value, count);
        }}
        readOnly={readOnly}
        required
        value={value}
      />
    </Grid>)
  );
}
