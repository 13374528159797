import { createAsyncThunk } from "@reduxjs/toolkit";

export const settings = {
  getConfigTables: createAsyncThunk(
    "settings/settings/getConfigTables",
    async (_, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;
      const { token = "" } = getState().user;

      const data = await apiService.settings
        .getConfigTables({ token })
        .then(async (data) => {
          if (data.error) {
            return {
              success: false,
              error: data.error,
            };
          }
          else if (data.success) {
            return {
              success: true,
              displayConfig: data.clientOrganizationConfig.gridConfigs,
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching config data.",
              },
            });
          }
        });
      return data;
    }
  ),

  editConfigTables: createAsyncThunk(
    "settings/settings/editConfigTables",
    async (tablesData, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;
      const { token = "" } = getState().user;

      const data = await apiService.settings
        .editConfigTables({ token, tablesData })
        .then((response) => {
          if (response.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: response.error,
              },
            });
          } else if (response.success) {
            return {
              success: true,
              displayConfig: response.clientOrganizationConfig.gridConfigs,
            }
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching asset histories.",
              },
            });
          }
        })
      return data;
    }
  ),

  createNewForm: createAsyncThunk(
    "settings/settings/createNewForm",
    async (formInfo, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;
      const { token = "" } = getState().user;

      const data = await apiService.settings
        .createNewForm({ token, formInfo })
        .then((response) => {
          if (response.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: response.error,
              },
            });
          } else if (response.success) {
            return {
              success: true,
              newFormInfo: response.form,
            }
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching forms.",
              },
            });
          }
        })
      return data;
    }
  ),
}