import { useEffect, useMemo, useState } from 'react';
import {
  useLocation
} from "react-router-dom";
import { validateDuoMFAuthentication } from '../../utils/API/Auth/auth';

export default function TwoFactorAuthPage(props) {
  const { apiUrl, logUserIn = () => { }, token } = props
  const [state, setState] = useState({
    stateString: null,
    token: null
  })
  const { stateString, duoCode } = state
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  // This useEffect grabs the state and duoCode from the URL
  useEffect(() => {
    setState({
      stateString: query.get("state"),
      duoCode: query.get("duo_code")
    })
  }, [query])

  // This useEffect fires off if we have a state and duoCode
  useEffect(() => {
    if (state.stateString && state.duoCode) {
      validateDuoMFAuthentication({ apiUrl, token, duoCode, stateString }).then((res = {}) => {
        const { success = false, token = '', user = {} } = res
        if (success) {
          // The time out exist as a way to give the backend some time to enable the token.
          setTimeout(() => {
            logUserIn({ user, token })
          }, 2000)
        } else {
          console.log('error')
        }
      })
    }
  }, [apiUrl, duoCode, logUserIn, state.duoCode, state.stateString, stateString, token])

  return (
    <></>
  )
}