import CloseIcon from "@mui/icons-material/Close";
import Devices from "./Tabs/Devices";
import HorizontalTabs from "../../../../components/Tabs/HorizontalTabs";
import Information from "./Tabs/Information";
import { Dialog, DialogContent, DialogTitle, IconButton, useTheme } from "@mui/material";

export default function ZoneDetail(props) {

  const theme = useTheme();

  const classes = {
    content: {
      minHeight: "75vh",
      maxHeight: "75vh",
      overflow: "auto",
    },
    root: {
      margin: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }


  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby={props.title}
      open={props.open || false}
      fullWidth
      maxWidth="md"
      sx={classes.root}
    >
      <DialogTitle>
        {" "}
        {props.handleClose ? (
          <IconButton
            aria-label="close"
            sx={classes.closeButton}
            onClick={props.handleClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={classes.content}>
        <HorizontalTabs noAppBarColor noElevation>
          <Information label="Zone Information" {...props} />
          <Devices label="Active Devices" {...props} />
        </HorizontalTabs>
      </DialogContent>
    </Dialog>
  );
}
