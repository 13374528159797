import { useState } from "react";
import _ from "lodash";
import { editFacility } from "../../../../utils/API/Facilities/Facilities";
import { editPreflightCheck } from "../API/preflight";
import { naturalSort } from "../../../../utils/naturalSort";
import { putCentroid } from "../API/API";
import { useTheme } from "@mui/material";
import { validateLat, validateLong } from "../utils/utils";
import Grid from "@mui/material/Grid2";
import MaterialUiButton from "../../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleSelect from "../../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../../components/Forms/FieldTypes/TextField";
import StateSelect from "../../../../components/Selects/StateSelect";

export default function EditFacility(props) {
  const theme = useTheme();

  const classes = {
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  };

  const {
    dispatchGlobal,
    facility,
    locationTypes = [],
    onHide,
    updateFacilities,
  } = props;
  const facilityClone = _.cloneDeep(facility);

  const [editState, setEditState] = useState({
    ...facilityClone,
    error: {
      name: false,
    },
    facilityId: props.match.params.facilityId,
    latValid: true,
    longValid: true,
    validated: false,
  });
  const {
    error,
    facilityType = "",
    location = {},
    name = "",
    propertiesMap = {},
    radius = null,
  } = editState;
  const {
    address1,
    address2,
    city = "",
    postalCode = "",
    state = "",
    latitude,
    longitude,
  } = location;
  const { facilityDetails = "" } = propertiesMap;
  const facilityTypeSelectOptions = [...locationTypes]
    .sort((a, b) => naturalSort(a, b))
    .map((element) => {
      return { label: element, value: element };
    });

  const handleSubmit = () => {
    const { apiUrl, facility, notificationModal, onHide, onSuccess, token } =
      props;

    const { location, facilityType, name, propertiesMap, facilityId, radius } =
      editState;

    let body = {
      location,
      facilityType,
      name,
      propertiesMap,
      radius,
    };

    // Remove facility ID from location object, as it interferes with geocode lookup logic on the backend
    if (body.location.facilityId) {
      delete body.location.facilityId;
    }
    // if the user is sending an empty lat/long, then they must have deleted the lat long and are entering a new address; strip out lat/lon from PUT body, as it interferes with geocode lookup as well
    if (!body.location.latitude || !body.location.longitude) {
      delete body.location.latitude;
      delete body.location.longitude;
      delete body.location.lat1000;
      delete body.location.lon1000;
    }
    // if lat/lon is being sent, construct a centroid string for preflight check, else set to null... preflight will check for centroid string and remove it if null
    const centroidString =
      body.location.latitude && body.location.longitude
        ? `POINT(${body.location.longitude} ${body.location.latitude})`
        : null;

    editPreflightCheck(
      { apiUrl, token },
      {
        // if name hasn't been changed, don't run a check on it
        name: body.name !== facility.name ? body.name : null,
        centroidString: centroidString || null,
        radius: 0.01,
        location: body.location.address1
          ? {
            address1: body.location.address1,
            city: body.location.city,
            state: body.location.state,
          }
          : null,
        facilityId: facilityId,
      }
    ).then((res) => {
      if (res.error) {
        notificationModal(`${res.error}`, true);
      } else {
        editFacility({ ...props, facilityId }, body).then((response) => {
          if (response.success) {
            // update centroid / radius automatically if prev lat/lon differs from updated lat/lon
            const resLat = response.facility.location.latitude;
            const resLon = response.facility.location.longitude;

            if (
              parseFloat(resLat).toFixed(5) !==
              parseFloat(facility.location.latitude).toFixed(5) ||
              parseFloat(resLon).toFixed(5) !==
              parseFloat(facility.location.longitude).toFixed(5)
            ) {
              putCentroid(
                { ...props, facilityId: facilityId },
                {
                  latitude: {
                    value: resLat,
                  },
                  longitude: {
                    value: resLon,
                  },
                  // putCentroid function divides incoming radius by 1000, as it is expecting meters but writes to API in kilometers.
                  radius: radius ? radius / 1000 : 400,
                }
              ).then((res) => {
                if (!res?.success) {
                  notificationModal(
                    `Facility data updated, but there was an error when updating centroid / radius for ${body.name}, message: ${res.error}`,
                    true
                  );
                } else {
                  setTimeout(() => {
                    onHide();
                    dispatchGlobal(
                      updateFacilities({
                        // We are splaying out the response and adding zones to this redux update.
                        // The backend states that they do not carry over transient data with this response.
                        // Thus we need to feed it in manually. I do not believe this will cause issues.
                        // But will confirm with the backend.
                        body: {
                          ...response.facility,
                          zones: facilityClone.zones,
                        },
                        facilityId: res.facility?.facilityId,
                      })
                    );
                    notificationModal("Facility Successfully Updated", false);
                  }, 150);
                  onSuccess();
                }
              });
            } else {
              setTimeout(() => {
                onHide();
                dispatchGlobal(
                  updateFacilities({
                    body: { ...response.facility, zones: facilityClone.zones },
                    facilityId: response.facility?.facilityId,
                  })
                );
                notificationModal("Facility Successfully Updated", false);
              }, 150);
              onSuccess();
            }
          } else {
            notificationModal(
              `There was an error when updating your facility, message: ${response.error}`,
              true
            );
          }
        });
      }
    });
  };

  function validateFields() {
    let isValidated = true;
    let fieldsToValidate = [
      { id: "address1", value: address1 },
      { id: "city", value: city },
      { id: "name", value: name },
      { id: "postalCode", value: postalCode },
      { id: "state", value: state },
    ];

    //if we have lat and long, we dont need to validate city/state/zip/address1/postalCode
    if (latitude && longitude) {
      fieldsToValidate = [{ id: "name", value: name }];
    }
    //if we have just lat or just long, set error on both
    if ((latitude && !longitude) || (!latitude && longitude)) {
      fieldsToValidate = [
        { id: "latitude", value: latitude },
        { id: "longitude", value: longitude },
      ];
    }

    // We validate the fields
    fieldsToValidate.forEach((element) => {
      const { id, value = "" } = element;
      if (value?.length === 0 || value === null) {
        isValidated = false;

        setEditState((prevState) => ({
          ...prevState,
          error: { ...prevState.error, [id]: true },
        }));
      }
    });

    // If nothing has been tripped, we move forward to the onSubmit
    if (isValidated) {
      handleSubmit();
    }
  }

  return (
    (<Grid sx={classes.root} container spacing={2}>
      {/* Facility Name */}
      <Grid size={6}>
        <SimpleTextField
          error={error?.name}
          id="Facility Name"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-txt-name",
          }}
          label="Facility Name"
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, name: false },
              name: event.target.value,
            }));
          }}
          required
          value={name}
        />
      </Grid>
      {/* Facility Type */}
      <Grid size={6}>
        <SimpleSelect
          error={error?.facilityType}
          id="Facility Type"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-select-type",
          }}
          label="Facility Type"
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, facilityType: false },
              facilityType: event.target.value,
            }));
          }}
          options={facilityTypeSelectOptions}
          value={facilityType}
          variant="outlined"
        />
      </Grid>
      {/* Address */}
      <Grid size={12}>
        <SimpleTextField
          // error={error?.address1}
          // error={error?.address1}
          error={
            location.latitude || location.longitude ? false : error?.address1
          }
          errorText="Required Field if No Lat/Long Set"
          id="Address"
          label="Address"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-txt-address",
          }}
          onChange={(event) => {
            let newAddress = editState.location || {};
            newAddress.address1 = event.target.value;
            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, address1: false },
              location: newAddress,
            }));
          }}
          required={location.latitude || location.longitude ? false : true}
          value={address1}
        />
      </Grid>
      {/* Address 2 */}
      <Grid size={12}>
        <SimpleTextField
          id="Address 2"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-txt-address-2",
          }}
          label="Address 2 - optional"
          onChange={(event) => {
            let newAddress = editState.location || {};
            newAddress.address2 = event.target.value;
            setEditState((prevState) => ({
              ...prevState,
              location: newAddress,
            }));
          }}
          value={address2}
        />
      </Grid>
      {/* City */}
      <Grid size={6}>
        <SimpleTextField
          error={location.latitude || location.longitude ? false : error?.city}
          errorText="Required Field if No Lat/Long Set"
          id="City"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-txt-city",
          }}
          label="City"
          onChange={(event) => {
            let newAddress = editState.location || {};
            newAddress.city = event.target.value;
            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, city: false },
              location: newAddress,
            }));
          }}
          required={location.latitude || location.longitude ? false : true}
          value={city}
        />
      </Grid>
      {/* State */}
      <Grid size={6}>
        <StateSelect
          error={location.latitude || location.longitude ? false : error?.state}
          errorText="Required Field if No Lat/Long Set"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-select-state",
          }}
          onChange={(state) => {
            let newAddress = editState.location || {};
            newAddress.state = state;
            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, state: false },
              location: newAddress,
            }));
          }}
          required={location.latitude || location.longitude ? false : true}
          requiredFieldMessage="Required Field if no Lat/Long set"
          state={state}
        />
      </Grid>
      {/* Zip Code */}
      <Grid size={6}>
        <SimpleTextField
          error={
            location.latitude || location.longitude ? false : error?.postalCode
          }
          errorText="Required Field if No Lat/Long Set"
          id="Zip Code"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-txt-zip-code",
          }}
          label="Zip Code"
          onChange={(event) => {
            let newAddress = editState.location || {};
            newAddress.postalCode = event.target.value;
            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, postalCode: false },
              location: newAddress,
            }));
          }}
          required={location.latitude || location.longitude ? false : true}
          value={postalCode}
        />
      </Grid>
      {/* Latitude */}
      <Grid size={6}>
        <SimpleTextField
          error={error?.latitude}
          errorText="Both latitude and longitude are required if no address fields are filled out"
          id="Latitude"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-txt-latitude",
          }}
          label="Latitude"
          required={
            location.latitude?.length > 0 || location.longitude?.length > 0
              ? true
              : false
          }
          onChange={(event) => {
            const hasLength = event.target.value?.length > 0;

            const latValid = hasLength
              ? validateLat.test(parseFloat(event.target.value).toFixed(5))
              : true;

            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, latitude: !latValid },
              location: {
                ...prevState.location,
                latitude: hasLength ? event.target.value : null,
                lat1000: hasLength ? parseInt(event.target.value) * 1000 : null,
              },
              latValid: latValid,
            }));
          }}
          value={latitude}
        />
      </Grid>
      {/* Longitude */}
      <Grid size={6}>
        <SimpleTextField
          error={error?.longitude}
          errorText="Both latitude and longitude are required if no address fields are filled out"
          id="Longitude"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-txt-longitude",
          }}
          label="Longitude"
          required={
            location.latitude?.length > 0 || location.longitude?.length > 0
              ? true
              : false
          }
          onChange={(event) => {
            const hasLength = event.target.value?.length > 0;
            const longValid = hasLength
              ? validateLong.test(parseFloat(event.target.value).toFixed(5))
              : true;

            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, longitude: !longValid },
              location: {
                ...prevState.location,
                longitude: hasLength ? event.target.value : null,
                lon1000: hasLength ? parseInt(event.target.value) * 1000 : null,
              },
              longValid: longValid,
            }));
          }}
          value={longitude}
        />
      </Grid>
      {/* Radius */}
      <Grid size={6}>
        <SimpleTextField
          error={error?.radius}
          errorText="Please enter a number greater than 0"
          id="radius"
          inputProps={{
            "data-cypress-id": "facilities-create-facility-txt-radius",
          }}
          label="Radius (meters)"
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, radius: false },
              radius: event.target.value,
            }));
          }}
          name="radius"
          type="number"
          value={radius}
        />
      </Grid>
      {/* Facility Details */}
      <Grid size={12}>
        <SimpleTextField
          error={error?.facilityDetails}
          id="Facility Details"
          inputProps={{
            "data-cypress-id": "facilities-edit-facility-txt-facility-details",
          }}
          label="Facility Details - optional"
          multiline
          name="facilityDetails"
          onChange={(event) => {
            let newPropertiesMap = editState.propertiesMap || {};
            newPropertiesMap.facilityDetails = event.target.value;
            setEditState((prevState) => ({
              ...prevState,
              propertiesMap: newPropertiesMap,
            }));
          }}
          rows={4}
          value={facilityDetails}
        />
      </Grid>
      {/* Cancel and Submit Buttons */}
      <Grid sx={classes.buttonContainer} size={12}>
        {/* Cancel */}
        <Grid size={4}>
          <MaterialUiButton
            color="cancel"
            cypressId="facilities-edit-facility-btn-cancel"
            fullWidth={true}
            label="Cancel"
            onClick={() => onHide()}
            variant="outlined"
          />
        </Grid>

        {/* Submit */}
        <Grid size={4}>
          <MaterialUiButton
            color="submit"
            fullWidth={true}
            label="Submit"
            cypressId="facilities-edit-facility-btn-submit"
            onClick={() => validateFields()}
          />
        </Grid>
      </Grid>
    </Grid>)
  );
}
