import { formatLatLon } from "../../../../../utils/lat_lon_utils";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";

function Information(props) {
  const { facilityType, location, propertiesMap } = props.facility;

  // TODO -  I ran into this while ripping out typescript. Looks like
  // I can delete this useEffect, but not sure if it was here to ghetto force something? 
  useEffect(() => {}, [props.facility]);

  return (
    (<Grid container spacing={3}>
      <Grid size={12}>
        {" "}
        <strong style={{ fontSize: "16px" }}>Name</strong>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4
        }}>
        <strong>Facility Type</strong> <br />
        {facilityType || "None provided"}
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4
        }}>
        <strong>Address</strong> <br />
        {location?.address1 || "None provided"}
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4
        }}>
        <strong>City</strong> <br />
        {location?.city || "None provided"}
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4
        }}>
        <strong>State</strong> <br />
        {location?.state || "None provided"}
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4
        }}>
        <strong>Zip Code</strong> <br />
        {location?.postalCode || "None provided"}
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4
        }}>
        <strong>Details</strong> <br />
        {propertiesMap?.locationDetails || "None provided"}
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 4
        }}>
        <strong>Latitude & Longitude</strong> <br />
        {location?.latitude && location?.longitude
          ? formatLatLon(location.latitude, location.longitude)
          : "None provided"}
      </Grid>
    </Grid>)
  );
}

export default Information;
