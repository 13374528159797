import Grid from "@mui/material/Grid2";
import InventoryMapFilter from "../../../inventory/inventoryStatus/InventoryMapFilter";

export default function MapDisplayWidgetSettings(props) {
  const {
    apiUrl = "",
    organizationId = "",
    setMapSettings = () => {},
    setState,
    state,
    token = "",
  } = props;
  return (
    (<Grid container>
      <Grid size={12}>
        <InventoryMapFilter
          {...props}
          apiUrl={apiUrl}
          assetMode={state.filters.assetType || "Inventory"}
          exportWithCard={false}
          isAssetAnalysis={true}
          isInventory={state.filters.assetType === "Inventory"}
          onSubmitEnd={() =>
            setMapSettings((prevState) => ({
              ...prevState,
              viewSettings: false,
            }))
          }
          organizationId={organizationId}
          secondButton={{
            label: "Go Back",
            onClick: () =>
              setMapSettings((prevState) => ({
                ...prevState,
                viewSettings: false,
              })),
          }}
          setState={setState}
          showAssetTypeRadioGroup
          state={state}
          token={token}
        />
      </Grid>
    </Grid>)
  );
}
