import { Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

export default function PCAWarningIcon(props) {

  const classes = {
    container: {
      color:'#343a40',
      cursor: "default",
    },
    pcaWarning: {
      color: "red",
      "&:hover": {
        color: "red !important",
      },
    },
  }

  const { onClick, data } = props;
  return (
    <Typography
      component="div"
      onClick={() => onClick(data.row.original)}
      className={classes.container}
    >
      <ErrorIcon
        sx={classes.pcaWarning}
      />
    </Typography>
  );
}
