import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Grid from "@mui/material/Grid2";
import ImageModal from "../Modals/ImageModal";
import MaterialUiButton from "../Buttons/MaterialUiButton/MaterialUiButton";
import SimpleTextField from "../Forms/FieldTypes/TextField";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
// This component should spit out an array of blobed up images for you.

export default function ReactDropZoneForImages(props) {
  const classes = {
    root: {
      display: "flex",
    },
    buttonContainer: {
      alignItems: "center",
      display: "flex",
      padding: "0 1rem",
    },
    files: {
      margin: "1rem 0",
    },
    list: {
      padding: "0",
    },
  }

  const {
    checkImages,
    disableNameChange,
    handleChange,
    images = [],
    limitOne = false,
    multiple = true,
    onImageDelete,
    position,
    requireImageName = true,
    tooltipText,
  } = props;
  const [state, setState] = useState({ readOnly: false, disabled: false });
  const { disabled = false, readOnly = false } = state;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const onDrop = (acceptedFiles) => {
    if (limitOne) {
      if (images.length > 0) {
        console.log("Sorry too many images");
      } else {
        handleAcceptedFiles(acceptedFiles);
      }
    } else {
      handleAcceptedFiles(acceptedFiles);
    }
  };

  useEffect(() => {
    setState({
      disabled: limitOne && images.length > 0,
      readOnly: props.readOnly,
    });
  }, [images.length, limitOne, props.readOnly]);

  function handleAcceptedFiles(acceptedFiles) {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      const preview = URL.createObjectURL(file);
      const { name, type } = file;
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const image = reader.result;
        handleChange([
          ...images,
          {
            image,
            imageType: type,
            name: name,
            path: name,
            position,
            preview,
          },
        ]);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/gif",
    disabled: disabled,
    multiple: multiple,
  });

  const files = images.map((file = {}, index) => {
    const { imageId, name, preview, url } = file;
    return (
      (<Typography component="div" sx={classes.root} key={`${index}`}>
        <Grid size={6}>
          <SimpleTextField
            error={checkImages && name.length === 0}
            id="urlBase"
            label="Image Name"
            onChange={(event) => {
              const imageArray = [...images];
              imageArray[index].name = event.target.value;
              handleChange(imageArray);
            }}
            readOnly={disableNameChange ? disableNameChange : readOnly }
            required={requireImageName}
            value={name}
          />
        </Grid>
        <Grid sx={classes.buttonContainer} size={3}>
          <MaterialUiButton
            color="primary"
            fullWidth={true}
            label="View"
            onClick={() => {
              setSelectedImage({ preview: imageId ? url : preview, name });
              setModalOpen(true);
            }}
          />
        </Grid>
        {readOnly ? null : (
          <Grid sx={classes.buttonContainer} size={3}>
            {readOnly ? null : (
              <MaterialUiButton
                color="delete"
                fullWidth={true}
                label="DELETE"
                onClick={() => {
                  const imagesArray = [...images];

                  if (imagesArray.length === 0) {
                    setState({ disabled: false });
                  } else if (onImageDelete) {
                    onImageDelete(imagesArray[index]);
                    imagesArray.splice(index, 1);
                  } else {
                    imagesArray.splice(index, 1);
                    handleChange(imagesArray);
                  }
                }}
              />
            )}
          </Grid>
        )}
      </Typography>)
    );
  });

  return (<>
    <ImageModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      image={selectedImage}
    />
    <Grid size={12}>
      <section>
        {readOnly ? null : (
          <Tooltip
            placement="right"
            title={tooltipText}
            disableHoverListener={!tooltipText}
          >
            <div
              {...getRootProps({
                className: `dropzone ${disabled ? "" : "pointer"}`,
              })}
            >
              <input {...getInputProps()} />
              <p>
                Drag 'n' drop or click to select images files (only
                .jpeg/.jpg/.png/.gif will be accepted)
              </p>
            </div>
          </Tooltip>
        )}
        {images.length > 0 ? (
          <aside className={classes.files}>
            <h4>Images</h4>
            <ul className={classes.list}>{files}</ul>
          </aside>
        ) : null}
      </section>
    </Grid>
  </>);
}
