import { Fragment, lazy, Suspense, useState } from "react";
import Grid from "@mui/material/Grid2";
import { naturalSort } from "../../../utils/naturalSort";
import { postBatch } from "./api";
import ConfirmPCA from "./ConfirmPCA/ConfirmPCA";
import Loading from "../../../components/Loading/Loading";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import moment from "moment";
import NotificationModal from "../../../components/Modals/NotificationModal";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";
import SimpleSwitch from "../../../components/Forms/FieldTypes/Switch"
import { useTheme } from '@mui/material/styles';

const ImportILDE = lazy(() => import("./ImportILDE/ImportILDE"));

export default function CreateBatch(props) {
  const theme = useTheme();
  const { palette = {} } = theme;
  const { typography = {} } = palette;
  const classes = {
    applyPcaToBatch: {
      marginLeft: "0 !important"
    },
    addNewUnitOfMeasure: {
      textAlign: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: ".3rem",
    },
    label: {
      color: typography?.secondary,
      fontFamily: "Lato Semibold",
      fontWeight: "bold",
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  };
  const {
    isLoading,
    itemLevelDataElements = [],
    onHide,
    pcaSettings = {},
    setState,
    timeZone,
  } = props;
  // get ILDE and PCA settings from org, if allBatches or default are set, then the batch is a PCA batch, otherwise show toggle for setting PCA
  // if ILDE exist, require import
  const isPCA = pcaSettings.default || pcaSettings.allBatches ? true : false;
  const isILDE = itemLevelDataElements.length ? true : false;
  const [confirmPCA, setConfirmPCA] = useState(false);
  const [importDialog, setImportDialog] = useState({
    dialogShow: false,
    uploadSuccess: false,
    uploadedElements: "",
  });
  const [confirm, setConfirm] = useState({
    modalShow: false,
    text: "",
    error: false,
  });
  const [modalState, setModalState] = useState({
    error: {
      tag: false,
      originLocation: false,
    },
    newBatch: {
      product: {
        id: props.selectedProduct.productId,
        name: props.selectedProduct.name,
      },
      timeCreated: moment().tz(timeZone).format("MM/DD/YYYY"),
      topologiesList: [
        {
          assetType: "",
          count: 1,
          countError: false,
          error: false,
          productId: props.selectedProduct.productId,
          pcaKeyType: "distribution",
        },
      ],
      customDataFields: [],
      isPCA: isPCA,
      isILDE: isILDE,
    },
  });
  const { newBatch } = modalState;

  function onSubmit() {
    if (isILDE) {
      setImportDialog({
        ...importDialog,
        dialogShow: true,
      });
    } else {
      // Active the loading spinner
      setState((prevState) => ({ ...prevState, isLoading: true }));

      postBatch(props, newBatch).then((res) => {
        if (res?.error) {
          // Disable Loading Spinner
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));

          // Triggering error reporting
          props.notificationModal(`${res?.error}`, true, 5000);
        } else {
          // Disable Loading Spinner
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));

          // Trigger success reporting
          props.notificationModal(`Batch successfully created.`, false, 2000);
        }
      });
    }
  }

  function validateFields() {
    let isValidated = true;
    const { originLocation, tag, topologiesList } = newBatch;
    const fieldsToValidate = [
      { id: "tag", value: tag },
      { id: "originLocation", value: originLocation },
    ];

    // Validate the required fields
    fieldsToValidate.forEach((element) => {
      const { id, value = "" } = element;
      if (value?.length === 0) {
        isValidated = false;

        setModalState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            [id]: true,
          },
        }));
      }
    });

    // Validate the Unit of Measure and Counts when applicable
    if (!isILDE && newBatch.topologiesList && newBatch.topologiesList.length) {
      topologiesList.forEach((element, index) => {
        const { assetType = "", count } = element;
        const elementCount = parseInt(count, 10);
        let newArr = topologiesList || [];

        // Verify a assetType is present
        if (assetType.length === 0) {
          newArr[index] = {
            ...newArr[index],
            error: true,
          };

          isValidated = false;
        }

        // Verify the number is within our limits
        if (elementCount === 0 || elementCount >= 1000000) {
          newArr[index] = {
            ...newArr[index],
            countError: true,
          };

          isValidated = false;
        }

        setModalState((prevState) => ({
          ...prevState,
          newBatch: {
            ...prevState.newBatch,
            topologiesList: newArr,
          },
        }));
      });
    }

    // If everything is good, we allow the user to move to the onSubmit
    if (isValidated) {
      onSubmit();
    }
  }

  const initialOptions = Object.keys(props.facilities)
    .map((key) => {
      return {
        label: props.facilities[key].name,
        value: key,
      };
    })
    .sort((a, b) => naturalSort(a.label, b.label))
  const noneOption = { value: "", label: "- None -" }
  const finalDestinationOptions = [noneOption, ...initialOptions]

  return (<>
    <Grid container sx={classes.root} spacing={2}>
      {/* Batch Id */}
      <Grid size={6}>
        <SimpleTextField
          disabled={isLoading}
          error={modalState.error?.tag}
          id="tag"
          inputProps={{ "data-cypress-id": "products-batch-mdl-txt-id" }}
          label="Batch ID"
          onChange={(e) => {
            setModalState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, tag: false },
              newBatch: {
                ...prevState.newBatch,
                tag: e.target.value,
              },
            }));
          }}
          required
          value={newBatch.tag || ""}
        />
      </Grid>

      {/* Batch Name */}
      <Grid size={6}>
        <SimpleTextField
          disabled={isLoading}
          id="name"
          inputProps={{ "data-cypress-id": "products-batch-mdl-txt-batch-name" }}
          label="Batch Name - optional"
          onChange={(e) => {
            setModalState((prevState) => ({
              ...prevState,
              newBatch: {
                ...prevState.newBatch,
                name: e.target.value,
              },
            }));
          }}
          value={newBatch.name || ""}
        />
      </Grid>

      {/* Product Name */}
      <Grid size={6}>
        <SimpleTextField
          disabled={isLoading}
          id="product"
          inputProps={{ "data-cypress-id": "products-batch-mdl-txt-product-name" }}
          label="Product Name"
          readOnly
          required
          value={newBatch.product.name}
        />
      </Grid>

      {/* Date Created */}
      <Grid size={6}>
        <SimpleTextField
          disabled={isLoading}
          id="timeCreated"
          inputProps={{ "data-cypress-id": "products-batch-mdl-dat-date-created" }}
          label="Date Created"
          readOnly
          required
          value={newBatch.timeCreated}
        />
      </Grid>

      {/* Batch Origin */}
      <Grid size={6}>
        <SimpleSelect
          disabled={isLoading}
          error={modalState.error?.originLocation}
          id="originLocation"
          inputProps={{ "data-cypress-id": "products-batch-mdl-select-batch-origin" }}
          label="Batch Origin"
          onChange={(e) => {
            setModalState((prevState) => ({
              ...prevState,
              error: {
                ...prevState.error,
                originLocation: false,
              },
              newBatch: {
                ...prevState.newBatch,
                originLocation: e.target.value,
              },
            }));
          }}
          options={Object.keys(props.facilities)
            .map((key) => {
              return {
                label: props.facilities[key].name,
                value: key,
              };
            })
            .sort((a, b) => naturalSort(a.label, b.label))}
          required
          value={newBatch.originLocation || ""}
          variant="outlined"
        />
      </Grid>
      {/* Final Destination */}
      <Grid size={6}>
        <SimpleSelect
          disabled={isLoading}
          id="destinationLocation"
          inputProps={{ "data-cypress-id": "products-batch-mdl-select-final-destination" }}
          label="Final Destination - optional"
          onChange={(e) => {
            setModalState((prevState) => ({
              ...prevState,
              newBatch: {
                ...prevState.newBatch,
                destinationLocation: e.target.value,
              },
            }));
          }}
          options={finalDestinationOptions}
          value={newBatch.destinationLocation || ""}
          variant="outlined"
        />
      </Grid>

      {/* Batch Description */}
      <Grid size={12}>
        <SimpleTextField
          disabled={isLoading}
          id="description"
          inputProps={{ "data-cypress-id": "products-batch-mdl-txt-batch-description" }}
          label="Batch Description - optional"
          multiline
          onChange={(e) => {
            setModalState((prevState) => ({
              ...prevState,
              newBatch: {
                ...prevState.newBatch,
                description: e.target.value,
              },
            }));
          }}
          rows={4}
          value={newBatch.description || ""}
        />
      </Grid>

      {/* Units of measure */}
      {!isILDE && newBatch.topologiesList && newBatch.topologiesList.length
        ? newBatch.topologiesList.map((top, idx) => (
          <Fragment key={idx}>
            <Grid size={6}>
              <SimpleSelect
                disabled={isLoading}
                error={newBatch.topologiesList[idx].error}
                errorText="Required"
                id={`${idx} - assetType`}
                inputProps={{ "data-cypress-id": "products-batch-mdl-select-uom" }}
                label="Unit of Measure"
                required
                onChange={(e) => {
                  // const assetType = e.target.value;
                  let newArr = newBatch.topologiesList || [];
                  newArr[idx] = {
                    ...newArr[idx],
                    assetType: e.target.value,
                    error: false,
                  };
                  setModalState((prevState) => ({
                    ...prevState,
                    newBatch: {
                      ...prevState.newBatch,
                      topologiesList: newArr,
                    },
                  }));
                }}
                options={props.assetTypes
                  .map((type) => {
                    return {
                      label: type,
                      value: type,
                    };
                  })
                  .sort((a, b) => naturalSort(a.label, b.label))}
                value={newBatch.topologiesList[idx].assetType}
                variant="outlined"
              />
            </Grid>
            <Grid size={6}>
              <SimpleTextField
                disabled={isLoading}
                error={newBatch.topologiesList[idx].countError}
                errorText="Value must be between 1 and 1000000"
                id={`${idx} - count`}
                InputProps={{ inputProps: { min: 1, max: 100000, "data-cypress-id": "products-batch-mdl-txt-count" } }}
                label="Count"
                onChange={(e) => {
                  let newArr = newBatch.topologiesList || [];
                  newArr[idx] = {
                    ...newArr[idx],
                    count: e.target.value,
                    countError: false,
                  };
                  setModalState((prevState) => ({
                    ...prevState,
                    newBatch: {
                      ...prevState.newBatch,
                      topologiesList: newArr,
                    },
                  }));
                }}
                required
                type="number"
                value={newBatch.topologiesList[idx].count}
              />
            </Grid>
          </Fragment>
        ))
        : null}
      {/* Add / remove units of measure */}
      {!isILDE ? (
        <Grid sx={classes.buttonContainer} size={12}>
          <Grid sx={classes.addNewUnitOfMeasure} size={5}>
            <MaterialUiButton
              color="secondary"
              cypressId="products-batch-mdl-btn-add-uom"
              disabled={isLoading}
              label="Add new unit of measure"
              onClick={() => {
                let newArr = newBatch.topologiesList;
                newArr.push({
                  assetType: "",
                  count: 1,
                  countError: false,
                  error: false,
                  productId: props.selectedProduct.productId,
                  pcaKeyType: "distribution",
                });
                setModalState((prevState) => ({
                  ...prevState,
                  newBatch: {
                    ...prevState.newBatch,
                    topologiesList: newArr,
                  },
                }));
              }}
              variant="contained"
            />
          </Grid>
          <Grid size={5}>
            <MaterialUiButton
              color="delete"
              cypressId="products-batch-mdl-btn-remove-uom"
              disabled={newBatch.topologiesList.length <= 1 || isLoading}
              label="Remove unit of measure"
              onClick={() => {
                let newArr = newBatch.topologiesList;
                newArr.pop();
                setModalState((prevState) => ({
                  ...prevState,
                  newBatch: {
                    ...prevState.newBatch,
                    topologiesList: newArr,
                  },
                }));
              }}
              variant="contained"
            />
          </Grid>
        </Grid>
      ) : null}

      {!pcaSettings.allBatches ? (
        <Grid size={8}>
          <SimpleSwitch
            checked={newBatch.isPCA}
            color="primary"
            disabled={isLoading}
            formControlLabelStyles={classes.applyPcaToBatch}
            inputProps={{ "aria-label": "PCA setting", "data-cypress-id": "products-batch-mdl-cbx-apply-pca" }}
            label="Apply PCA to the Batch"
            labelPlacement="start"
            name="pca"
            onChange={(e) => {
              setModalState((prevState) => ({
                ...prevState,
                newBatch: {
                  ...prevState.newBatch,
                  isPCA: e.target.checked,
                },
              }));
              if (e.target.checked) {
                setConfirmPCA(true);
              }
            }}
          />
        </Grid>
      ) : null}

      {isILDE ? (
        <Grid sx={classes.buttonContainer} container>
          <Grid size={12}>
            This batch includes products that require item-level data
            elements. Please upload these data elements.
          </Grid>

          {/* Cancel */}
          <Grid size={4}>
            <MaterialUiButton
              color="cancel"
              cypressId="products-batch-mdl-btn-cancel"
              fullWidth
              label="CANCEL"
              onClick={() => onHide()}
              type="submit"
              variant="contained"
            />
          </Grid>

          {/* Submit */}
          <Grid size={4}>
            <MaterialUiButton
              color="primary"
              cypressId="products-batch-mdl-btn-next"
              fullWidth
              label="Next"
              onClick={() => validateFields()}
              type="submit"
              variant="contained"
            />
          </Grid>
        </Grid>
      ) : (
        <Grid sx={classes.buttonContainer} container>
          {/* Cancel */}
          <Grid size={4}>
            <MaterialUiButton
              color="cancel"
              cypressId="products-batch-mdl-btn-cancel"
              fullWidth
              label="CANCEL"
              onClick={() => onHide()}
              type="submit"
              variant="outlined"
            />
          </Grid>

          {/* Submit */}
          <Grid size={4}>
            <MaterialUiButton
              color="submit"
              cypressId="products-batch-mdl-btn-submit"
              fullWidth
              label="Submit"
              onClick={() => validateFields()}
              type="submit"
              variant="contained"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
    <ConfirmPCA
      confirmPCA={confirmPCA}
      setConfirmPCA={setConfirmPCA}
      cancel={() => {
        setModalState((prevState) => ({
          ...prevState,
          newBatch: {
            ...prevState.newBatch,
            isPCA: false,
          },
        }));
      }}
    />
    <ModalDialog
      handleClose={() => {
        setImportDialog({
          ...importDialog,
          dialogShow: false,
        });
      }}
      open={importDialog.dialogShow}
      title="Upload Item Level Data Elements"
      content={
        <Suspense fallback={<Loading />}>
          <ImportILDE
            apiUrl={props.apiUrl}
            assetTypes={props.assetTypes}
            facilities={props.facilities}
            itemLevelDataElements={itemLevelDataElements}
            newBatch={newBatch}
            onSuccess={() => {
              setImportDialog({
                ...importDialog,
                dialogShow: false,
                uploadSuccess: true,
              });
              setTimeout(() => {
                props.onHide();
              }, 2000);
            }}
            pcaId={props.pcaId}
            selectedProduct={props.selectedProduct}
            setConfirm={setConfirm}
            token={props.token}
          />
        </Suspense>
      }
    />
    <NotificationModal
      confirmationModalShow={confirm.modalShow}
      modalClose={() => {
        setConfirm({ ...confirm, modalShow: false });
      }}
      color={confirm.error ? `error` : `success`}
      confirmationText={confirm.text}
    />
  </>);
}
