import { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import GoogleMapComponent from "../../../../components/Maps/GoogleMapComponent";

export default function InventoryStatusMap(props) {
  const classes = {
    dataButton: {
      marginTop: "5px",
    },
  };
  const {
    dispatchGlobal,
    eventTypesMap = {},
    facilities,
    setConfirm,
    setLoading,
    setState,
    showLastEvents,
    state,
    timeZone,
  } = props;
  const assets = useMemo(() => state.assets || [], [state.assets]);
  const [mapSettings, setMapSettings] = useState({
    center: { lat: 60.363007, lng: -43.6339 },
    zoom: 5,
  });
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    const defaultLat = 60.363007;
    const defaultLon = -43.6339;

    if (
      assets &&
      !assets[0]?.latitude &&
      !assets[0]?.longitude &&
      assets[0]?.failityId
    ) {
      const currentFacility = facilities[assets[0]?.facilityId] || {};
      const { location = {} } = currentFacility;
      const {
        latitude: currentLat = defaultLat,
        longitude: currentLon = defaultLon,
      } = location;

      setMapSettings({ center: { lat: currentLat, lng: currentLon }, zoom: 4 });
    } else if (assets && assets[0]?.latitude && assets[0]?.longitude) {
      setMapSettings({
        center: { lat: assets[0].latitude, lng: assets[0].longitude },
        zoom: 4,
      });
    } else {
      // Default view if no pins are available
      setMapSettings({
        center: { lat: defaultLat, lng: defaultLon },
        zoom: 2.3,
      });
    }
  }, [assets, facilities]);

  const markersToRender = assets
    .map((asset) => {
      if (
        asset &&
        (!asset.latitude || !asset.longitude) &&
        asset.facility &&
        asset.facilityId
      ) {
        const currentFacility = facilities[asset.facilityId] || {};
        const { location = {} } = currentFacility;
        const { latitude: currentLat, longitude: currentLon } = location;
        return { ...asset, latitude: currentLat, longitude: currentLon };
      } else if (
        asset &&
        (asset.latitude !== 0 || asset.longitude !== 0) &&
        asset.latitude &&
        asset.longitude
      ) {
        return asset;
      }
      return null;
    })
    .filter((asset) => asset !== null)
    .sort((a, b) => a.timeOfLog - b.timeOfLog);

  const popUpContent = (marker) => {
    const {
      facilityId = null,
      lastEvent = null,
      latitude = 0,
      longitude = 0,
      propertiesMap = {},
      tag = null,
      timeOfLogLong = null,
    } = marker;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {tag ? (
          <Typography component="h2" sx={{ fontSize: "22px" }}>
            {tag}
          </Typography>
        ) : (
          ""
        )}
        {lastEvent ? (
          <Typography component="span" sx={{ fontSize: "22px" }}>
            {lastEvent}
          </Typography>
        ) : (
          ""
        )}{" "}
        {propertiesMap && propertiesMap.note ? (
          <Typography component="span" sx={{ fontSize: "18px" }}>
            Note: {propertiesMap.note}
          </Typography>
        ) : (
          ""
        )}
        {facilityId &&
        facilities[facilityId] &&
        latitude !== 0 &&
        longitude !== 0 ? (
          <Typography component="span" sx={{ fontSize: "14px" }}>
            Facility: {facilities[facilityId].name}
          </Typography>
        ) : (
          ""
        )}{" "}
        {timeOfLogLong ? (
          <>
            <Typography component="span" sx={{ fontSize: "14px" }}>
              Date: {unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)}
            </Typography>
            <br />
          </>
        ) : null}
        <br />
        <Button
          sx={classes.dataButton}
          color="primary"
          variant="contained"
          onClick={() => {
            // get initial count from this facility... if greater than 1,000, throw a window confirm.
            dispatchGlobal(
              showLastEvents({
                filters: state.filters,
                page: state.page,
                limit: 1,
              })
            ).then((res) => {
              if (!res.payload.success) {
                setConfirm({
                  modalShow: true,
                  text: res.payload.error,
                  error: true,
                });
                setLoading(false);
                return null;
              }
              if (res.payload.count >= 1000) {
                if (
                  !window.confirm(
                    `This facility has more than 1,000 assets. The map table will display only the first 1,000 assets at this facility. To narrow your results for this facility, please change your filters.`
                  )
                ) {
                  setLoading(false);
                  return null;
                }
              }
              dispatchGlobal(
                showLastEvents({
                  filters: state.filters,
                  isExport: true,
                  page: state.page,
                  locations: [facilityId],
                })
              ).then((res) => {
                if (!res.payload.success) {
                  setConfirm({
                    modalShow: true,
                    text: res.payload.error,
                    error: true,
                  });
                  setLoading(false);
                  return null;
                }
                setState((prevState) => {
                  return {
                    ...prevState,
                    mapTableData: res.payload.assets.filter(
                      (asset) => asset.facilityId === facilityId
                    ),
                  };
                });
                setLoading(false);
              });
            });
          }}
        >
          See Data
        </Button>
      </Box>
    );
  };

  return (
    <GoogleMapComponent
      activeMarker={activeMarker}
      eventTypesMap={eventTypesMap}
      markersToRender={markersToRender}
      markerKey="assetId"
      mapSettings={mapSettings}
      popUpContent={popUpContent}
      setActiveMarker={setActiveMarker}
    />
  );
}
