import { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getDevices } from "../../../utils/API/Devices/Devices";
import { PieChart } from '@mui/x-charts/PieChart';

export default function TrackerCount(props) {
  const [state, setState] = useState({
    assigned: 0,
    unAssigned: 0,
  });
  const { assigned, unAssigned } = state;

  useEffect(() => {
    getDevices(props).then((data) => {
      let assigned = 0;
      let unAssigned = 0;

      data.assets.forEach((asset) => {
        const { device = {} } = asset || {};
        const { hasDescendents = false } = device;

        if (hasDescendents) {
          assigned++;
        } else {
          unAssigned++;
        }

        setState({
          assigned,
          unAssigned,
        });
      });
    });
  }, [props]);

  return (
    (<Grid container sx={{ height: "100%" }}>
      <Grid size={12}>
        <Card sx={{ boxShadow: 3, height: "100%" }}>
          <CardContent
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Grid container spacing={2} id="trackerCount">
              {/* Title */}
              <Grid sx={{ textAlign: 'center', marginBottom: '1rem' }} size={12}>
                <Typography variant="h5">Tracker Count</Typography>
              </Grid>

              {/* Pie Chart */}
              <Grid size={12}>
                <PieChart
                  height={300}
                  margin={{ top: 0, bottom: 50, left: 25, right: 25 }}
                  series={[
                    {
                      data: [
                        {
                          color: "rgba(255, 99, 132, 0.2)",
                          id: 0,
                          label: 'Assigned',
                          value: assigned,
                        },
                        {
                          color: "rgba(54, 162, 235, 0.2)",
                          id: 1,
                          label: 'Unassigned',
                          value: unAssigned,
                        }
                      ]
                    }
                  ]}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      padding: 0,
                      position: { vertical: 'bottom', horizontal: 'middle' },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>)
  );
}
