import { useState } from "react";
import { Box, Dialog, DialogTitle, Typography, useTheme } from "@mui/material";
import { getForm } from "../../../../utils/API/Forms/Forms";
import { naturalSort } from "../../../../utils/naturalSort";
import { submitUpdateEvent } from "../../api";
import { turnAssetIntoAContainerAsset } from "../../../../utils/API/Assets/Assets";
import { useSelector } from "react-redux";
import AssetIdTextField from "../../../../components/Forms/FieldTypes/AssetIdTextField/AssetIdTextField";
import AutocompleteSelect from "../../../../components/Forms/FieldTypes/AutocompleteSelect";
import Grid from "@mui/material/Grid2";
import MaterialUiButton from "../../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import Paper from "@mui/material/Paper";
import ReceiveForm from "./ReceiveForm";
import ScannedAssetsTextField from "../../../../components/Forms/FieldTypes/ScannedAssetsTextField";
import SimpleSelect from "../../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../../components/Forms/FieldTypes/TextField";

function Update(props) {
  const theme = useTheme();
  const classes = {
    root: {
      flexGrow: 1,
      height: "100%",
    },
    formArea: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      maxHeight: "43vh",
      overflow: "auto",
    },
    submit: {
      marginTop: theme.spacing(4),
    },
    select: {
      marginTop: theme.spacing(1),
      width: "90%",
    },
    input: {
      width: "90%",
    },
  };
  const {
    apiUrl = "",
    assetData = {},
    eventTypes = [],
    eventTypesMap = {},
    facilities = {},
    setModal = () => {},
    setState = () => {},
    token = "",
    userId = "",
  } = props;
  const [assetInfo, setAssetInfo] = useState({});

  // Its a hack because we have to declare a assetId as a container and then move forward with it.
  // This should be a real action. Additional logic should not have to happen on the front end for this.
  // Seperation of conernnnns
  const [assetIdUsedInHack, setAssetIdUsedInHack] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const facilityOptions = Object.keys(facilities)
    .map((key) => ({
      value: key,
      label: facilities[key].name,
    }))
    .sort((a, b) => naturalSort(a.label, b.label));

  // Adding blank value to facility options
  facilityOptions.push({ value: "", label: "" });

  const [formFields, setFormFields] = useState([]);
  // const [formName, setFormName] = useState("");
  const [selectedForm, setSelectedForm] = useState({});
  const [staticFormFields, setStaticFormFields] = useState([]);
  const [receiveFormState, setReceiveFormState] = useState({
    applyActiveTagChecked: false,
    assetIdFromExternalScan: "",
    itemLabel: "",
    navigateToEventListPage: false,
    receiveFormAssetId: "",
    showFormFields: false,
    snackbarShow: false,
    snackbarText: "",
    snackbarSeverity: "success",
  });
  const [updateEvent, setUpdateEvent] = useState({
    location: null,
    event: "",
    note: "",
    zone: { label: "", id: "" },
  });
  const [zoneOptions, setZoneOptions] = useState([]);

  const { name: formName = "" } = selectedForm;

  const { zones } = useSelector((state) => state.organization);

  // create setZoneOptions function which takes in facilityId and sets zoneOptions
  const zoneOptionsCreator = (facilityId) => {
    if (!facilityId || facilityId === "") {
      setZoneOptions([]);
    }
    const updatedZoneOptions = [];
    Object.keys(zones).forEach((zoneId) => {
      if (zones[zoneId].facilityId === facilityId) {
        updatedZoneOptions.push({ id: zoneId, label: zones[zoneId].name });
      }
    });
    setZoneOptions(updatedZoneOptions);
  };

  const fieldComps = (fieldsArray = {}, isStatic = false) => {
    const renderedFields = Object.keys(fieldsArray).map((formField, index) => {
      const specificField = !isStatic
        ? formFields[formField]
        : staticFormFields[formField];
      const { fieldType, fieldKey, formOptions, label, readOnly } =
        specificField || {};
      const value = isStatic
        ? staticFormFields[formField]?.fieldValue
        : formFields[formField]?.fieldValue;
      const key = `${index} - ${fieldType}`;
      let selectOptions = [];

      // We need to massage the select fields to match what the SimpleSelect is expecting {label:"",value:""}
      if (formOptions) {
        selectOptions = formOptions.map((option) => {
          return {
            label: option.label,
            value: option.formOptionId,
          };
        });
      }

      function onChange(e) {
        const { assetInfo = null, selectionLabel = null, value } = e.target;
        if (isStatic) {
          setStaticFormFields((prevState) => {
            let newState = {
              ...prevState,
              [formField]: {
                ...prevState[formField],
                fieldValue: value,
                selectionLabel: selectionLabel,
              },
            };

            if (assetInfo) {
              newState[formField].assetInfo = assetInfo;
            }

            return newState;
          });
        } else {
          setFormFields((prevState) => {
            let newState = {
              ...prevState,
              [formField]: {
                ...prevState[formField],
                fieldValue: value,
                selectionLabel: selectionLabel,
              },
            };

            if (assetInfo) {
              newState[formField].assetInfo = assetInfo;
            }

            return newState;
          });
        }
      }

      function onValueClear() {
        if (isStatic) {
          setStaticFormFields((prevState) => ({
            ...prevState,
            [formField]: {
              ...prevState[formField],
              assetInfo: null,
              fieldValue: "",
              selectionLabel: null,
            },
          }));
        } else {
          setFormFields((prevState) => ({
            ...prevState,
            [formField]: {
              ...prevState[formField],
              assetInfo: null,
              fieldValue: "",
              selectionLabel: null,
            },
          }));
        }
      }

      // Here we check to see if the field is a text field AND an assetId field.
      // We have to do special steps with these fields, so they broken out of the switch
      // statement. These assets are usually devices.
      if (fieldType === "text" && fieldKey === "assetId") {
        return (
          <AssetIdTextField
            assetInfo={assetInfo}
            key={index}
            fieldIndex={index}
            fieldKey={fieldKey}
            formFields={formFields}
            label={label}
            onChange={(e) => {
              onChange(e);
            }}
            onDeleteIconClick={() => {
              // Clear out the assetInfo
              setAssetInfo({});

              // Clear out any value this might of had
              onValueClear();
            }}
            readOnly={readOnly}
            setAssetInfo={setAssetInfo}
            showDeleteIcon={true}
            showInformation={true}
            size="medium"
            token={token}
            value={value}
          />
        );
      }

      switch (fieldType) {
        // Had no idea you could string case's like this
        // https://stackoverflow.com/questions/13207927/switch-statement-for-multiple-cases-in-javascript
        case "externalAssembly":
        case "externalId":
        case "dissociate":
        case "location":
        case "text":
          return (
            <SimpleTextField
              key={key}
              label={label}
              onChange={onChange}
              onDeleteIconClick={onValueClear}
              readOnly={readOnly}
              size="medium"
              showDeleteIcon={true}
              value={value}
            />
          );
        case "number":
          return (
            <SimpleTextField
              key={key}
              label={label}
              onChange={onChange}
              onDeleteIconClick={onValueClear}
              readOnly={readOnly}
              size="medium"
              showDeleteIcon={true}
              type="number"
              value={value}
            />
          );
        case "select":
          return (
            <SimpleSelect
              key={key}
              label={label}
              onChange={(e) => {
                let selectionObject = formOptions.find(
                  (element) => element.formOptionId === e.target.value
                );
                const { label = "" } = selectionObject;

                onChange({
                  target: { value: e.target.value, selectionLabel: label },
                });
              }}
              onDeleteIconClick={onValueClear}
              options={selectOptions}
              readOnly={readOnly}
              size="medium"
              showDeleteIcon
              value={value || ""}
              variant="outlined"
            />
          );

        //   // Note: allowMultiple can be null, true or false
        //   //   null: a single value will be written into the fieldValue
        //   //   true: multiple values may be added to the fieldValues array
        //   //   false: a single value is maintained in the fieldValues array
        //   //
        case "scannedAssets":
          return (
            <ScannedAssetsTextField
              assetInfo={assetInfo}
              key={index}
              fieldIndex={index}
              fieldKey={fieldKey}
              formFields={formFields}
              label={label}
              isHack={formName === "Create Container"}
              onChange={(event) => {
                onChange(event);
              }}
              onClear={() => {
                // Clear out the assetInfo
                setAssetInfo({});

                // Clear out any value this might of had
                setFormFields((prevState) => ({
                  ...prevState,
                  [formField]: { ...prevState[formField], fieldValue: "" },
                }));
              }}
              readOnly={readOnly}
              setAssetIdUsedInHack={setAssetIdUsedInHack}
              setAssetInfo={setAssetInfo}
              setState={setState}
              showInfoIcon={true}
              token={token}
              value={value}
            />
          );

        default:
          console.log("***** can't process field type: " + formField.fieldType);
          break;
      }
      return null;
    });

    return renderedFields;
  };

  function editAsset() {
    submitUpdateEvent(
      {
        action: selectedForm,
        apiUrl,
        assetData,
        facilities,
        formFields,
        isReceiveForm: formName === "Receive",
        receiveFormAssetId: receiveFormState.receiveFormAssetId,
        staticFormFields,
        token,
        userId,
      },
      updateEvent
    ).then((res) => {
      if (res.success) {
        // We need to clear all the formFieldValues
        setFormFields((prevState) => {
          let newState = { ...prevState };
          Object.keys(prevState).forEach((item) => {
            const specificField = newState[item];

            specificField.fieldValue = "";
          });

          return newState;
        });

        // Also need to clear out the assetInfo
        setAssetInfo({});

        // We need to throw the success modal up. Also need to null the externalId out
        setState((prevState) => {
          const newState = {
            ...prevState,
            externalId: null,
          };

          // We perform this catch because someone could run a event with an associated form
          // And scan something that has nothing to do with this asset.
          if (res?.asset?.assetId === assetData.assetId) {
            newState.assetData = {
              ...prevState.assetData,
              ...res.asset,
              lastEvent: res.assetHistory.event,
              propertiesMap: {
                ...(prevState.assetData.propertiesMap || {}),
                ...res.asset.propertiesMap,
                note: res.assetHistory.propertiesMap.note,
              },
            };

            newState.histories = {
              ...prevState.histories,
              histories: [res.assetHistory].concat(
                prevState?.histories?.histories || []
              ),
              count: prevState.histories.count + 1,
            };
          }

          return newState;
        });

        // Trigger success modal
        setModal({
          modalShow: true,
          text: "Update Event Success",
          isError: false,
        });
      } else {
        setModal({
          modalShow: true,
          text: `Something went wrong. ${res?.error ? res?.error : ""}`,
          isError: false,
        });
      }
    });
  }

  function prepFields(arrayOfFields) {
    let collectionOfFields = {};

    arrayOfFields.forEach((field, index) => {
      const fieldCopy = { ...field };

      // Setting the default value
      fieldCopy["fieldValue"] = "";

      collectionOfFields[index] = fieldCopy;
    });
    return collectionOfFields;
  }

  function validateFormFields() {
    let isFormError = false;

    // Check the form fields to see if anything needs to be validated
    Object.keys(formFields).forEach((formField) => {
      const { fieldValue, required } = formFields[formField];

      if (required && (fieldValue === "" || fieldValue === null)) {
        isFormError = true;
      }
    });

    // Check the static form fields to see if anything needs to be validated
    Object.keys(staticFormFields).forEach((formField) => {
      const { fieldValue, required } = staticFormFields[formField];

      if (required && (fieldValue === "" || fieldValue === null)) {
        isFormError = true;
      }
    });

    // Set the form error state
    if (isFormError) {
      setIsFormError(isFormError);
      setIsSubmitButtonDisabled(true);
    } else {
      setIsFormError(isFormError);
      setIsSubmitButtonDisabled(false);
    }

    return;
  }

  return (
    <Box sx={classes.root}>
      <Typography style={{ textAlign: "left" }} variant="h4">
        Update Event
      </Typography>
      <Grid container direction="row" spacing={1}>
        <Grid size={12}>
          <Paper sx={classes.formArea} elevation={0}>
            <form
              onSubmit={(e) => {
                if (formName === "Create Container") {
                  turnAssetIntoAContainerAsset({
                    token,
                    assetId: assetIdUsedInHack,
                  }).then((res) => {
                    if (res?.success) {
                      editAsset();
                    } else {
                      console.log("error handling here");
                    }
                  });
                } else {
                  e.preventDefault();
                  editAsset();
                }
              }}
            >
              <Grid container spacing={3}>
                {/* Dialog is used to display forms with associated events */}
                <Dialog
                  fullWidth
                  maxWidth="md"
                  onClose={() => {
                    // Close the Dialog
                    setIsDialogOpen(false);

                    // Check if the user missed any required fields in a form
                    validateFormFields();
                  }}
                  open={isDialogOpen}
                >
                  <DialogTitle>{formName}</DialogTitle>
                  {/* Receive for requires special handling */}
                  {updateEvent.event === "Receive" ? (
                    <ReceiveForm
                      fieldComps={fieldComps}
                      receiveFormState={receiveFormState}
                      selectedForm={selectedForm}
                      setIsDialogOpen={setIsDialogOpen}
                      setReceiveFormState={setReceiveFormState}
                      token={token}
                      validateFormFields={validateFormFields}
                    />
                  ) : (
                    <Grid
                      container
                      sx={{
                        padding: "1rem",
                      }}
                    >
                      {/* Static Form Fields */}
                      <Grid size={12}>
                        {Object.keys(staticFormFields)?.length > 0
                          ? fieldComps(staticFormFields, true)
                          : null}
                        {Object.keys(formFields)?.length > 0
                          ? fieldComps(formFields)
                          : null}
                      </Grid>
                      <Grid sx={{ marginTop: "1rem" }} size={12}>
                        <MaterialUiButton
                          label="Close"
                          onClick={() => {
                            // Close the Dialog
                            setIsDialogOpen(false);

                            // Check if the user missed any required fields in a form
                            validateFormFields();
                          }}
                          variant="contained"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Dialog>

                {/* Facility Name */}
                <Grid
                  size={{
                    xs: 12,
                    sm: 4,
                  }}
                >
                  <AutocompleteSelect
                    id="facilityName"
                    getOptionLabel={(option) => option.label}
                    label="Facility Name"
                    onChange={(event, value) => {
                      setUpdateEvent({
                        ...updateEvent,
                        location: value || null,
                        zone: { label: "", id: "" },
                      });

                      zoneOptionsCreator(value?.value || null);
                    }}
                    options={facilityOptions}
                    size="large"
                    sx={classes.select}
                    value={updateEvent.location}
                    variant="standard"
                  />
                </Grid>

                {/* Event */}
                <Grid
                  size={{
                    xs: 12,
                    sm: 4,
                  }}
                >
                  <SimpleSelect
                    classes={classes}
                    id="event"
                    label="Event"
                    margin="dense"
                    onChange={(e) => {
                      let formId =
                        eventTypesMap[e.target.value]?.formId || null;

                      // Set the Event
                      setUpdateEvent({
                        ...updateEvent,
                        event: e.target.value,
                      });

                      // Is there a form associated with the action? Cool, throw the fields up
                      // otherwise clear it out.
                      if (formId) {
                        getForm({ apiUrl, formId, token }).then((res) => {
                          if (res.success) {
                            const { form = {} } = res || {};

                            // We get the selected form and need to set the fields and static fields
                            const {
                              fields = [],
                              multiUseFields = {},
                              name = "",
                            } = form || {};
                            const {
                              fields: specificFormFields = [],
                              staticFields: specificStaticFields = [],
                            } = multiUseFields || {};

                            // Hopefully the receive field is a one off and we dont have to keep doing one off logic like this.
                            // All other forms are using {formId}.multiuseFields. Except the receive form. Its using {formId}.fields
                            if (name === "Receive") {
                              setFormFields(prepFields(fields));
                            } else {
                              setFormFields(prepFields(specificFormFields));
                            }
                            setSelectedForm(form);
                            setStaticFormFields(
                              prepFields(specificStaticFields)
                            );

                            // We disable the submit button until the user fills out the form
                            setIsSubmitButtonDisabled(true);
                          }
                        });
                      } else {
                        // Alot of this is just clearing out the form fields. Primarly for the asstedIdTextField to work properly.
                        setAssetInfo({});
                        setFormFields([]);
                        setFormFields([]);
                        setIsFormError(false);
                        setIsSubmitButtonDisabled(false);
                        setSelectedForm({});
                        setStaticFormFields([]);
                        setStaticFormFields([]);
                      }

                      // Clear out any asset's they might of scanned on the previous form
                      setAssetInfo({});
                    }}
                    options={eventTypes
                      .map((event) => {
                        return {
                          value: event,
                          label: event,
                        };
                      })
                      .sort((a, b) => naturalSort(a.label, b.label))}
                    required
                    size="large"
                    value={updateEvent.event}
                    variant="standard"
                  />
                </Grid>

                {/* Zones */}
                <Grid
                  size={{
                    xs: 12,
                    sm: 4,
                  }}
                >
                  <AutocompleteSelect
                    id="zone"
                    getOptionLabel={(option) => option.label}
                    label="Zone"
                    onChange={(event, value) => {
                      setUpdateEvent({
                        ...updateEvent,
                        zone: value || null,
                      });
                    }}
                    options={zoneOptions}
                    size="large"
                    sx={classes.select}
                    value={updateEvent.zone}
                    variant="standard"
                  />
                </Grid>

                {/* Bin Location */}
                <Grid
                  size={{
                    xs: 12,
                    sm: 4,
                  }}
                >
                  <SimpleTextField
                    id="binLocation"
                    label="Bin Location"
                    name="binLocation"
                    onChange={(e) => {
                      setUpdateEvent({
                        ...updateEvent,
                        binLocation: e.target.value,
                      });
                    }}
                    size="large"
                    sx={classes.input}
                    type="text"
                    value={updateEvent.binLocation}
                    variant="standard"
                  />
                </Grid>

                {/* Notes */}
                <Grid
                  size={{
                    xs: 12,
                    sm: 12,
                  }}
                >
                  <SimpleTextField
                    id="note"
                    label="Notes"
                    margin="normal"
                    multiline={true}
                    name="note"
                    onChange={(e) => {
                      setUpdateEvent({
                        ...updateEvent,
                        note: e.target.value,
                      });
                    }}
                    rows="1"
                    sx={classes.input}
                    type="text"
                    variant="standard"
                    value={updateEvent.note}
                  />
                </Grid>

                {/* Form Fields */}
                <Grid
                  size={{
                    xs: 12,
                    sm: 12,
                  }}
                >
                  {formFields.length > 0 ? fieldComps(formFields) : null}
                  {staticFormFields.length > 0
                    ? fieldComps(staticFormFields, true)
                    : null}
                </Grid>

                {/* Click here to fill out form */}
                {formName?.length > 0 ? (
                  <Grid size={12}>
                    <Grid container>
                      <Grid size={12}>
                        The following event has an associated form. Click the
                        button to fill out the form.
                      </Grid>
                      <Grid
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                        size={12}
                      >
                        <MaterialUiButton
                          label="Fill Out Form"
                          onClick={() => setIsDialogOpen(true)}
                          sx={{ marginTop: "1rem" }}
                          variant="contained"
                        />

                        {/* Error message for incomplete form */}
                        {isFormError ? (
                          <Typography
                            sx={{ color: "red", marginLeft: ".5rem" }}
                            variant="subtitle2"
                          >
                            There are required fields that need to be filled
                            out.
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>

              {/* If a given event has a form associated with it. Required fields must be filled in  */}
              <MaterialUiButton
                className={classes.submit}
                disabled={isSubmitButtonDisabled}
                label="Submit"
                type="submit"
                variant="contained"
              />
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Update;
