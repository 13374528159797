import { BatchInformationTable, ProductInformationTable } from "../Tables";
import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";

function Overview(props) {

  const theme = useTheme();

  const classes = {
    root: {
      flexGrow: 1,
      height: "100%",
    },
    overview: {
      width: "100%",
      textAlign: "left",
      color: theme.palette.text.secondary,
      // These were initially set, Im not sure why though. These rules break they layout when the resolution is set high (3840 x 2160)
      // minHeight: "30vh",
      // maxHeight: "30vh",
    },
    filter: {
      textAlign: "left",
      color: theme.palette.text.secondary,
      width: "100%",
      margin: "auto",
    },
    card: {},
  }

  const { facilityMap } = props.state.lists;

  return (
    (<Box sx={classes.root}>
      <Grid container direction="row" spacing={1}>
        <Grid size={12}>
          <Paper sx={classes.overview} elevation={0}>
            <Grid container spacing={1}>
              <Grid size={12}>
                <h4 style={{ textAlign: "left" }}>Product Information</h4>
                <ProductInformationTable state={props.state} />
              </Grid>
              <Grid size={12}>
                <h4 style={{ textAlign: "left" }}>Batch Information</h4>
                <BatchInformationTable
                  timeZone={props.timeZone}
                  state={props.state}
                  facilityMap={facilityMap}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>)
  );
}

export default Overview;
