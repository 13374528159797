import buildDefaultColumns from "./displayConfigUtils";

export default function returnsDefaultDisplayColumns(props) {
  const { customs, classifications, name } = props;

  //distinguish btwn asset and inventory customs and classifications.
  //Nice source of truth location for this logic

  const assetClassifications = Object.values(classifications).filter(
    (classification) => classification.assetMode === "Asset"
  );
  const assetCustoms = customs.filter((custom) => custom.assetMode === "Asset");
  const inventoryClassifications = Object.values(classifications).filter(
    (classification) => classification.assetMode === "Inventory"
  );
  const inventoryCustoms = customs.filter(
    (custom) => custom.assetMode === "Inventory"
  );

  if (name === "AssetStatus" || name === "AssetItemAnalysis") {
    return buildDefaultColumns({
      classifications: assetClassifications,
      customs: assetCustoms,
      isInventory: false,
      isHistory: false,
    });
  } else if (name === "AssetHistory" || name === "AssetEventAnalysis") {
    return buildDefaultColumns({
      classifications: assetClassifications,
      customs: assetCustoms,
      isInventory: false,
      isHistory: true,
    });
  } else if (name === "AssetSnapshot") {
    return buildDefaultColumns({
      classifications: assetClassifications,
      customs: assetCustoms,
      isSnapshot: true,
    });
  } else if (name === "InventoryStatus" || name === "ItemAnalysis") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isHistory: false,
    });
  } else if (name === "InventoryHistory" || name === "EventAnalysis") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isHistory: true,
    });
  } else if (name === "InventorySnapshot") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isSnapshot: true,
    });
  } else if (name === "MovementHistory") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isHistory: true,
    });
  } else if (name === "Device") {
    return buildDefaultColumns({
      isDevice: true,
    });
  } else if (name === "DailyHistory") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isHistory: true,
    });
  }
}
