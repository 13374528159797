import moment from "moment";

export const onSubmit = async (
  customFields,
  handleAlertModal,
  props,
  values
) => {
  const { apiUrl, facilities, token, userId } = props;
  const {
    category,
    description,
    inventoryItemTag,
    note,
    quantity,
    selectedFacility,
    selectedZone,
    selectedInventoryClassifications,
    status,
    timeCreatedUser,
    timeNeeded,
    xCoordinate,
    yCoordinate,
  } = values;

  const { location = {}, name: facilityName } =
    facilities[selectedFacility?.value];
  const { facilityId, latitude, longitude } = location;
  const body = {
    assetMode: "Inventory",
    category: category.value,
    customMap: {},
    classificationMap: selectedInventoryClassifications,
    location,
    timeNeeded: timeNeeded?.value
      ? moment(timeNeeded.value, "MM/DD/yyyy").format("YYYYMMDDHHmm")
      : "",
    timeCreatedUser: timeCreatedUser?.value
      ? moment(timeCreatedUser.value, "MM/DD/yyyy").format("YYYYMMDDHHmm")
      : "",
    facility: facilityName,
    propertiesMap: {
      assetDetails: note.value,
      category: category.value,
      description: description.value,
    },
    quantity: {
      quantityCurrent: quantity.value,
    },
    tag: inventoryItemTag.value,
    zone: {
      zoneId: selectedZone.value,
    },
    xyz: {
      x: xCoordinate.value,
      y: yCoordinate.value,
    },
  };

  // add properties into customMap based on customFields(custom data elements)
  Object.keys(customFields).forEach((field) => {
    const { name, value = "" } = customFields[field];
    if (
      value?.length > 0 ||
      typeof value === "number" ||
      value instanceof Date
    ) {
      body.customMap[name] = value;
    }
  });

  const results = await fetch(`${apiUrl}assets/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then(async (response) => {
      if (response.error) {
        return response;
      }

      const { assetId } = response.asset;
      const updateAssetBody = {
        action: status.value,
        appUserId: userId,
        facilityId: facilityId,
        facility: facilityName,
        latitude,
        longitude,
        zone: {
          zoneId: selectedZone.value,
        }
      };
      const updateAsset = await fetch(`${apiUrl}assets/${assetId}/action`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(updateAssetBody),
      })
        .then(() => {
          handleAlertModal({
            alertModalContent: "Inventory Item Successfully Added",
            alertModalSeverity: "success",
          });
          return response.asset;
        })
        .catch((error) => {
          handleAlertModal({
            alertModalContent: error.toString(),
            alertModalSeverity: "error",
            alertModalTitle: "Error",
          });
        });

      return updateAsset;
    })
    .catch((error) => {
      handleAlertModal({
        alertModalContent: error.toString(),
        alertModalSeverity: "error",
        alertModalTitle: "Error",
      });
    });

  return results;
};