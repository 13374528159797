import { BarChart } from '@mui/x-charts/BarChart';
import Grid from "@mui/material/Grid2";
import { isValidColor } from '../../../globalStore/utils/helpers';
import { useSelector } from 'react-redux'

function DailyEventsBar(props) {
  const {
    barDatasets,
    barDatasets2,
    eventsGridRef,
    setSelectedBar,
  } = props
  const { data = [], series = {} } = barDatasets2
  const allEventTypesMap = useSelector((state) => state.organization.organization.allEventTypesMap)

  //create color pallete with 45 colors to use for bar chart not including the first 15 colors, grey, white, and black
  const colorPallete = [
    '#3366E6', 'pink', 'orange', '#00B3E6', '#CCFF1A', 'purple', '#999966', '#FF6633', 'green', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#FF1A66', '#E6331A', '#33FFCC', '#FFB399',
    '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D', '#80B300', '#809900',
    '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#FF1A66', '#E6331A', '#33FFCC', '#FFB399', '#FF33FF', '#FFFF99',
    '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC', '#FFB399'
  ]

  const handleLabelClick = (data) => {
    const { axisValue } = data || {}

    setSelectedBar({ date: axisValue })
    eventsGridRef.current?.focus()
  }

  const dataSets = []

  for (let key in barDatasets) {
    dataSets.push({
      label: key,
      data: barDatasets[key]
    })
  }

  colorPallete.forEach((color, index) => {

    const currDataSet = dataSets[index]
    const currDataSetLabel = currDataSet?.label

    //makes sure the event exists in allEventTypesMap and the color is valid
    if (currDataSet && allEventTypesMap[currDataSetLabel] && isValidColor(allEventTypesMap[currDataSetLabel]?.color)) {
      currDataSet.backgroundColor = allEventTypesMap[currDataSetLabel].color
    }
    else if (currDataSet) {
      currDataSet.backgroundColor = color
    }
    else return
  })

  return (
    (<Grid size={12}>
      <BarChart
        colors={colorPallete}
        dataset={data}
        height={200}
        onAxisClick={(event, data) => handleLabelClick(data)}
        series={series}
        slotProps={{ legend: { hidden: false } }}
        xAxis={[{ dataKey: 'date', scaleType: 'band', }]}
      />
    </Grid>)
  );
}

export default DailyEventsBar