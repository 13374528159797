export const searchRadios = async (
  props,
  zoneId,
  body = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              primary_p_zone_id: zoneId,
            },
          },
        ],
      },
    },
  }
) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}radios/search/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) => console.log(err));
  return results;
};

export const searchFacilityRadios = async (
  props,
  zoneIds,
  body = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            terms: {
              primary_p_zone_id: zoneIds,
            },
          },
        ],
      },
    },
  }
) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}radios/search/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) => console.log(err));
  return results;
};