import SimpleTextField from "../Forms/FieldTypes/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export default function FontSize(props) {

  const classes = {
    fontSize: {
      paddingTop: "3px",
    },
  }

  const {
    disabled = false,
    name = "fontSize",
    onChange,
    size,
    value,
    variant = "outlined",
  } = props;

  return (
      <SimpleTextField
        disabled={disabled}
        InputProps={{
          endAdornment: <InputAdornment position="end">pt</InputAdornment>,
          inputProps: {
            max: 40,
            min: 1,
          },
        }}
        name={name}
        onChange={onChange}
        size={size}
        sx={classes.fontSize}
        type="number"
        value={value}
        variant={variant}
      />
  );
}
