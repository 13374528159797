import { createSlice } from "@reduxjs/toolkit";

import { initDevices } from "./thunks/init";
import * as thunks from "./thunks/thunks";
import { extraReducers } from "./thunks/extraReducers";

export const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    init: {
      status: "idle",
    },
    filters: {},
    devices: [],
    count: 0,
  },
  reducers: {},
  extraReducers: {
    ...extraReducers,
  },
});

// Action creators are generated for each case reducer function
// const {} = devicesSlice.actions;

export { initDevices, thunks };

export default devicesSlice.reducer;
