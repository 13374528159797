import _ from "lodash";
import { ExportToCsv } from "export-to-csv";
import { formatLatLon } from "../../../../utils/lat_lon_utils";
import { utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import moment from "moment-timezone";
import XLSX from "xlsx";
const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function xlSerialToJsDate(xlSerial) {
  return new Date(
    -2209075200000 + (xlSerial - (xlSerial < 61 ? 0 : 1)) * 86400000
  );
}

export const exportSpreadsheet = async (
  histories,
  facilities,
  timeZone,
  products,
  zones,
  classifications
) => {
  const dateTimeHeader = `Date/Time${timeZone}`;

  const json = histories.map(async (history) => {
    const {
      classificationMap = {},
      customMap = {},
      productId = "",
      propertiesMap = {},
    } = history;

    const classificationOptions =
      !_.isEmpty(classifications) && classificationMap
        ? Object.keys(classifications)
          .map((classKey) => {
            const { name } = classifications[classKey];
            const classificationValue = classificationMap[classKey] || "";
            return {
              [name]: classificationValue,
            };
          })
          .reduce((x, y) => {
            return {
              ...x,
              ...y,
            };
          }, {})
        : {};

    let formData = {};
    let customDataFields = {};

    const itemLevelDataElements =
      products[productId]?.propertiesMap?.itemLevelDataElements?.reduce(
        (x, y) => {
          return {
            ...x,
            [y.id]: y,
          };
        },
        {}
      ) || {};

    customDataFields = Object.keys(propertiesMap)
      .filter(
        (e) =>
          e !== "classificationSet" &&
          e !== "deltaTimeHrs" &&
          e !== "distance" &&
          e !== "formData" &&
          e !== "formId" &&
          e !== "kmPerHour" &&
          e !== "note"
      )
      .map((k) => {
        if (itemLevelDataElements[k]?.dataType === "Date") {
          return {
            label: k,
            value:
              moment
                .utc(xlSerialToJsDate(propertiesMap[k]))
                .format("MM/DD/YYYY") || propertiesMap[k],
          };
        }
        return { label: _.startCase(k), value: propertiesMap[k] };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    if (customDataFields.length > 0) {
      customDataFields = customDataFields.reduce((x, y) => {
        return {
          ...x,
          [_.startCase(y.label)]: y.value,
        };
      }, {});
    }

    const renderedTime = utc_to_local_12_hour_clock(
      history.timeOfLog,
      timeZone
    );

    return {
      [dateTimeHeader]: renderedTime === "Invalid date" ? "-" : renderedTime,
      "Asset Tag": history.tag ? history.tag : "-",
      "Last Event": history.lastEvent ? history.lastEvent : "-",
      User: `${history.lastName || "-"}${history.firstName ? `, ${history.firstName}` : "-"
        }`,
      "Facility Name":
        !history.latitude || !history.longitude
          ? "-"
          : history.facilityId &&
            facilities[history.facilityId] &&
            facilities[history.facilityId].name
            ? facilities[history.facilityId].name
            : "Not Registered",
      Zone: zones[history.zone?.zoneId]?.name || "-",
      "Bin Location": history.zone?.binLocation || "-",
      State: history.state ? history.state : "-",
      "Latitude, Longitude":
        history.latitude && history.longitude
          ? formatLatLon(history.latitude, history.longitude)
          : "-",
      Category: customDataFields.Category ? customDataFields.Category : "-",
      Notes:
        history.propertiesMap && history.propertiesMap.note
          ? history.propertiesMap.note
          : "-",
      //'PCA Flagged':history.flagged ? "Flagged" : "",
      ...customDataFields,
      ...formData,
      ...classificationOptions,
      ...customMap,
    };
  });

  const currentTime = moment()
    .tz(timeZone)
    .format("YYYY-MM-DD");

  return Promise.all(json).then((data) => {
    const fileName = `Inv_Stat_${currentTime}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, fileName);
    return true;
  });
};

// Commented ALOT of this out. I believe we are going to attempt to 
// have the backend do this for us. Notice how this function just returns a boolean.
export const exportQRSpreadsheet = async (
  assets,
  token,
  tableName
) => {
  try {
    const jsonData = await Promise.all(
      assets.map(async (asset) => {
        const results = await fetch(`${apiUrl}assets/${asset.assetId}/qrUrl`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
        })
          .then((response) => response.json())
          .then((data) => data.url)
          .catch((error) => {
            console.error(error);
            return null;
          });

        return {
          "Asset Tag": asset.tag ? asset.tag : "-",
          "Qr Url": results || "", // Ensure the value is not undefined/null
        };
      })
    );

    const currentTime = moment().format("YYYY-MM-DD");

    const options = {
      decimalSeparator: ".",
      fieldSeparator: ",",
      filename: `QR_Codes_${tableName}_${currentTime}`,
      quoteStrings: '"',
      showLabels: true,
      showTitle: false,
      useBom: true,
      useKeysAsHeaders: true,
      useTextFile: false,
    };

    const csvExporter = new ExportToCsv(options);
    const csvData = csvExporter.generateCsv(jsonData);

    return true;
  }
  catch (error) {
    console.error("Error exporting QR spreadsheet:", error);
    return false;
  }
};
