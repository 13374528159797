export const getAssetsById = async (props, input, limit = 1000) => {
  const { apiUrl, token, organizationId } = props;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            terms: {
              asset_id: input
            }
          }
        ],
        //Match for strings that start with Scan and end with LogisticsPro
        must_not: [
          {
            regexp: {
              "lastEvent": {
                "value": "Scan.*LogisticsPro",
              }
            }
          }
        ]
      },
    },
    limit: limit,
    range: {},
    sort: [
      {
        tag: "desc",
      },
      {
        asset_type: "asc"
      }
    ],
  };

  const results = await fetch(`${apiUrl}assets/search?includeConsoleExtras=true`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { apiUrl, token, userId } = props;
  const payload = {
    propertiesMap: {
      assetStatusTableSettings: filters,
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const resetDisplayColumnOptions = async (props) => {
  const { apiUrl, token, userId } = props;
  const payload = {
    propertiesMap: {
      assetStatusTableSettings: {
        displayColumnOptions: null,
        defaultColumnOrder: [],
      },
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};
