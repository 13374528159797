import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { deleteAssetImage, editAssetImage, uploadAssetImage } from "../api";
import ImagesDisplayNSave from "../../../components/Images/ImagesDisplayNSave";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";

function Attachments(props) {
  const classes = {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      height: "60vh",
      overflow: "auto",
      width: "100%",
    },
  };

  const { apiUrl, assetData, assetId, setState, token } = props;

  const [attachmentState, setAttachmentState] = useState({
    images: assetData.images || [],
    imagesToDelete: [],
    imagesToEdit: {},
  });

  const { images = [], imagesToEdit = {} } = attachmentState;

  async function uploadImages(images, Id) {
    const newImages = images.filter((item) => item.imageId === undefined);

    // Create an array to store promises for each API call
    const uploadPromises = [];

    newImages.forEach((image) => {
      // Create a promise for each API call
      const uploadPromise = uploadAssetImage({
        ...props,
        assetId: Id,
        ...image,
      });
      uploadPromises.push(uploadPromise);
    });

    // Wait for all promises to resolve using Promise.all
    try {
      //get only the image objects from the response and filter out any without an imageId
      const response = await Promise.all(uploadPromises).then((res) =>
        res.map((item) => item.image)
      );

      // After all API calls are completed, update the 'images' state with the modified 'images' array
      const updatedImages = images
        .concat(response)
        .filter((item) => item.imageId !== undefined);

      //use setAttachmentState to update images
      setAttachmentState((prevState) => ({
        ...prevState,
        images: updatedImages,
      }));

      //set images in assetData using setState with updatedImages
      setState((prevState) => ({
        ...prevState,
        assetData: {
          ...prevState.assetData,
          images: updatedImages,
        },
      }));
    } catch (error) {
      // Handle errors if any of the API calls fail
      console.error("Error uploading images:", error);
    }
  }

  async function deleteImage(imageId) {
    try {
      const response = await deleteAssetImage({
        ...props,
        assetId: assetData.assetId,
        imageId,
      });

      //if response is true, then the image was deleted successfully
      if (response.success) {
        //filter through images and remove the image that was deleted
        const updatedImages = images.filter((img) => img.imageId !== imageId);
        //set state so that the image is removed
        setAttachmentState((prevState) => ({
          ...prevState,
          images: updatedImages,
        }));

        //update images in assetData using setState with updatedImages
        setState((prevState) => ({
          ...prevState,
          assetData: {
            ...prevState.assetData,
            images: updatedImages,
          },
        }));
      }
    } catch (error) {
      // TODO: put in modal
      console.error("Error editing image:", error);
    }
  }

  async function editImage(imageId) {
    const { name } = imagesToEdit[imageId];

    try {
      const response = await editAssetImage({
        ...props,
        assetId: assetData.assetId,
        imageId,
        name,
      });

      const editedImage = response.image;

      //map through images and replace the image that was edited
      const updatedImages = images.map((img) =>
        img.imageId === imageId ? editedImage : img
      );
      delete updatedImages[imageId]; // Remove the edited image

      //delete the imageId from imagesToEdit to prevent the save button from being enabled
      const updatedImagesToEdit = { ...imagesToEdit };
      delete updatedImagesToEdit[imageId]; // Remove the edited image

      //set state so that the image is updated and the imageId is removed from imagesToEdit
      setAttachmentState((prevState) => ({
        ...prevState,
        images: updatedImages,
        imagesToEdit: updatedImagesToEdit,
      }));

      //set images in assetData using setState with updatedImages
      setState((prevState) => ({
        ...prevState,
        assetData: {
          ...prevState.assetData,
          images: updatedImages,
        },
      }));
    } catch (error) {
      console.error("Error editing image:", error);
    }
  }

  return (
    <Box sx={classes.mainContainer}>
      <Typography component="h1" sx={{ textAlign: "left" }} variant="h5">
        Attachments
      </Typography>
      <ImagesDisplayNSave
        acceptedFileTypes={"image/jpeg, image/png, application/pdf, image/jpg"}
        apiUrl={apiUrl}
        deleteImage={deleteImage}
        editImage={editImage}
        images={images}
        imagesToEdit={imagesToEdit}
        assetId={assetId}
        readOnly={false}
        setState={setAttachmentState}
        token={token}
      />
      <MaterialUiButton
        color="submit"
        fullWidth={true}
        label="Save New Attachments"
        onClick={() => uploadImages(images, assetData.assetId)}
        //disable if there are no new images to save
        disabled={
          images.filter((item) => item.imageId === undefined).length === 0
        }
      />
    </Box>
  );
}
export default Attachments;
