import { useEffect, useState } from "react";
import { Box, CircularProgress, Paper, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import RecursiveTreeView from "../../../../components/TreeView/RecursiveTreeView";

export default function Hierarchy(props) {

  const theme = useTheme();

  const classes = {
    root: {
      flexGrow: 1,
      height: "100%",
    },
    formArea: {
      color: theme.palette.text.secondary,
      maxHeight: "43vh",
      minHeight: "43vh",
      overflow: "auto",
      padding: theme.spacing(2),
      textAlign: "left",
    },
  }

  const { treeView } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (treeView?.complete) {
      setLoading(false);
    }
  }, [treeView]);

  return (
    (<Box sx={classes.root}>
      <Typography component={"h1"} sx={{ textAlign: "left" }} variant="h5" >
        Hierarchy Tree
      </Typography>
      <Grid container direction="row" spacing={1}>
        <Grid size={12}>
          <Paper sx={classes.formArea} elevation={0}>
            {!loading ? (
              <RecursiveTreeView data={treeView} />
            ) : (
              <CircularProgress />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>)
  );
}
