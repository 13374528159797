import { useCallback, useEffect } from "react";

import { useInterval } from "./useInterval";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const useFreshDeskAuth = (token) => {
  const freshWorksWidget = window.FreshworksWidget;

  // set interval hook needs milliseconds. 7200000 === 2 hours
  let millisecondDelay = 7000000;

  // convert to seconds
  const JWT_ttl = parseInt(Math.floor(millisecondDelay / 1000));

  // Fires the authenticate function before the JWT expires (every 2 hours)
  useInterval(() => {
    authenticate();
  }, millisecondDelay);

  const authenticate = useCallback(() => {
    const fetchJWT = async () => {
      return await fetch(`${API_URL}appUsers/freshDeskWidgetJWT`, {
        method: "POST",
        headers: {
          "auth-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          timeToLive: JWT_ttl,
        }),
      })
        .then((res) => res.json())
        .then((res) => res.freshDeskWidgetJWT);
    };

    fetchJWT().then((res) => {
      freshWorksWidget("authenticate", {
        token: res,
      });
    });
  }, [freshWorksWidget, token, JWT_ttl]);

  useEffect(() => {
    authenticate();
  }, [token, authenticate]);
};
