import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
  useTheme,
} from "@mui/material/";
// import GenerateCQRContent from "../../../components/Layout/AppBar/GenerateCQRContent";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { isValidDateObject } from "../../../globalStore/utils/helpers";
import { naturalSort } from "../../../utils/naturalSort";
import { onSubmit } from "./utils";
import AsteriskSolid from "../../../assets/faIcons/AsteriskSolid";
import AutocompleteSelect from "../../../components/Forms/FieldTypes/AutocompleteSelect";
import CurrencyTextBox from "../../../components/Forms/FieldTypes/CurrencyTextBox";
import Grid from "@mui/material/Grid2";
import LXDatePicker from "../../../components/Forms/FieldTypes/DatePicker";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import MuiSVGIcon from "../../../components/IconPicker/MuiSVGIcon";
import NotificationModal from "../../../components/Modals/NotificationModal";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";

export default function InventoryContent(props) {
  const theme = useTheme();
  const { palette = {} } = theme;
  const { typography = {} } = palette;
  const classes = {
    button: {
      marginLeft: "1rem",
    },
    label: {
      color: `${typography?.secondary} !important`,
      fontFamily: "Lato !important",
      fontWeight: "bold !important",
      lineHeight: "1 !important",
    },
    inputContainer: {
      margin: ".5rem 0 !important",
      padding: "0 .5rem",
    },
    inputField: {
      width: "100%",
    },
  };

  const { customs, facilities, organization, zones } = props;
  // const [generateCQR, setGenerateCQR] = useState(false);
  // const [newInventoryItem, setNewInventoryItem] = useState("");

  const initialState = {
    category: {
      error: false,
      id: "category",
      isRequired: false,
      placeholder: "-Select Category-",
      value: "",
    },
    description: {
      error: false,
      id: "description",
      isRequired: true,
      value: "",
    },
    inventoryItemTag: {
      error: false,
      id: "inventoryItemTag",
      isRequired: true,
      value: "",
    },
    note: { error: false, id: "note", value: "" },
    quantity: { error: false, id: "quantity", isRequired: true, value: "" },
    selectedFacility: {
      error: false,
      placeholder: "-Select Facility-",
      id: "selectedFacility",
      isRequired: true,
      value: "",
    },
    selectedZone: {
      error: false,
      placeholder: "-Select Zone-",
      id: "selectedZone",
      value: "",
    },
    status: {
      error: false,
      id: "status",
      isRequired: true,
      placeholder: "-Select Status-",
      value: "",
    },
    timeCreatedUser: { error: false, id: "timeCreatedUser", value: "" },
    timeNeeded: { error: false, id: "timeNeeded", value: "" },
    xCoordinate: { error: false, id: "xCoordinate", value: "" },
    yCoordinate: { error: false, id: "yCoordinate", value: "" },
  };

  const [
    {
      category,
      description,
      inventoryItemTag,
      note,
      quantity,
      selectedFacility,
      selectedZone,
      status,
      timeCreatedUser,
      timeNeeded,
      xCoordinate,
      yCoordinate,
    },
    setState,
  ] = useState(initialState);

  const [alertModalState, setAlertModalState] = useState({
    alertModalColor: "success",
    modalShow: false,
    modalText: "",
  });

  const [customsExpanded, setCustomsExpanded] = useState(false);
  const [classificationsExpanded, setClassificationsExpanded] = useState(false);

  const handleCustomsExpandClick = () => {
    setCustomsExpanded(!customsExpanded);
  };
  const handleClassificationsExpandClick = () => {
    setClassificationsExpanded(!classificationsExpanded);
  };

  const classifications = {};
  for (let currentClassification in props.classifications) {
    const classificationObj = props.classifications[currentClassification];
    if (classificationObj.assetMode === "Inventory") {
      classifications[currentClassification] = classificationObj;
    }
  }

  const [availableEvents, setAvailableEvents] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [inventoryClassifications, setInventoryClassifications] = useState({
    ...classifications,
  });
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [customFields, setCustomFields] = useState({});
  const handleAlertModal = (content) => {
    const {
      alertModalContent,
      alertModalSeverity = "success",
    } = content;

    setAlertModalState(() => ({
      alertModalColor: alertModalSeverity,
      modalShow: false,
      modalText: alertModalContent,
    }));
  };
  const renderedCustomFields = Object.keys(customFields).map(
    (element, index) => {
      if (customFields[element].customId) {
        let {
          dataType,
          error = false,
          errorText = "",
          customId,
          name = "",
          required = false,
          value = "",
        } = customFields[element];

        switch (dataType) {
          case "Currency":
            return (
              <Grid
                item
                key={`${customId}-${index}`}
                size={4}
                sx={classes.inputContainer}
              >
                <CurrencyTextBox
                  fullWidth={true}
                  error={error}
                  label={name}
                  onChange={(event) => {
                    setCustomFields((prevState) => ({
                      ...prevState,
                      [name]: {
                        ...prevState[name],
                        error: false,
                        value: event.target.value,
                      },
                    }));
                  }}
                  required={required}
                  size="large"
                  value={value}
                  variant="outlined"
                />
              </Grid>
            );
          case "Date":
            return (
              <Grid
                item
                key={`${customId}-${index}`}
                size={4}
                sx={classes.inputContainer}
              >
                <LXDatePicker
                  sx={classes.inputField}
                  format="MM/dd/yyyy"
                  id={`date-picker-inline-${customId}`}
                  label={name}
                  onChange={(event) => {
                    setCustomFields((prevState) => ({
                      ...prevState,
                      [name]: {
                        ...prevState[name],
                        error: false,
                        value: event,
                      },
                    }));
                  }}
                  required={required}
                  slotProps={{
                    textField: {
                      error: error,
                      helperText: errorText,
                      InputLabelProps: {
                        style: { color: "transparent" },
                        shrink: false,
                      },
                      label: name,
                      variant: "outlined",
                    },
                  }}
                  value={value?.length > 0 ? value : null}
                />
              </Grid>
            );
          default:
            return (
              <Grid
                item
                key={`${customId}-${index}`}
                size={4}
                sx={classes.inputContainer}
              >
                <SimpleTextField
                  error={error}
                  fullWidth
                  helperText={error ? "Required Field" : ""}
                  label={name}
                  onChange={(event) => {
                    // if datatype is numeric, parse the event.target.value to an integer
                    let value;
                    if (dataType === "Numeric") {
                      value = event.target ? parseInt(event.target.value) : "";
                    } else {
                      value = event.target ? event.target.value : "";
                    }
                    setCustomFields((prevState) => ({
                      ...prevState,
                      [name]: { ...prevState[name], value, error: false },
                    }));
                  }}
                  required={required}
                  size="large"
                  type={`${dataType === "Numeric" ? "number" : ""}`}
                  value={value}
                  variant="outlined"
                />
              </Grid>
            );
        }
      }
      return null;
    }
  );
  const renderClassificationFields = Object.keys(inventoryClassifications).map(
    (parent, index) => {
      const { formOptions, name } = inventoryClassifications[parent];
      const childrenOptions = [];

      // Populates the select options
      Object.keys(formOptions).forEach((child) => {
        childrenOptions.push({
          label: formOptions[child].label,
          parentId: formOptions[child].referenceId,
          value: formOptions[child].formOptionId,
        });
      });

      return (
        <Grid sx={classes.inputContainer} key={`${name}-${index}`} size={4}>
          <AutocompleteSelect
            id={name}
            label={name}
            options={childrenOptions.sort((a, b) =>
              naturalSort(a.label, b.label)
            )}
            onChange={(event, value) => {
              if (value) {
                const { value: itemValue = "" } = value;
                setInventoryClassifications((prevState) => ({
                  ...prevState,
                  [name]: {
                    ...prevState[name],
                    selectedValue: itemValue,
                    // Im revisiting this code. We should of written the onSubmit to break apart
                    //the value. Instead of doing the itemValue variable. But alas, deadlines.
                    //Im adding 'value' to this. That way we can actually clear this field when
                    //the clear button is clicked
                    value,
                  },
                }));
              } else {
                setInventoryClassifications((prevState) => ({
                  ...prevState,
                  [name]: {
                    ...prevState[name],
                    selectedValue: value,
                    value,
                  },
                }));
              }
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label={null} variant="outlined" />
            )}
            size="large"
            value={
              inventoryClassifications[name].value || { label: "", value: "" }
            }
          />
        </Grid>
      );
    }
  );

  function validateFields() {
    let isValidated = true;

    const fieldsToValidate = [
      inventoryItemTag,
      category,
      description,
      selectedFacility,
      selectedZone,
      status,
      xCoordinate,
      yCoordinate,
    ];

    // if a field is empty, set its error value to true,
    fieldsToValidate.forEach((currentField) => {
      const { value, id } = currentField;

      if (value.length === 0 && currentField.isRequired) {
        setState((prevState) => {
          return {
            ...prevState,
            [id]: {
              ...prevState[id],
              error: true,
              errorText: "Required field",
              id,
              value,
            },
          };
        });
        isValidated = false;
      }
    });

    // if custom field is required and field is empty, set its error value to true
    Object.keys(customFields).forEach((customField) => {
      const { value, name, required } = customFields[customField];
      if (required && value?.length === 0) {
        isValidated = false;
        setCustomFields((prevState) => {
          return {
            ...prevState,
            [name]: { ...prevState[name], error: true },
          };
        });
      }
    });

    //validate timeCreatedUser
    if (
      timeCreatedUser.value === null ||
      timeCreatedUser.value.length < 1 ||
      isValidDateObject(timeCreatedUser.value)
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          timeCreatedUser: {
            ...prevState.timeCreatedUser,
            error: false,
            errorText: "",
          },
        };
      });
    } else if (!isValidDateObject(timeCreatedUser.value)) {
      isValidated = false;
      setState((prevState) => {
        return {
          ...prevState,
          timeCreatedUser: {
            ...prevState.timeCreatedUser,
            error: true,
            errorText: "Invalid Date",
          },
        };
      });
    } else {
      console.warn("unaccounted date case");
    }

    //validate timeNeeded
    if (
      timeNeeded.value === null ||
      timeNeeded.value.length < 1 ||
      isValidDateObject(timeNeeded.value)
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          timeNeeded: {
            ...prevState.timeNeeded,
            error: false,
            errorText: "",
          },
        };
      });
    } else if (!isValidDateObject(timeNeeded.value)) {
      isValidated = false;
      setState((prevState) => {
        return {
          ...prevState,
          timeNeeded: {
            ...prevState.timeNeeded,
            error: true,
            errorText: "Invalid Date",
          },
        };
      });
    } else {
      console.warn("unaccounted date case");
    }

    //validate xCoordinate. If there is a value for x, there must be a value for y. And vice versa
    if (xCoordinate.value.length > 0 && yCoordinate.value.length === 0) {
      isValidated = false;
      setState((prevState) => {
        return {
          ...prevState,
          yCoordinate: {
            ...prevState.yCoordinate,
            error: true,
            errorText: "If X coordinate is set, Y coordinate is required",
          },
        };
      });
    } else if (yCoordinate.value.length > 0 && xCoordinate.value.length === 0) {
      isValidated = false;
      setState((prevState) => {
        return {
          ...prevState,
          xCoordinate: {
            ...prevState.xCoordinate,
            error: true,
            errorText: "If Y coordinate is set, X coordinate is required",
          },
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          xCoordinate: {
            ...prevState.xCoordinate,
            error: false,
            errorText: "",
          },
          yCoordinate: {
            ...prevState.yCoordinate,
            error: false,
            errorText: "",
          },
        };
      });
    }

    if (isValidated) {
      let selectedInventoryClassifications = {};
      // Handles Classifications
      Object.keys(inventoryClassifications).forEach((item) => {
        if (
          inventoryClassifications[item].selectedValue !== null &&
          inventoryClassifications[item].selectedValue !== undefined &&
          inventoryClassifications[item].selectedValue.length > 0
        ) {
          // Here we find the parent classification. Then do a .find in the formOptions to pull the childs label
          const selectedItemLabel = inventoryClassifications[
            item
          ]?.formOptions?.find(
            (element) =>
              element.formOptionId ===
              inventoryClassifications[item].selectedValue
          )?.label;

          selectedInventoryClassifications[item] = selectedItemLabel;
        }
      });

      onSubmit(customFields, handleAlertModal, props, {
        category,
        description,
        inventoryItemTag,
        note,
        quantity,
        selectedFacility,
        selectedZone,
        selectedInventoryClassifications,
        status,
        timeCreatedUser,
        timeNeeded,
        xCoordinate,
        yCoordinate,
      }).then((response) => {
        // reset state
        if (response.assetId) {
          setAlertModalState(() => ({
            alertModalColor: "success",
            modalShow: true,
            modalText: "Successfully Created",
          }));
          clearFields();
        } else if (!response.success) {
          setAlertModalState(() => ({
            alertModalColor: "error",
            modalShow: true,
            modalText: response.errors.map((error, index) => {
              return <p key={`${error} - ${index}`}>{error}</p>;
            }),
          }));
        }
      });
    }
  }

  function clearFields() {
    // Clear Basic Fields
    setState(initialState);

    // Clear Custom Fields
    setCustomFields((prevState) => {
      const newState = prevState;
      Object.keys(newState).forEach(
        (item) =>
        (newState[item] = {
          ...newState[item],
          error: false,
          required: false,
          value: "",
        })
      );

      // did you know if you returned newState instead of {...newState} this whole clear function breaks? Wild... The more you know
      return { ...newState };
    });

    // Clear Classifications
    setInventoryClassifications((prevState) => {
      const newState = prevState;
      Object.keys(newState).forEach(
        (item) =>
        (newState[item] = {
          ...newState[item],
          error: false,
          required: false,
          value: "",
          selectedValue: "",
        })
      );

      // did you know if you returned newState instead of {...newState} this whole clear function breaks? Wild
      return { ...newState };
    });
  }

  useEffect(() => {
    const { eventTypesMap = {}, categoriesList = [] } = organization;
    const inventoryCategoriesList = categoriesList.filter(
      (category) => category.assetMode === "Inventory"
    );

    const availableEventItems = [];
    const categoryOptionItems = [];
    const facilityOptionItems = [];
    const fields = {};
    const fieldValues = {};

    const inventoryCustoms = customs.filter(
      (custom) => custom.assetMode === "Inventory"
    );

    inventoryCustoms.forEach((inventoryCustom) => {
      fields[inventoryCustom.name] = {
        ...inventoryCustom,
        required: false,
        error: false,
        value: "",
      };
      fieldValues[inventoryCustom.customId] = "";
    });

    // Set Facility Options

    facilityOptionItems.push({ label: "-Select-", value: "" });

    Object.keys(facilities)
      .sort((a, b) => {
        return facilities[a].name?.localeCompare(facilities[b].name);
      })
      .forEach((element) => {
        const { name, facilityId } = facilities[element];
        facilityOptionItems.push({ label: name, value: facilityId });
      });

    // Set Category Options
    [...inventoryCategoriesList]
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      })
      .forEach((element) => {
        categoryOptionItems.push({ id: element.id, label: element.id });
      });

    // Add a blank value as one of the options
    categoryOptionItems.unshift({ id: "", label: "" });

    // Add a blank value to the events
    availableEventItems.push({ value: "" });

    Object.keys(eventTypesMap)
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .forEach((element) => {
        if (element === "Tag Destroyed") {
          return null;
        } else {
          availableEventItems.push({ value: element });
        }
      });

    if (Object.keys(fields).length > 0) {
      setCustomFields(fields);
    }

    setAvailableEvents(availableEventItems);
    setCategoryOptions(categoryOptionItems);
    setFacilityOptions(facilityOptionItems);
  }, [customs, facilities, organization]);

  return (
    <Grid container>
      <NotificationModal
        confirmationModalShow={alertModalState.modalShow}
        modalClose={() => {
          setAlertModalState(() => ({
            alertModalColor: "success",
            modalShow: false,
            modalText: "",
          }));
        }}
        confirmationText={alertModalState.modalText}
        color={alertModalState.alertModalColor}
      />

      {/* TODO - Looks like we are currently not using this. Awaiting word from the company to see what they want to do */}
      {/* The create cqr dialog appears after the creation process is done */}
      {/* {generateCQR ? (
        <GenerateCQRContent
          closeModal={() => setGenerateCQR(false)}
          inventoryItem={newInventoryItem}
          open={generateCQR}
          setGenerateCQR={setGenerateCQR}
        />
      ) : null} */}
      <Grid size={12}>
        <Grid display="flex">
          <Typography
            component="p"
            sx={{ color: typography?.secondary, fontWeight: "700" }}
          >
            Required fields have an asterisk
          </Typography>
          <MuiSVGIcon
            icon={<AsteriskSolid />}
            sx={{
              color: "red",
              fontSize: "10px",
            }}
          />
        </Grid>
        <Grid container>
          {/* Inventory Tag */}
          <Grid sx={classes.inputContainer} size={4}>
            <SimpleTextField
              error={inventoryItemTag.error}
              fullWidth
              helperText={inventoryItemTag.error ? "Required Field" : ""}
              id="inventoryItemTag"
              label="Inventory Tag"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  inventoryItemTag: {
                    ...prevState.inventoryItemTag,
                    value,
                    error:
                      prevState.inventoryItemTag.error && value.length > 0
                        ? false
                        : prevState.inventoryItemTag.error,
                  },
                }));
              }}
              required={inventoryItemTag.isRequired}
              size="large"
              value={inventoryItemTag.value}
              variant="outlined"
            />
          </Grid>

          {/* Description */}
          <Grid sx={classes.inputContainer} size={4}>
            <SimpleTextField
              error={description.error}
              helperText={description.error ? "Required Field" : ""}
              id="description"
              label="Description"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  description: {
                    ...prevState.description,
                    value,
                    error:
                      prevState.description.error && value.length > 0
                        ? false
                        : prevState.description.error,
                  },
                }));
              }}
              required={description.isRequired}
              size="medium"
              value={description.value}
              variant="outlined"
            />
          </Grid>

          {/* Quantity */}
          <Grid sx={classes.inputContainer} size={4}>
            <SimpleTextField
              error={quantity.error}
              errorText={quantity.errorText}
              helperText={quantity.error ? "Required Field" : ""}
              id="quantity"
              label="Quantity"
              onChange={(event) => {
                const value =
                  event.target && !isNaN(parseInt(event.target.value))
                    ? parseInt(event.target.value)
                    : "";
                setState((prevState) => ({
                  ...prevState,
                  quantity: {
                    ...prevState.quantity,
                    value,
                    error: prevState.quantity.error
                      ? false
                      : prevState.quantity.error,
                  },
                }));
              }}
              required={quantity.isRequired}
              size="medium"
              type="number"
              value={quantity.value}
              variant="outlined"
            />
          </Grid>

          {/* Status */}
          <Grid sx={classes.inputContainer} size={4}>
            <AutocompleteSelect
              error={status.error}
              errorText={status.errorText}
              getOptionLabel={(option) => option.value || option.placeholder}
              id="status"
              label="Status"
              onChange={(event, value) => {
                if (value) {
                  const { value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    status: {
                      ...prevState.status,
                      value: itemValue,
                      error:
                        prevState.status.error && itemValue.length > 0
                          ? false
                          : prevState.status.error,
                    },
                  }));
                }
              }}
              options={availableEvents}
              required={status.isRequired}
              size="large"
              value={status}
            />
          </Grid>

          {/* Facility */}
          <Grid sx={classes.inputContainer} size={4}>
            <AutocompleteSelect
              error={selectedFacility.error}
              errorText={selectedFacility.errorText}
              id="facility"
              getOptionLabel={(option) => option.label || option.placeholder}
              label="Facility"
              onChange={(event, value) => {
                if (value) {
                  const { label, value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    selectedFacility: {
                      ...prevState.selectedFacility,
                      label,
                      error:
                        prevState.selectedFacility.error && itemValue.length > 0
                          ? false
                          : prevState.selectedFacility.error,
                      value: itemValue,
                    },
                    selectedZone: {
                      ...prevState.selectedZone,
                      label: "-Select-",
                      value: "",
                    },
                  }));
                  //set zone options
                  if (!value || itemValue === "") {
                      setZoneOptions([]);
                      return;
                  }

                  const zoneOptions = [];
                  for (let zone in zones) {
                    if (zones[zone].facilityId === itemValue) {
                      zoneOptions.push({
                        label: zones[zone].name,
                        value: zones[zone].zoneId,
                      });
                    }
                  }                  
                  setZoneOptions(zoneOptions);
                }
              }}
              options={facilityOptions}
              required={selectedFacility.isRequired}
              size="large"
              value={selectedFacility}
            />
          </Grid>

            <Grid sx={classes.inputContainer} size={4}>
              <AutocompleteSelect
                id="zone"
                disabled={!selectedFacility.value.length > 0}
                getOptionLabel={(option) => option.label || option.placeholder}
                label="Zone"
                onChange={(event, value) => {
                  if (value) {
                    const { label, value: itemValue = "" } = value;
                    setState((prevState) => ({
                      ...prevState,
                      selectedZone: {
                        ...prevState.selectedZone,
                        label,
                        error:
                          prevState.selectedZone.error && itemValue.length > 0
                            ? false
                            : prevState.selectedZone.error,
                        value: itemValue,
                      },
                    }));
                  }
                }}
                options={zoneOptions}
                size="large"
                value={selectedZone || selectedZone.label}
              />
            </Grid>

          {/* Category */}
          <Grid sx={classes.inputContainer} size={4}>
            <AutocompleteSelect
              error={category.error}
              errorText={category.errorText}
              id="category"
              getOptionLabel={(option) => option.label || option.placeholder}
              label="Category"
              onChange={(event, value) => {
                if (value) {
                  const { id = "" } = value;
                  // Sets the value in state
                  setState((prevState) => ({
                    ...prevState,
                    category: {
                      ...prevState.category,
                      label: id,
                      value: id,
                      error:
                        prevState.category.error && id.length > 0
                          ? false
                          : prevState.category.error,
                    },
                  }));

                  // Checks custom fields to see if any fields need to switched to required
                  // The else statement sets everything to default. In the case they switch
                  // to a category that does not have any custom fields set.

                  const isValidCustomCategory =
                    organization.categoriesList.find((category) => {
                      return category.id === id;
                    });

                  if (isValidCustomCategory) {
                    const { requiredFields = [] } = isValidCustomCategory;
                    setCustomFields((prevState) => {
                      let newState = { ...prevState };
                      requiredFields.forEach((field) => {
                        const { required, label } = field;
                        newState[label] = {
                          ...newState[label],
                          required,
                          error: false,
                        };
                      });
                      return newState;
                    });
                  }
                }
              }}
              options={categoryOptions}
              required={category.isRequired}
              size="large"
              value={category}
            />
          </Grid>

          {/* timeCreatedUser */}
          <Grid sx={classes.inputContainer} size={4} key={`timeCreatedUser`}>
            <LXDatePicker
              sx={classes.inputField}
              format="MM/dd/yyyy"
              id={`date-picker-inline-timeCreatedUser`}
              label="Create Date"
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  timeCreatedUser: {
                    ...prevState.timeCreatedUser,
                    value: event,
                  },
                }));
              }}
              size="large"
              slotProps={{
                textField: {
                  error: timeCreatedUser.error,
                  helperText: timeCreatedUser.errorText,
                  InputLabelProps: {
                    style: { color: "transparent" },
                    shrink: false,
                  },
                  label: "Create date",
                  variant: "outlined",
                },
              }}
              value={timeCreatedUser.value}
            />
          </Grid>

          {/* timeNeeded */}
          <Grid sx={classes.inputContainer} size={4} key={`timeNeeded`}>
            <LXDatePicker
              sx={classes.inputField}
              format="MM/dd/yyyy"
              id={`date-picker-inline-timeNeeded`}
              label="Need Date"
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  timeNeeded: {
                    ...prevState.timeNeeded,
                    value: event,
                  },
                }));
              }}
              slotProps={{
                textField: {
                  error: timeNeeded.error,
                  helperText: timeNeeded.errorText,
                  InputLabelProps: {
                    style: { color: "transparent" },
                    shrink: false,
                  },
                  label: "Need date",
                  variant: "outlined",
                },
              }}
              value={timeNeeded.value}
            />
          </Grid>

          {/* x coordinate field */}
          <Grid sx={classes.inputContainer} size={4} key={`xCoordinate`}>
            <SimpleTextField
              error={xCoordinate.error}
              errorText={xCoordinate.errorText}
              helperText={xCoordinate.error ? "Required Field" : ""}
              id="xCoordinate"
              label="X Coordinate"
              onChange={(event) => {
                console.log("typeof xcoord: ", typeof event.target.value);
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  xCoordinate: {
                    ...prevState.xCoordinate,
                    value,
                    error: prevState.xCoordinate.error
                      ? false
                      : prevState.xCoordinate.error,
                  },
                }));
              }}
              size="medium"
              type="number"
              value={xCoordinate.value}
              variant="outlined"
            />
          </Grid>

          {/* y coordinate field */}
          <Grid sx={classes.inputContainer} size={4} key={`yCoordinate`}>
            <SimpleTextField
              error={yCoordinate.error}
              errorText={yCoordinate.errorText}
              id="yCoordinate"
              label="Y Coordinate"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  yCoordinate: {
                    ...prevState.yCoordinate,
                    value,
                    error: prevState.yCoordinate.error
                      ? false
                      : prevState.yCoordinate.error,
                  },
                }));
              }}
              size="medium"
              type="number"
              value={yCoordinate.value}
              variant="outlined"
            />
          </Grid>

          {/* Inventory Details */}
          <Grid sx={classes.inputContainer} size={12}>
            <SimpleTextField
              multiline
              id="inventoryDetails"
              label="Inventory Details"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  note: { ...prevState.note, label: value, value: value },
                }));
              }}
              size="large"
              variant="outlined"
              value={note.label}
            />
          </Grid>

          {/* Custom Fields */}
          <Grid marginTop="10px" size={12}>
            <Accordion
              expanded={customsExpanded}
              onChange={handleCustomsExpandClick}
            >
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{ color: typography?.secondary, fontWeight: "700" }}
                >
                  Custom Fields
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container size={12}>
                  {renderedCustomFields}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Classifications */}
          <Grid marginTop="10px" size={12}>
            <Accordion
              expanded={classificationsExpanded}
              onChange={handleClassificationsExpandClick}
            >
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  sx={{ color: typography?.secondary, fontWeight: "700" }}
                >
                  Classifications
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container size={12}>
                  {renderClassificationFields}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12}>
        <Box sx={classes.inputContainer} textAlign="right" width="100%">
          {/* Clear Button */}
          <MaterialUiButton
            className={classes.button}
            color="cancel"
            label="Clear"
            onClick={() => {
              clearFields();
            }}
            variant="outlined"
          />

          {/* Submit Button */}
          <MaterialUiButton
            className={classes.button}
            color="submit"
            label="submit"
            onClick={() => {
              validateFields();
            }}
            variant="contained"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
