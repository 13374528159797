import { createAsyncThunk } from "@reduxjs/toolkit";

export const initSettings = createAsyncThunk(
    "settings/init",
    async (_, thunkAPI) => {
        const { getState, extra: apiService, dispatch } = thunkAPI;

        const { token = "" } = getState().user;

        const data = await apiService.settings
            .getConfigTables({ token })
            .then(async (data) => {
                if (data.error) {
                    return dispatch({
                        success: false,
                        error: data.error,
                    });
                } else if (data.success) {
                    return {
                        success: true,
                        displayConfig: data.clientOrganizationConfig.gridConfigs,
                    }
                } else {
                    return dispatch({
                        type: "alert/showAlert",
                        payload: {
                            text: "Something went wrong while initializing settings.",
                            // text: data.error,
                        },
                    });
                }
            });
        return data;
    }
);