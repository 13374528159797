export const fixedDrawerStyles = (props = {}) => {
  const { sideNavWidth = "", appBarHeight = "" } = props;

  return {
    bottomPush: {
      height: "100px",
    },
    drawer: (theme) => {
      return {
        flexShrink: 0,
        width: sideNavWidth,
        "& .MuiPaper-root": {
          backgroundColor: `${theme?.palette?.primary?.main}`,
        },
      };
    },
    listItem: {
      justifyContent: "center",
      "& *": {
        color: "#fefefe",
      },
      "&:focus-visible": (theme) => ({
        backgroundColor: `${theme?.palette?.success?.main}`,
      }),
      "&:hover": (theme) => ({
        "& *": {
          color: "white!important",
        },
        backgroundColor: `${theme?.palette?.success?.main}`,
      }),
    },
    listItemIcon: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    listItemText: {
      "& .MuiListItemText-primary": {
        fontSize: "0.7em",
        textAlign: "center",
        },
    },
    menuIcon: {
      height: appBarHeight,
      justifyContent: "center",
    },
    menuItem: (theme) => ({
      backgroundColor: `${theme?.palette?.third?.main}`,
      color: "white",
      "&:hover": {
        color: "white",
        backgroundColor: `${theme?.palette?.primary?.main}`,
      },
    }),
    menuList: {
      justifyContent: "center",
      paddingBottom: "0!important",
      paddingTop: "0!important",
    },
    menuPaper: (theme) => ({
      backgroundColor: `${theme?.palette?.third?.main}`,
    }),
    userBadgeItem: (theme) => ({
      backgroundColor: `${theme?.palette?.primary?.main}`,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      marginBottom: '1rem',
      position: "sticky",
      "&:hover": {
        backgroundColor: `${theme?.palette?.success?.main}`,
      },
    }),
  };
};
