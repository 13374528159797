import moment from "moment-timezone";

const retrieveAssetsEvents = (props, body) => {
  const { apiUrl, token } = props;
  return new Promise((resolve) => {
    fetch(`${apiUrl}assetHistories/search?includeConsoleExtras=true`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve({
          values: response.assetHistories
            ? response.assetHistories
              .map((history) => {
                return { tag: history.assetTag, history: history };
              })
              .reduce((x, y) => {
                return {
                  ...x,
                  [y.tag]: x[y.tag]?.length
                    ? [...x[y.tag], y.history]
                    : [y.history],
                };
              }, {})
            : null,
          cqrGeneratedCount: response.assetHistories.filter(
            (ass) => ass.event.toLowerCase() === "cqr generated"
          ).length,
          tagDestroyedCount: response.assetHistories.filter(
            (ass) => ass.event.toLowerCase() === "tag destroyed"
          ).length,
        });
      });
  });
};

export const checkSelectedAssets = async (props, state) => {
  const { organizationId = "" } = props;
  let results = { isError: false, faultyContent: [] };
  const idArray = Object.keys(state.selectedAssets).map((assId) => assId);
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              organization_id: organizationId,
            },
          },
          {
            terms: {
              asset_id: idArray,
            },
          },
          {
            terms: {
              event: ["CQR Generated", "Tag Destroyed"],
            },
          },
        ],
      },
    },
    limit: 500,
    sort: `time_of_log DESC`,
  };

  let testAssets = await retrieveAssetsEvents(props, elasticQuery);

  Object.keys(testAssets.values).forEach((key) => {
    const assetTag = key;

    const cqrsGenerated = testAssets.values[key]
      .filter((history) => history.event === "CQR Generated")
      .map((el) => el.timeOfLog);
    const tagsDestroyed = testAssets.values[key]
      .filter((history) => history.event === "Tag Destroyed")
      .map((el) => el.timeOfLog);

    if (
      cqrsGenerated.some((cqr) =>
        tagsDestroyed.every((tagDestroyed) =>
          moment(cqr, "YYYYMMDDHHmmss").isAfter(
            moment(tagDestroyed, "YYYYMMDDHHmmss")
          )
        )
      ) ||
      (cqrsGenerated.length > 0 && tagsDestroyed.length === 0)
    ) {
      results.isError = true;
      (results.faultyContent).push(assetTag ? assetTag : "");
    }
  });

  return results;
};

export const parseUserInfo = (row, firstLast = false) => {
  if (firstLast && row.appUserId && (row.firstName || row.lastName)) {
    return `${row.firstName || ""}${row.firstName && row.lastName ? " " : ""}${row.lastName || ""
      }`;
  }
  if (row.appUserId && (row.firstName || row.lastName)) {
    return `${row.lastName || ""}${row.firstName && row.lastName ? ", " : ""}${row.firstName || ""
      }`;
  } else {
    return "";
  }

  // else if (row.appUserId === "53ef1d1f-3e47-46e7-b444-18170051486f") {
  //   return `iOS User`;
  // } else if (row.appUserId === "aabbab69-10c3-4c7e-9011-6f1c05e7b0a7") {
  //   return `Android User`;
  // } else {
  //   return ``;
  // }
};
