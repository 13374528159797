export const fontList = [
  { label: "Acme", value: "'Acme', sans-serif" },
  { label: "DejaVu Sans Mono", value: "DejaVu Sans Mono" },
  { label: "DejaVu Sans", value: "DejaVu Sans" },
  { label: "DejaVu Serif", value: "DejaVu Serif" },
  { label: "Dialog Input", value: "DialogInput" },
  { label: "Dialog", value: "Dialog" },
  { label: "Lato", value: "'Lato', sans-serif" },
  { label: "Libre Franklin", value: "'Libre Franklin', sans-serif" },
  { label: "Monospaced", value: "Monospaced" },
  { label: "Montserrat", value: "'Montserrat', sans-serif" },
  { label: "News Cycle", value: "'News Cycle', sans-serif" },
  { label: "Nunito Sans", value: "'Nunito Sans', sans-serif" },
  { label: "Nunito", value: "'Nunito', sans-serif" },
  { label: "Open Sans", value: "'Open Sans', sans-serif" },
  { label: "Oswald", value: "'Oswald', sans-serif" },
  { label: "Poppins", value: "'Poppins', sans-serif" },
  { label: "Raleway", value: "'Raleway', sans-serif" },
  { label: "Roboto", value: "'Roboto', sans-serif" },
  { label: "Rowdies", value: "'Rowdies', cursive" },
  { label: "Sans Serif", value: "SansSerif" },
  { label: "Serif", value: "Serif" },
];

export const cqrFontList = [
  {label:"DejaVu Sans",value:"DejaVu Sans"},
  {label:"DejaVu Sans Mono",value:"DejaVu Sans Mono"},
  {label:"DejaVu Serif",value:"DejaVu Serif"},
  {label:"Dialog",value:"Dialog"},
  {label:"Dialog Input",value:"DialogInput"},
  {label:"Monospaced",value:"Monospaced"},
  {label:"Sans Serif",value:"SansSerif"},
  {label:"Serif",value:"Serif"}
  ]