import { formatLatLon } from "../../../../utils/lat_lon_utils";
import { isEmpty } from "lodash";
import { parseUserInfo } from "../utils";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useTranslation as UseTranslation } from "react-i18next";
import getColumnType from "../../../../utils/get_column_type";
import modifyColumns from "../../../../utils/modifyColumns";

export default function buildColumns(props) {
  const {
    classifications,
    columnOrder,
    customs,
    facilities,
    history,
    timeZone,
    zones,
  } = props;

  const { t } = UseTranslation();
 
  const classificationColumns = !isEmpty(classifications)
    ? Object.keys(classifications).map((classKey) => {
        const { name } = classifications[classKey];
        return {
          field: name,
          headerName: name,
          renderCell: (data) => {
            if (data.rowNode.type === "group") return data.value;
            const { row, assetId } = data;
            const { classificationMap = {} } = row;

            const classificationValue = Object.keys(classificationMap).filter(
              (k) => k === name
            )[0];

            return (
              <div
                className="pointer"
                key={assetId}
                onClick={() => onClick(row)}
              >
                {classificationMap[classificationValue] || t("dnpinput1")}
              </div>
            );
          },
          valueGetter: (value, row) => {
            const { classificationMap = {} } = row;
            const classificationValue = Object.keys(classificationMap).filter(
              (k) => k === name
            )[0];
            return classificationMap[classificationValue] || "";
          },
          groupingValueGetter: (value, row) => {
            const { classificationMap = {} } = row;
            const classificationValue = Object.keys(classificationMap).filter(
              (k) => k === name
            )[0];
            return classificationMap[classificationValue] || null;
          },
        };
      })
    : [];

  const customColumns = !isEmpty(customs)
    ? customs.map((item) => {
        const { dataType, name } = item;

        const colType = getColumnType(dataType);
        return {
          field: name,
          headerName: name,
          renderCell: (data) => {
            // Check if the row type is "group" or "pinnedRow", and return formatted date or fallback text accordingly.
            if (["group", "pinnedRow"].includes(data.rowNode.type)) {
              return colType === "date" && data.value
                ? new Date(data.value).toLocaleDateString() || t("dnpinput1")
                : data.value || t("dnpinput1");
            }
            const { row, tabIndex } = data;
            const { customMap = {} } = row;

            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];
            const renderValue = customMap[customValue];
            return (
              <div
                className="pointer"
                key={tabIndex}
                onClick={() => onClick(row)}
              >
                {/* //if colType is date and returnVal is not undefined, return the date */}
                {colType === "date" && renderValue !== undefined
                  ? new Date(renderValue).toLocaleDateString()
                  : renderValue || t("dnpinput1")}
              </div>
            );
          },
          type: colType,
          valueGetter: (value, row) => {
            const { customMap = {} } = row;
            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];
            const returnVal = customMap[customValue];
            if (colType === "number") {
              return parseInt(returnVal) || null;
            }
            if (colType === "date") {
              return returnVal !== undefined ? new Date(returnVal) : null;
            }
            return returnVal || null;
          },
          groupingValueGetter: (value, row) => {
            const { customMap = {} } = row;
            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];
            const returnVal = customMap[customValue];
            if (colType === "number") {
              return parseInt(returnVal) || null;
            }
            if (colType === "date") {
              return returnVal !== undefined ? new Date(returnVal) : null;
            }
            return returnVal || null;
          },
        };
      })
    : [];

  const historyTableColumns = [
    // timeOfLog
    {
      field: "timeOfLog",
      groupable: false,
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { row } = data;
        const { timeOfLogLong } = row;

        return (
          <div className="pointer" onClick={() => onClick(row)}>
            {timeOfLogLong
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (value, row) => {
        const timeOfLogLong = row.timeOfLogLong;
        return timeOfLogLong
          ? new Date(timeOfLogLong)
          : //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
            new Date(8640000000000000);
      },
    },
    // assetTag
    {
      field: "assetTag",
      headerName: "Asset Tag",
      id: "assetTag",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;

        const { row, tabIndex } = data;
        const { assetTag } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {assetTag ? assetTag : t("dnpinput1")}
          </div>
        );
      },
      groupingValueGetter: (value, row) => row.assetTag || null,
    },
    // category
    {
      field: "category",
      headerName: "Asset Category",
      id: "category",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, assetId } = data;
        const { category } = row;
        return (
          <div className="pointer" key={assetId} onClick={() => onClick(row)}>
            {category ? category : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) =>
        row.category ? row.category : t("dnpinput1"),
      groupingValueGetter: (value, row) => (row.category ? row.category : null),
    },
    // event
    {
      field: "event",
      headerName: "Event",
      id: "event",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, assetId } = data;
        const { event } = row;
        return (
          <div
            className="pointer"
            key={assetId}
            style={{
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => onClick(row)}
          >
            {event ? event : t("dnpinput1")}
          </div>
        );
      },
      groupingValueGetter: (value, row) => row.event || null,
    },
    // user
    {
      field: "user",
      headerName: "User",
      id: "user",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, assetId } = data;
        const userInfo = parseUserInfo(row);
        return (
          <div
            className="pointer"
            key={assetId}
            style={{
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => onClick(row)}
          >
            {userInfo || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => parseUserInfo(row),
      groupingValueGetter: (value, row) => parseUserInfo(row) || null,
    },
    // facility
    {
      field: "facility",
      headerName: "Facility Name",
      id: "facility",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { facility, facilityId, latitude, longitude } = row;

        return (
          <div className={"pointer"} onClick={() => onClick(row)}>
            {facility
              ? facility
              : !latitude || !longitude
              ? t("dnpinput1")
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered"}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.facility
          ? row.facility
          : !row.latitude || !row.longitude
          ? t("dnpinput1")
          : row.facilityId &&
            facilities[row.facilityId] &&
            facilities[row.facilityId].name
          ? facilities[row.facilityId].name
          : "Not Registered";
      },
      groupingValueGetter: (value, row) => {
        return row.facility
          ? row.facility
          : !row.latitude || !row.longitude
          ? null
          : row.facilityId &&
            facilities[row.facilityId] &&
            facilities[row.facilityId].name
          ? facilities[row.facilityId].name
          : null;
      },
    },
    // zone
    {
      field: "zone",
      headerName: "Zone",
      id: "zone",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;

        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {/* Show pZoneName. If not present show pZoneName. If not present the Zone Name */}
            {pZoneName
              ? pZoneName
              : zoneName
              ? zoneName
              : zoneId && zones[zoneId]
              ? zones[zoneId].name
              : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;

        return pZoneName
          ? pZoneName
          : zoneName
          ? zoneName
          : zoneId && zones[zoneId]
          ? zones[zoneId].name
          : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;

        return pZoneName
          ? pZoneName
          : zoneName
          ? zoneName
          : zoneId && zones[zoneId]
          ? zones[zoneId].name
          : null;
      },
    },
    // binLocation
    {
      field: "binLocation",
      headerName: "Bin Location",
      id: "binLocation",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, zone = {} } = data;
        const { binLocation = "" } = zone;

        return (
          <div className={"pointer"} onClick={() => onClick(row)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              row.zone
                ? binLocation
                : row.binLocation
                ? row.binLocation
                : row.propertiesMap && row.propertiesMap.binLocation
                ? row.propertiesMap.binLocation
                : t("dnpinput1")
            }
          </div>
        );
      },
      valueGetter: (value, row) => row.zone?.binLocation || "",
      groupingValueGetter: (value, row) => row.zone?.binLocation || null,
    },
    // state
    {
      field: "state",
      headerName: "State",
      id: "state",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, assetId } = data;
        const { state = t("dnpinput1") } = row;

        return (
          <div className="pointer" key={assetId} onClick={() => onClick(row)}>
            {state ? state : t("dnpinput1")}
          </div>
        );
      },
      groupingValueGetter: (value, row) => {
        return row?.state || null;
      },
    },
    // latLong
    {
      field: "latLong",
      headerName: "Latitude, Longitude",
      id: "latLong",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, assetId } = data;
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return (
          <div className="pointer" key={assetId} onClick={() => onClick(row)}>
            {formattedLatLong}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");
        return formattedLatLong;
      },
      groupingValueGetter: (value, row) => {
        const { latitude, longitude } = row;
        const formattedLatLong =
          latitude && longitude ? formatLatLon(latitude, longitude) : null;
        return formattedLatLong;
      },
    },
  ]
    .concat(classificationColumns)
    // TODO - Elastic Wait for this to get refactored on AC4
    .concat(customColumns);

  //iterate over columns and if a column has a type of number, allow aggregation on that column
  historyTableColumns.forEach((column) => {
    if (column.type === "number") {
      column.aggregable = true;
    } else {
      column.aggregable = false;
    }
  });

  const onClick = (row) => {
    const { assetId = "", assetType = "", batchId = "" } = row;
    if (assetId || batchId) {
      const url = batchId
        ? `/batches/${batchId}/history/${assetType}`
        : `/assetSnapshot/${assetId}`;
      history.push(url);
    }
  };

  // Here we need to go through the columnOrder and handle Sorting / Renaming / Visibility settings
  if (columnOrder) {
    return modifyColumns({ columnOrder, historyTableColumns });
  }

  return { historyTableColumns };
}
