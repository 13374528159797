import { useState } from "react";
import { updateFacility } from "./api";
import Grid from "@mui/material/Grid2";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";

export default function DefineGrid(props) {
  const classes = {
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1rem 0",
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      padding: "2rem 1rem",
    },
  };

  const { setModalState, setState, state = {} } = props;
  const { grid = { x: 0, y: 0 } } = state;
  const [componentState, setComponentState] = useState({
    x: grid.x,
    y: grid.y,
  });

  return (
    (<Grid sx={classes.container} container>
      {/* xAxis */}
      <Grid
        size={{
          xs: 12,
          md: 5
        }}>
        <SimpleTextField
          label="Length of X-Axis(m)"
          onChange={(event) => {
            setComponentState((prevState) => ({
              ...prevState,
              x: event.target.value,
            }));
          }}
          type="number"
          value={componentState.x}
        />
      </Grid>
      {/* yAxis */}
      <Grid
        size={{
          xs: 12,
          md: 5
        }}>
        <SimpleTextField
          label="Length of Y-Axis(m)"
          onChange={(event) => {
            setComponentState((prevState) => ({
              ...prevState,
              y: event.target.value,
            }));
          }}
          type="number"
          value={componentState.y}
        />
      </Grid>
      {/* Buttons */}
      <Grid sx={classes.buttonContainer} size={12}>
        <Grid size={4}>
          <MaterialUiButton
            color="cancel"
            fullWidth
            label="CANCEL"
            onClick={() => {
              setModalState({ modalShow: false });
            }}
          />
        </Grid>
        <Grid size={4}>
          <MaterialUiButton
            color="submit"
            fullWidth
            label="SUBMIT"
            onClick={() => {
              updateFacility(props, { grid: { ...componentState } }).then(
                (res = {}) => {
                  if (res.success) {
                    setState((prevState) => ({
                      ...prevState,
                      grid: { ...componentState },
                      selectedFacility: res.facility,
                    }));
                    setModalState({
                      modalShow: true,
                      title: "Configure Zones",
                    });
                  }
                }
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>)
  );
}
