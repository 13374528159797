import Button from "@mui/material/Button";
import { useTheme } from '@mui/material/styles';

export default function MaterialUiButton(props) {
  const theme = useTheme();
  const { palette = {} } = theme;
  const { error = {}, primary = {}, success = {}, typography = {} } = palette;

  const cssClasses = {
    cancel: {
      color: "#FFFFFF !important",
      backgroundColor: `${primary?.main} !important`,
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
        color: `${primary?.main} !important`,
      },
    },
    delete: {
      backgroundColor: `${error.main} !important`,
      color: "#FFFFFF !important",
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
        color: `${error.main} !important`,
      },
    },
    disabled: {
      backgroundColor: `${typography?.secondary} !important`,
      color: "#E6E7E8 !important",
      "&:hover": {
        backgroundColor: `${typography?.secondary} !important`,
        color: "#E6E7E8 !important",
      },
    },
    submit: {
      backgroundColor: `${success?.main} !important`,
      color: "#FFFFFF !important",
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
        color: `${success?.main} !important`,
      },
    },
  };

  const {
    className = {},
    color = "primary",
    cypressId,
    disabled = false,
    disableElevation,
    disableFocusRipple,
    disableRipple,
    fullWidth = false,
    id,
    label = "Submit",
    onClick,
    onMouseDown = () => { },
    role,
    size,
    sx = {},
    type,
    variant = "contained",
  } = props;

  return (
    <Button
      sx={{
        ...(color === "cancel" ? cssClasses.cancel : {}),
        ...(color === "delete" ? cssClasses.delete : {}),
        ...(color === "submit" ? cssClasses.submit : {}),
        ...(color === "primary" ? { backgroundColor: `${theme.main} !important` } : {}),
        ...(disabled ? cssClasses.disabled : {}),
        ...(className ? className : {}),
        ...sx,
      }}
      color={
        color === "cancel" ? "inherit" : color === "delete" ? "inherit" : color === "submit" ? "inherit" : color
      }
      data-cypress-id={cypressId}
      disabled={disabled || color === "disabled" ? true : false}
      disableElevation={disableElevation}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      fullWidth={fullWidth}
      id={id ? id : null}
      onClick={onClick}
      onMouseDown={onMouseDown}
      role={role}
      size={size}
      type={type}
      variant={variant}
    >
      {label}
    </Button>
  );
}
