import { useEffect, useState } from "react";
import GoogleMapComponent from "../../../../components/Maps/GoogleMapComponent";
import Grid from '@mui/material/Grid2';
import MaterialUiButton from "../../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import { editFacility } from "../../../../utils/API/Facilities/Facilities"

export default function EditGeofence(props) {
  const {
    apiUrl,
    dispatchGlobal,
    facility,
    handleConfirmationModal,
    onHide,
    token,
    updateFacilities,
  } = props;
  const { facilityId = "", propertiesMap = {} } = facility;
  const [state, setState] = useState({
    drawingControl: true,
    hasGeofenceBeenSaved: false,
    geofence: {},
    geofencedSaved: false
  });
  const { drawingControl, geofence, geofencedSaved } = state;
  const doesFacilityHavePath = state?.path?.length > 0;

  function saveGeofence() {
    const payload = {
      propertiesMap: {
        geofence: state.path[0],
      }
    }
    editFacility({ apiUrl, facilityId, token }, payload).then(response => {
      if (response.error) {
        // throw error handling
      } else {
        // throw success message up
        handleConfirmationModal('Geofence has been saved', false)

        // Update Redux
        dispatchGlobal(
          updateFacilities({
            body: { ...response.facility },
            facilityId: response.facility?.facilityId,
          })
        );

        // close modal
        onHide()
      }
    })
  }

  function removeGeofence() {
    const payload = {
      propertiesMap: {
        geofence: null,
      }
    }

    editFacility({ apiUrl, facilityId, token }, payload).then(response => {
      if (response.error) {
        // throw error handling
      } else {
        // throw success message up
        handleConfirmationModal('Geofence has been deleted', false)

        // Update Redux
        dispatchGlobal(
          updateFacilities({
            body: { ...response.facility },
            facilityId: response.facility?.facilityId,
          })
        );

        // close modal
        onHide()
      }
    })
  }

  useEffect(() => {
    console.log('useEffect')
    if (propertiesMap?.geofence) {
      setState((prevState) => ({ ...prevState, geofence: propertiesMap?.geofence, geofencedSaved: true }))
    }
  }, [geofence])

  return (
    <Grid container>
      {/* Map Component */}
      <Grid size={12} sx={{ height: '50vh', width: '50vw' }}>
        <GoogleMapComponent
          drawingControl={drawingControl}
          mapSettings={{ center: geofence?.path ? geofence?.path[0] : {}, zoom: 10 }}
          onPolygonComplete={(path) => setState((prevState) => ({ ...prevState, path: [path], geofencedSaved: false }))}
          polygons={[geofence]}
        />
      </Grid>

      {/* Button Farm */}
      <Grid size={12} sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 1rem',
        marginBottom: '1rem',
        marginTop: '1rem',
      }}>

        {/* Remove Geofence - Should only appear when there is an existing geofence */}
        {geofencedSaved ? <MaterialUiButton
          color="error"
          fullWidth
          label="Remove Geofence"
          onClick={removeGeofence}
          sx={{ marginLeft: '.5rem' }}
        /> : null}

        {/* Save Geofence */}
        {!geofencedSaved ? <MaterialUiButton
          color="primary"
          disabled={state?.path?.length === 0}
          fullWidth
          label="Save Geofence"
          onClick={saveGeofence}
          size="large"
          sx={{ marginRight: '.5rem' }}
          variant="contained"
        />
          : null
        }

        {/* Cancel */}
        <MaterialUiButton
          color="error"
          fullWidth
          label="Cancel"
          onClick={() => console.log('close the modal')}
          sx={{ marginLeft: '.5rem' }}
        />
      </Grid>
    </Grid>
  );
}
