import { Box, Typography } from "@mui/material";

export default function PolygonPopUpContent(props) {
  const { facility = {} } = props;
  const { name = "", location = {} } = facility
  const {
    address1 = '',
    city = '',
    lat1000 = '',
    lon1000 = '',
    state = '',
  } = location

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}>
      <Typography variant="h2" >
        {name}
      </Typography>
      <Typography variant="body2" >
        {address1} {city} {state}
      </Typography>
      <Typography variant="caption" >
        {lat1000} {lon1000}
      </Typography>
    </Box>
  )
}