export const validateDuoMFAuthentication = async (props) => {
  const { apiUrl, token, duoCode, stateString } = props;
  const body = {
    duoCode,
    state: stateString
  }

  const results = await fetch(`${apiUrl}auth/validateDuoMFAuthentication`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body)
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err)
    })
  return results
}