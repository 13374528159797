import { useState } from "react";
import { Box, Card, CardContent, IconButton, InputLabel, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";

export default function ClassificationFilters(props) {
  const {
    cardSx = {
      height: "75%",
      marginTop: ".5rem",
      maxHeight: "75%",
      position: "absolute",
      overflow: "scroll",
      width: "90%",
    },
    classifications = [],
    state = {},
    setState,
    xsSize = 3,
    parentSx = { padding: "0 1rem", marginTop: "1rem", position: "relative" },
  } = props;
  const { filters = {} } = state;
  const theme = useTheme()
  const { palette = {} } = theme
  const { typography = {} } = palette

  const [showClassificationSelector, setShowClassificationSelector] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState({});
  const [classificationsFromFilters, setClassificationsFromFilters] = useState([])

  const classificationOptions =
    classifications.map((item) => ({
      label: item.name,
      value: item.classificationId,
    }))
      .sort((a, b) => a.label.localeCompare(b.label))
    || [];

  function handleSubmit() {
    let newClassificationsArray = [...classificationsFromFilters];

    // Here we need to set the show state back to default
    setShowClassificationSelector(false);

    // Push new values into the classifications array
    // We do type of check in case we want to add multi select
    if (typeof selectedClassification === "string") {
      //find the classification object that matches the selectedClassification value and add it to the classificationsFromFilters array
      const selectedCustomsObject = classifications.find((item) => item.classificationId === selectedClassification)
      setClassificationsFromFilters([...classificationsFromFilters, selectedCustomsObject])
      newClassificationsArray.push(selectedCustomsObject);
    }

    // Reset the selected items
    setSelectedClassification([]);
  }

  return (
    (<Grid sx={parentSx} size={xsSize}>
      <Grid container>
        <Grid size={12}>
          {/* Header and Add filter button */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <InputLabel
              sx={{
                color: typography?.secondary,
                fontFamily: "Lato",
                fontWeight: "bold",
              }}
            >
              {showClassificationSelector ? "Select Classification" : "Classification Filters"}
            </InputLabel>
            {!showClassificationSelector ? (
              <IconButton
                aria-label="Open Classification filter selector"
                onClick={() => setShowClassificationSelector(true)}
              >
                <AddCircleIcon
                  color="primary"
                  cursor="pointer"
                  size="small"
                />
              </IconButton>
            ) : (
              <Box>
                {/* Cancel */}
                <IconButton
                  aria-label="Close classification filter selector"
                  onClick={() => {
                    // Switch back to the default view
                    setShowClassificationSelector(false);

                    // Clear any selection that might've been made
                    setSelectedClassification([]);
                  }}
                >
                  <ClearIcon
                    className="pointer"
                    color="error"
                    cursor="pointer"
                    size="small"
                  />
                </IconButton>

                {/* Submit */}
                <IconButton
                  aria-label="Add Classification Filter"
                  onClick={() => handleSubmit()}
                >
                  <CheckIcon
                    className="pointer"
                    color="success"
                    cursor="pointer"
                    size="small"
                  />
                </IconButton>
              </Box>
            )}
          </Box>

          {/* Classification Selector */}
          {showClassificationSelector ? (
            <Box sx={{ margin: ".5rem 0" }}>
              <SimpleSelect
                onChange={(event) => {
                  setSelectedClassification(event.target.value)
                }}
                options={classificationOptions}
                size="large"
                variant="outlined"
                value={selectedClassification}
              />


            </Box>
          ) : null}
        </Grid>
        {/* Rendered classifications */}
        <Grid size={12}>
          <Card
            sx={cardSx}
          >
            <CardContent>


              {classificationsFromFilters?.map((item, index) => {

                return (
                  <Box key={`${item.label}-${index}`} sx={{ padding: ".5rem 0" }}>
                    <SimpleSelect
                      options={item.formOptions.map((option) => {
                        return { label: option.label, value: option.label }
                      }).sort((a, b) => a.label.localeCompare(b.label))
                      }
                      onChange={(event) => {
                        //object will look like: {label: "classification name", value: "classification value"}
                        const classificationObject = { label: item.name, value: event.target.value }
                        //update the classifications array in state
                        setState((prevState) => {
                          const newState = { ...prevState };
                          newState.filters.classifications[index] = classificationObject;
                          return newState;
                        })
                      }
                      }
                      label={item.name}
                      onDeleteIconClick={() => {
                        setState((prevState) => {
                          const newState = { ...prevState };
                          newState?.filters?.classifications?.splice(index, 1);
                          return newState;
                        })
                        //   also remove from classificationsFromFilters array
                        setClassificationsFromFilters((prevState) => {
                          const newState = [...prevState];
                          newState.splice(index, 1);
                          return newState;
                        })
                      }
                      }
                      showDeleteIcon
                      size="small"
                      // value={state.filters?.classifications[index]?.value || ""}
                      value={filters?.classifications[index]?.value || ""}
                    />
                  </Box>
                )
              }
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>)
  );
}
