import { InputLabel, useTheme } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function SimpleSwitch(props) {
  const theme = useTheme()
  const { palette = {} } = theme
  const { typography = {} } = palette
  const classes = {
    label: {
      color: typography?.secondary,
      // fontFamily: "Lato Semibold",
      fontWeight: "bold",
      marginBottom: 0
    },
  }

  const {
    checked,
    color = "primary",
    disabled = false,
    formControlLabelStyles,
    id="",
    inputProps = {},
    label = "",
    labelPlacement,
    labelStyles,
    onChange,
    required,
    size,
  } = props;
  return (
    <FormControlLabel
      sx={formControlLabelStyles}
      control={
        <Switch
          checked={checked}
          color={color}
          disabled={disabled}
          id={id}
          inputProps={inputProps}
          onChange={onChange}
          required={required}
          size={size}
        />
      }
      label={
        <InputLabel sx={{ ...classes.label, ...(labelStyles || {}) }}>
          {label}
        </InputLabel>
      }
      labelPlacement={labelPlacement}
    />
  );
}
