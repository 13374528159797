const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const showLastEvents = async (props, state) => {
  const { token, organizationId } = props;

  const { page = 0 } = state;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          // Give us assets from this organization
          {
            term: {
              current_owner_id: organizationId,
            },
          },

          // No assets that are from batches
          {
            bool: {
              must_not: [
                {
                  exists: {
                    field: "batch_id",
                  },
                },
              ],
            },
          }
        ],
        must_not: [
          // No devices
          {
            terms: {
              // If the user is on the Inventory Snapshot page, then we do not need
              // to exclude this
              asset_mode: ["Device"],
            },
          },
        ],
        should: [

          // Should be an Inventory asset
          {
            term: {
              asset_mode: "Inventory",
            },
          },

          // Should be a Container asset
          {
            term: {
              asset_mode: "Container",
            },
          }
        ],
        minimum_should_match: 1
      },
    },
    limit: 1000,
    range: {},
    page,
    sort: "time_of_log DESC",
  };

  const results = await fetch(`${apiUrl}assets/search?includeConsoleExtras=true`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(elasticQuery),
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });
  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      inventoryStatusTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const retrieveParentIds = async (props) => {
  const { token } = props;
  const results = await fetch(`${apiUrl}assets/assetParents?includeConsoleExtras=true`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((results) => results.json())
    .then((results) => {
      return results;
    });

  return results;
};

export const inventory = {
  retrieveParentIds,
  saveFilterSettings,
  showLastEvents,
};
