import { createAsyncThunk } from "@reduxjs/toolkit";

export const assetHistory = {
  saveFilterSettings: createAsyncThunk(
    "assetHistory/assetHistory/saveFilterSettings",
    async (assetHistoryTableSettings, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      // retrieve token and user Id from store
      const { token = "", appUserId = "" } = getState().user;

      // also retrieve current filters to spread over incoming filters, just in case
      const { filters: filtersFromStore = {} } = getState().assetHistory;
      const filters = { ...filtersFromStore, ...assetHistoryTableSettings };

      const data = await apiService.assetHistory
        .saveFilterSettings({ token, appUserId }, assetHistoryTableSettings)
        .then((data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: { type: "error", text: data.error },
            });
          } else if (data.success) {
            return {
              success: true,
              filters: filters,
              showInstructions: false,
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while saving your filter settings.",
                type: "error",
              },
            });
          }
        });
      // data.reduxRequestId = thunkAPI.requestId;
      return data;
    }
  ),
  searchHistories: createAsyncThunk(
    "assetHistory/assetHistory/searchHistories",
    async (settings = null, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      const { token = "", organizationId = "" } = getState().user;
      const { classifications } = getState().organization;

      const data = await apiService.assetHistory
        .searchHistories(
          { token, organizationId, classifications },
          settings
        )
        .then(async (data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                type: "error",
                text: data.error,
              },
            });
          } else if (data.success) {
            return {
              success: true,
              histories: data.assetHistories,
              count: data.count,
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching asset histories.",
                type: "error",
              },
            });
          }
        });
      return data;
    }
  ),
};
