const DefaultOrganizationRolesMap = {
  allPermissions: {
    availableActions: {
      "Create CQR": {},
      "Delete Batches": {},
      "Create Locations": {},
      "Delete Locations": {},
      "Edit Locations": {},
      "Edit Facillity Image": {},
      "Edit Far Point": {},
      "Edit Zero Point": {},
      "Edit Organizations": {},
      "Delete Organizations": {},
      "Create Organizations": {},
      "Edit Products": {},
      "Create Products": {},
      "Edit Users": {},
      "Delete Users": {},
      "Unlock Users": {},
      "Create Users": {},
    },
    availableViews: {
      "View Assets": {},
      "View Asset Settings": {},
      "View Asset Snapshot": {},
      "View Inventory": {},
      "View Inventory Settings": {},
      "View Inventory Snapshot": {},
      "View Batches": {},
      "View Devices": {},
      "View Facilities": {},
      "View Locations": {},
      "View Organizations": {},
      "View Products": {},
      "View Settings": {},
      "View Users": {},
    },
  },
  console: {
    Admin: {
      availableActions: {
        "Create CQR": {},
        "Delete Batches": {},
        "Create Locations": {},
        "Delete Locations": {},
        "Edit Locations": {},
        "Edit Facillity Image": {},
        "Edit Far Point": {},
        "Edit Zero Point": {},
        "Edit Organizations": {},
        "Delete Organizations": {},
        "Create Organizations": {},
        "Edit Products": {},
        "Create Products": {},
        "Edit Users": {},
        "Delete Users": {},
        "Unlock Users": {},
        "Create Users": {},
      },
      availableViews: {
        "View Assets": {},
        "View Asset Settings": {},
        "View Asset Snapshot": {},
        "View Inventory": {},
        "View Inventory Settings": {},
        "View Inventory Snapshot": {},
        "View Batches": {},
        "View Devices": {},
        "View Facilities": {},
        "View Locations": {},
        "View Organizations": {},
        "View Products": {},
        "View Settings": {},
        "View Users": {},
      },
    },
    "Mobile Only": {},
  },

  mobile: {
    "Asset Editor": {
      actions: {
        scan: {},
        editAsset: {},
        topology: {},
      },
    },
    "Asset Scanner": {
      actions: {
        scan: {},
      },
    },
    Mobile: {
      actions: {
        "Item Information": {},
      },
    },
  },
};

export default DefaultOrganizationRolesMap;
