import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    text: "",
    // type is "success" or "error"
    type: "",
    show: false,
  },
  reducers: {
    showAlert: (state, action) => {
      state.text = action.payload.text;
      state.type = action.payload.type || "error";
      state.show = true;
    },
    hideAlert: (state) => {
      state.show = false;
    },
  },
});

// Action creators are generated for each case reducer function
const { showAlert, hideAlert } = alertSlice.actions;

export { showAlert, hideAlert };

export default alertSlice.reducer;
