import CustomDataFields from "../../components/CustomDataFields/CustomDataFields";
import Grid from "@mui/material/Grid2";
import SimpleSelect from "../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import { naturalSort } from "../../utils/naturalSort";

export default function ProductInformation(props) {
  const {
    addProductLevelDataElement,
    customDataFields = [],
    description = {},
    name = {},
    onChange,
    onRemove,
    productCategoryOptions,
    productType = {},
    readOnly,
    setAddProductLevelDataElement,
    setState,
    tag = {},
  } = props;
  const categoryOptions = productCategoryOptions();

  return (<>
    {/* Product Name */}
    <Grid size={6}>
      <SimpleTextField
        error={name.error}
        id="name"
        inputProps={{ "data-cypress-id": "products-product-mdl-txt-name"}}
        label="Product Name"
        onChange={(event) => {
          onChange(event);
        }}
        readOnly={readOnly}
        required={true}
        value={name.value}
      />
    </Grid>
    {/* Product Code */}
    <Grid size={6}>
      <SimpleTextField
        error={tag.error}
        id="tag"
        inputProps={{ "data-cypress-id": "products-product-mdl-txt-code"}}
        label="Product Code"
        onChange={(event) => {
          onChange(event);
        }}
        readOnly={readOnly}
        required={true}
        value={tag.value}
      />
    </Grid>
    {/* Product Category */}
    <Grid size={6}>
      <SimpleSelect
        error={productType.error}
        label="Product Category"
        id="productType"
        inputProps={{ "data-cypress-id": "products-product-mdl-select-category"}}
        onChange={(event) => {
          onChange({
            target: { id: "productType", value: event.target.value },
          });
        }}
        readOnly={readOnly}
        required={true}
        value={categoryOptions.length > 0 ? productType.value : ""}
        options={categoryOptions.sort((a, b) =>
          naturalSort(a.label, b.label)
        )}
        variant="outlined"
      />
    </Grid>
    {/* Product Description */}
    <Grid size={12}>
      <SimpleTextField
        id="description"
        inputProps={{ "data-cypress-id": "products-product-mdl-txt-description"}}
        label="Product Description"
        multiline
        onChange={(event) => {
          onChange(event);
        }}
        readOnly={readOnly}
        required={false}
        rows={4}
        value={description.value}
      />
    </Grid>
    {/* Custom Data Fields are handled here */}
    {customDataFields.length > 0 || addProductLevelDataElement ? (
      <CustomDataFields
        addField={addProductLevelDataElement}
        customDataFields={customDataFields}
        onRemove={(element) => {
          onRemove(element);
        }}
        readOnly={readOnly}
        setAddProductLevelDataElement={setAddProductLevelDataElement}
        updateCustomFields={(customDataFields) =>
          setState((prevState) => ({ ...prevState, customDataFields }))
        }
      />
    ) : null}
  </>);
}
